import * as actionTypes from "../actionTypes.js";

const initialState = {
  isGuestAuthenicated: false,
  guestUser: null
};

const guestReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GUEST_AUTHENICATE:
        localStorage.setItem("user", JSON.stringify(action.payload.data.token));
        return { 
            ...state, 
            isGuestAuthenicated: true,
            guestUser: action.payload.data.token
        };

    case actionTypes.SELECT_COMPANY:
      return initialState;
    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default guestReducer;