import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Modal, 
  Tooltip, 
  Typography
} from '@mui/material';

import useCurrencyService from '../../../components/hooks/useCurrencyService';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import NA from '../../../components/utils/NA';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { 
  GetPayment, 
  GetPaymentActivities
} from '../../../redux/actions/pay/paymentsActions';

import global from "../../../global.scss";
import "./styles.scss";

const Data = {
  Date: dayjs()
};

const PaymentDetailsModal = ({
  open, 
  setOpen,
  title,
  update,
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const { handleShowAmount } = useCurrencyService();
  // eslint-disable-next-line no-unused-vars
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(-1);

  const [payment, setPayment] = useState(null);

  const [paymentActivitiesList, setPaymentActivitiesList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
    setFormData(Data);
    setPayment(null);
    setMode(-1);
  };

  useEffect(() => {
    try {
      if(open){
        if(update?.defaultMode === 1){
          // mode = 1 - view payment details
          setMode(1);
          setPayment(null);
          dispatch(GetPayment(companyId, update?.payment_id, setLoading));
        }else if(update?.defaultMode === 2){
          // mode = 2 - view payment activities
          setMode(2);
          dispatch(GetPaymentActivities(companyId, update?.payment_id, setLoading));
        }else{
          toast.error("Something went wrong!");
          handleClose();
        }
      }
    } catch (err) {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      if(mode === 1) formatPaymentDetailsObj(state.payment || null);
      if(mode === 2) formatPaymentActivitiesList(state.paymentActivitiesList || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // ------------------- obj formatter -------------------
  const formatPaymentDetailsObj = (obj) => {
    setPayment(obj);
  };

  const formatPaymentActivitiesList = (list) => {
    setPaymentActivitiesList(list);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          className="fullpage__modal">

          <ModalTopBar 
            title={title}
            onBack={handleClose}
            />

          {
            loading
            ?
            <Box
              className="flexCenterCenterRow" height={'300px'}>
              <CircularProgress />
            </Box>
            :
              mode === 1
              ?
              <Container 
                maxWidth="sm">
                
                <Box
                  mb={"60px"}
                  className="flexCenter_Row payment__details-info"
                  sx={{
                    backgroundColor: payment?.status === "Scheduled" ? "#dce6f1" : payment?.status === "Paid" ? "#d7ebce" : "#fce2ba"
                  }}
                  gap={"15px"}>
                  {
                    payment?.status === "Scheduled"
                    ?
                    <AccessTimeIcon 
                      color='info'
                      sx={{ width: "40px", height: "40px" }}/>
                    :
                      payment?.status === "Paid"
                      ?
                      <TaskAltOutlinedIcon 
                        color='success'
                        sx={{ width: "40px", height: "40px" }}/>
                      :
                      <ErrorOutlineOutlinedIcon
                        color='warning'
                        sx={{ width: "40px", height: "40px" }}/>
                  }
                  <Typography
                    variant='subtitle1'
                    fontWeight={500}
                    sx={{
                      color: payment?.status === "Scheduled" ? "#004182" : payment?.status === "Paid" ? "#44712e" : "#915907"
                    }}>
                    {update?.status}
                  </Typography>
                </Box>

                <Box
                  mb={"60px"}>
                  <Typography
                    variant='h5'
                    mb={"20px"}>
                    Payments summary
                  </Typography>
                  <Box
                    mb={"20px"}
                    className="flexCenterSBRow">
                    <Typography
                      fontWeight={600}>
                      Payment amount
                    </Typography>
                    <Typography
                      variant='subtitle2'>
                      {handleShowAmount("USD", payment?.amount)}
                    </Typography>
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Paid to
                    </Typography>
                    <Typography
                      variant='subtitle2'>
                      {payment?.ref_vendor_id?.name}
                    </Typography>
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Scheduled by
                    </Typography>
                    {
                      payment?.pmt_scheduled_by
                      ?
                        <>
                          <Box
                            className="flexCenter_Row"
                            gap="15px">
                            <Tooltip
                              title={payment?.pmt_scheduled_by?.employee_email}>
                              <Typography
                                variant='subtitle2'>
                                {payment?.pmt_scheduled_by?.employee_name}
                              </Typography>
                            </Tooltip>
                            <Chip
                              size='small'
                              label={payment?.pmt_scheduled_by?.employee_role || <NA/>} 
                              sx={{ 
                                color: payment?.pmt_scheduled_by?.employee_role === "Admin" ? global["purple"] : global["secondary"],
                                backgroundColor: payment?.pmt_scheduled_by?.employee_role === "Admin" ? global["purple_light"] : global["primary_light"]
                              }}
                              />
                          </Box>
                          <Typography
                            variant='caption'
                            color={"grey"}>
                            On {dayjs(payment?.created_at).format("MMM DD, YYYY")}
                          </Typography>
                        </>
                      : 
                        <NA />
                    }
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Payment method
                    </Typography>
                    <Box
                      className="flexCenter_Row"
                      sx={{ margin: "10px" }}>
                      <AccountBalanceOutlinedIcon 
                        sx={{ margin: "10px" }}/>
                      <Box>
                        <Typography
                          variant='caption'
                          color={"grey"}
                          fontWeight={600}>
                          Bank account
                        </Typography>
                        <Typography
                          variant='subtitle2'>
                          ...({payment?.ref_co_bank_acc_id?.account_number?.slice(-4)})
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className="flexCenter_Row"
                      sx={{ margin: "10px" }}>
                      <CalendarMonthOutlinedIcon 
                        sx={{ margin: "10px" }}/>
                      <Box>
                        <Typography
                          variant='caption'
                          color={"grey"}
                          fontWeight={600}>
                          Debit on
                        </Typography>
                        <Typography
                          variant='subtitle2'>
                          {dayjs(payment?.debit_date).format("MMM DD, YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Delivery method
                    </Typography>
                    <Box
                      className="flexCenter_Row"
                      sx={{ margin: "10px" }}>
                      <AccountBalanceOutlinedIcon 
                        sx={{ margin: "10px" }}/>
                      <Box>
                        <Typography
                          variant='caption'
                          color={"grey"}
                          fontWeight={600}>
                          Bank transfer to
                        </Typography>
                        <Typography
                          variant='subtitle2'>
                          ...({payment?.ref_vendor_id?.associated_vendor_bank_account[0]?.account_number?.slice(-4)})
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className="flexCenter_Row"
                      sx={{ margin: "10px" }}>
                      <ScheduleSendOutlinedIcon 
                        sx={{ margin: "10px" }}/>
                      <Box>
                        <Typography
                          variant='caption'
                          color={"grey"}
                          fontWeight={600}>
                          Delivery date
                        </Typography>
                        <Typography
                          variant='subtitle2'>
                          {dayjs(payment?.delivery_date).format("MMM DD, YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {
                  payment.associated_billing_pay.length > 0
                  &&
                  <Box
                    mb={"60px"}>
                    <Box 
                      mb={"20px"}
                      className="flexCenterSBRow">
                      <Typography
                        variant='subtitle2'>
                        Transaction fees
                      </Typography>
                      <Typography
                        variant='subtitle2'>
                        {handleShowAmount(payment?.associated_billing_pay[0]?.fee_currency, 
                            payment?.associated_billing_pay[0]?.fee_billed)}
                      </Typography>
                    </Box>
                  </Box>
                }

                <Box
                  mb={"60px"}>
                  <Typography
                    variant='h5'
                    mb={"20px"}>
                    Bill details
                  </Typography>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Vendor name
                    </Typography>
                    <Typography
                      variant='subtitle2'>
                      {payment?.ref_vendor_id?.name}
                    </Typography>
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Bill amount
                    </Typography>
                    <Typography
                      variant='subtitle2'>
                      {handleShowAmount("USD", payment?.amount)}
                    </Typography>
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Invoice #
                    </Typography>
                    <Typography
                      variant='subtitle2'>
                      {payment?.ref_bill_id?.invoice_number || <NA />}
                    </Typography>
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Creation date
                    </Typography>
                    <Typography
                      variant='subtitle2'>
                      {dayjs(payment?.ref_bill_id?.bill_date).format("MMM DD, YYYY")}
                    </Typography>
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Due date
                    </Typography>
                    <Typography
                      variant='subtitle2'>
                      {dayjs(payment?.ref_bill_id?.due_date).format("MMM DD, YYYY")}
                    </Typography>
                  </Box>
                  <Box
                    mb={"40px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Note to self
                    </Typography>
                    <Typography
                      variant='subtitle2'>
                      <NA />
                    </Typography>
                  </Box>

                  <Box 
                    mb={"20px"}
                    className="flexCenterSBRow payment__details-payment">
                    <Typography
                      variant='h6'>
                      Bill amount
                    </Typography>
                    <Typography
                      variant='h6'>
                      {handleShowAmount("USD", payment?.amount)}
                    </Typography>
                  </Box>
                </Box>
              </Container>
              :
                mode === 2
                ?
                  <Container
                    maxWidth="sm">
                    <Box
                      className="flex__Column"
                      gap={"10px"}
                      pt="20px"
                      pb="20px">
                      {
                        loading
                        ?
                        <Box
                          className="flexCenterCenterRow"
                          height={"200px"}>
                          <CircularProgress />
                        </Box>
                        :
                        paymentActivitiesList.length === 0
                        ?
                        <Box
                          className="flexCenterCenterRow"
                          height={"200px"}>
                          <NA
                            label={'No activity found'}/>
                        </Box>
                        :
                        <>
                        <Typography 
                          variant='caption'
                          color={"grey"}>
                          Latest activity first
                        </Typography>
                        {
                          paymentActivitiesList.map((item, index) => (
                          <Box
                            key={index}
                            className="payment__act"
                            gap={"10px"}>
                            <Box
                              className="flex__Column"
                              gap={"10px"}>
                              <Box
                                className="flexCenter_Row"
                                gap={"10px"}>
                                <Typography
                                  variant='body2'>
                                  <Typography 
                                    variant='caption'
                                    sx={{ color: "grey" }}>
                                    {item.created_at && dayjs(item.created_at).format('MMM DD YYYY, hh:mm A')}&nbsp;-&nbsp;
                                  </Typography>
                                  <b>{item?.activity || "NA"}</b> :: {item?.comment.split("&&")[0]}
                                </Typography>
                              </Box>
                              {
                                item?.ref_approver_id && item?.ref_approver_id !== null
                                &&
                                <Box
                                  className="flexCenter_Row"
                                  gap={"10px"}>
                                  <Typography
                                    variant='caption'
                                    color={"grey"}
                                    textAlign={"right"}
                                    width={"130px"}>
                                    Action taken by
                                  </Typography>
                                  <Tooltip
                                    title={item?.ref_approver_id?.employee_email || "NA"}>
                                    <Chip
                                      size='small'
                                      label={item?.ref_approver_id?.employee_name || "NA"}
                                      /> 
                                  </Tooltip>
                                </Box>
                              }
                              {
                                item?.comment.split("&&").length > 1
                                &&
                                <Box
                                  className="flexFS_Row"
                                  gap={"10px"}>
                                  <Typography
                                    variant='caption'
                                    color={"grey"}
                                    textAlign={"right"}
                                    minWidth={"130px"}>
                                    Approver Comment
                                  </Typography>
                                  <Typography
                                    variant='body2'>
                                    {item?.comment.split("&&").slice(1).join("")}
                                  </Typography>
                                </Box>
                              }
                            </Box>
                          </Box>
                          ))}
                        </>
                      }
                      <Button
                        onClick={handleClose}>
                        Close
                      </Button>
                    </Box>
                  </Container>
                :
                <></>
          }
        </Box>
      </Box>
    </Modal>
  )
}

export default PaymentDetailsModal;