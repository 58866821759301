import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from "react-apexcharts";

import { 
  Box,
  Skeleton,
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Typography 
} from '@mui/material';

import useSelectedCompany from '../../components/hooks/useSelectedCompany';
import useCurrencyService from '../../components/hooks/useCurrencyService';
import NA from '../../components/utils/NA';

// import Chart from "../../assets/images/expenses-chart.png";

import { 
  GetBillsStats, 
  GetExpenseStats, 
  GetInvoiceStats 
} from '../../redux/actions/analytics/analyticsActions';
import { 
  GetCurrencies 
} from '../../redux/actions/miscellaneousActions';

import "./styles.scss";

const HomePage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.analytics);
  const other = useSelector(state => state.other);
  const companyId = useSelectedCompany();
  const { handleShowAmount } = useCurrencyService();
  const [loadingPay, setLoadingPay] = useState(true);
  const [loadingReceive, setLoadingReceive] = useState(true);
  const [loadingExpense, setLoadingExpense] = useState(true);

  const [billsStats, setBillsStats] = useState(0);
  const [invoicesStats, setInvoicesStats] = useState(0);

  const [selectedCompany, setSelectedCompany] = useState({});

  const [expenseChart, setExpenseChart] = useState({
    options:{
      series: [],
      labels: []
    },
    series: [],
  });

  useEffect(() => {
    dispatch(GetCurrencies());
    if(companyId !== null){
      dispatch(GetBillsStats(companyId, setLoadingPay));
      dispatch(GetInvoiceStats(companyId, setLoadingReceive));
      dispatch(GetExpenseStats(companyId, setLoadingExpense));
    }

    // return () => {
    //   setBillsStats(0);
    //   setInvoicesStats(0);
    //   setExpenseChart({
    //     options:{
    //       series: [],
    //       labels: []
    //     },
    //     series: [],
    //   });
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    try {
      console.log(state);
      formatBillsList(state.billsStats || []);
      formatInvoicesList(state.invoicesStats || []);
      formatExpensesChart(state.expenseStats || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    try {
      setSelectedCompany(other.selectedCompany || {});
      console.log(other)
    } catch (err) {}
  }, [other]);

  // -------------------- list formatter --------------------
  const formatBillsList = (obj) => {
    setBillsStats(obj);
  };

  const formatInvoicesList = (obj) => {
    setInvoicesStats(obj)
  };

  const formatExpensesChart = (list) => {
    let seriesTemp = [];
    let labelsTemp = [];
    list.forEach(i => {
      seriesTemp.push(i.expense_total);
      labelsTemp.push(i.category_name);
    });

    console.log(seriesTemp, labelsTemp)

    setExpenseChart({
      ...expenseChart,
      options: {
        labels: labelsTemp
      },
      series: seriesTemp,
    });
  }

  return (
    <Box>
      <Box sx={{ marginBottom: "40px" }}>
        <Typography variant='h5'>
          {selectedCompany.LegalName || ""}
        </Typography>
      </Box>

      <Box>
        <Box
          className="flexCenterCenterRow"
          sx={{
            width: "800px", 
            minWidth: "400px", 
            margin: "auto", 
            gap: "200px",
            marginBottom: "50px" }}>
          <Box
            minWidth={"350px"}>
            <Typography 
              variant='subtitle1'
              textAlign={"center"}
              mb="10px">
              Payables
            </Typography>
            <Table>
              <TableHead>
                <TableCell>Bills you owe</TableCell>
                <TableCell align="right">{loadingPay ? "0" : billsStats.total_bill_count}</TableCell>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Coming due</TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={600}>
                      {handleShowAmount("USD", loadingPay ? "0" : billsStats.upcoming)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>1-30 days overdue</TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={600}>
                      {handleShowAmount("USD", loadingPay ? "0" : billsStats.past_1_30)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>31-60 days overdue</TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}>
                        {handleShowAmount("USD", loadingPay ? "0" : billsStats.past_31_60)}
                      </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>61-90 days overdue</TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}>
                        {handleShowAmount("USD", loadingPay ? "0" : billsStats.past_61_90)}
                      </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{">"} 90 days overdue</TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}> 
                        {handleShowAmount("USD", loadingPay ? "0" : billsStats.more_than_90)}
                      </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box
              mt={"10px"}
              sx={{ borderRadius: "16px", 
                backgroundColor: "#fafafa",
                padding: "15px" }}
              className="flexCenterSBRow">
              <Typography variant='body2'>
                Bills you paid <br/>YTD
              </Typography>
              <Box>
                <Typography variant='body2' textAlign={"right"}>
                  {loadingPay ? "0" : billsStats.paid_total_bill_count}
                </Typography>
                <Typography fontWeight={600} variant='body2'>
                  {handleShowAmount("USD", loadingPay ? "0" : billsStats.paid_total_bill_amount)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            minWidth={"350px"}>
            <Typography 
              variant='subtitle1'
              textAlign={"center"}
              mb={"10px"}>
              Receivables
            </Typography>
            <Table
              width={"350px"}>
              <TableHead>
                <TableCell>Invoices owed to you</TableCell>
                <TableCell align="right">{loadingReceive ? "0" : invoicesStats.total_rcv_invoice_count}</TableCell>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Coming due</TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}>
                        {handleShowAmount("USD", loadingReceive ? "0" : invoicesStats.upcoming)}
                      </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>1-30 days overdue</TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}>
                        {handleShowAmount("USD", loadingReceive ? "0" : invoicesStats.past_1_30)}
                      </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>31-60 days overdue</TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}>
                        {handleShowAmount("USD", loadingReceive ? "0" : invoicesStats.past_31_60)}
                      </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>61-90 days overdue</TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}>
                        {handleShowAmount("USD", loadingReceive ? "0" : invoicesStats.past_61_90)}
                      </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{">"} 90 days overdue</TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}>
                        {handleShowAmount("USD", loadingReceive ? "0" : invoicesStats.more_than_90)}
                      </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box
              mt={"10px"}
              sx={{ borderRadius: "16px", 
                backgroundColor: "#fafafa",
                padding: "15px" }}
              className="flexCenterSBRow">
              <Typography variant='body2' maxWidth={"50%"}>
                Invoices paid to you YTD
              </Typography>
              <Box>
                <Typography variant='body2' textAlign={"right"}>
                  {loadingReceive ? "0" : invoicesStats.paid_rcv_invoice_count}
                </Typography>
                <Typography fontWeight={600} variant='body2'>
                  {handleShowAmount("USD", loadingReceive ? "0" : invoicesStats.paid_total_rcv_invoice_amount)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography 
            variant='subtitle1'
            textAlign={"center"}>
            Employee expenses
          </Typography>
          {
            loadingExpense
            ?
            <Box
              sx={{ 
                width: "350px",
                // border: "10px solid white",
                margin: "auto", marginTop: "20px", marginBottom: "20px" }}>
              <Skeleton 
                variant="circular"
                sx={{ width: "300px", height: "300px", marginLeft:"20px", backgroundColor: "#f1f1f1" }}
                />
            </Box>
            :
              expenseChart.series.length === 0
              ?
                <Box
                  className="flexCenterCenterRow"
                  sx={{ 
                    width: "350px",
                    height: "250px",
                    // border: "10px solid white",
                    margin: "auto", marginTop: "10px", marginBottom: "20px" }}>
                  <NA 
                    label={"No expense found"}
                    />
                </Box>
              :
              <Box
                sx={{ 
                  width: "350px",
                  // border: "10px solid white",
                  margin: "auto", marginTop: "10px", marginBottom: "20px" }}>
                <Chart
                  options={expenseChart.options}
                  series={expenseChart.series}
                  labels
                  type="donut"
                  width="500"
                />
              </Box>
          }
          {/* <Box
            sx={{ 
              backgroundColor: "#f1f1f1",
              width: "300px",
              padding: "15px",
              borderRadius: "16px",
              margin: "auto", marginTop: "50px"}}>
            <Typography
              variant='subtitle2'
              textAlign={"center"}>
              There isn't any data to display.
            </Typography>
            <Typography
              variant='body2'
              textAlign={"center"}>
              A chart will appear here if you add some data to this time period.
            </Typography>
          </Box> */}
        </Box>
      </Box>

    </Box>  
  )
}

export default HomePage