import * as actionTypes from "../../actionTypes";
import * as api from "../../../api/index";

export const GetBillingPay = (companyId, page, limit, query, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getBillingPay(companyId, page, limit, query);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_BILLING_PAY_BILL, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetBillingReceive = (companyId, page, limit, query, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getBillingReceive(companyId, page, limit, query);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_BILLING_RECEIVE_BILL, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetBillingExpense = (companyId, page, limit, query, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getBillingExpense(companyId, page, limit, query);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_BILLING_EXPENSE_BILL, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetBillingCrossBorderPayment = (companyId, page, limit, query, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getBillingCrossBorderPayment(companyId, page, limit, query);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_BILLING_CROSS_BORDER_PAYMENT_BILL, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetPlanBilling = (companyId, page, limit, query, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getPlanBilling(companyId, page, limit, query);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_PLAN_BILLING_BILL, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetAddonBilling = (companyId, page, limit, query, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getAddonBilling(companyId, page, limit, query);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_ADDON_BILLING_BILL, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};