import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
  Box,
  Checkbox,
  Container,
  Modal, 
  TextField,
  Typography, 
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateSalesTax, 
  UpdateSalesTax
} from '../../../../redux/actions/accounting/salesTaxActions';

import "./styles.scss";

const Data = {
  TaxName: "",
  Abbreviation: "",
  Description: "",
  TaxNumber: "",
  TaxRate: "",
  OldTaxRate: "",
  UseEffectiveDate: false,
  EffectiveDate: dayjs(),
  ShowTaxOnInvoices: false,
  IsCompoundTax: false,
  IsRecoverableTax: false
};

const SalesTaxModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open) {
      if(update?.defaultMode === 1){
        // mode = 1 - create sales tax
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - edit sales tax
        setMode(2);
        setFormData({
          ...formData,
          TaxName: update?.name || "",
          Abbreviation: update?.abbreviation || "",
          Description: update?.description || "",
          TaxNumber: update?.company_tax_number || "",
          ShowTaxOnInvoices: update?.is_tax_num_shown_on_invoice || false,
          IsRecoverableTax: update?.is_tax_recoverable || false,
          IsCompoundTax: update?.is_compound_tax || false,
          TaxRate: update?.associated_sale_taxes[0].tax_rate_percent || 0,
          OldTaxRate: update?.associated_sale_taxes[0].tax_rate_percent || 0
        });
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> create sales tax
        let obj =  {
          "name": formData.TaxName,
          "abbreviation": formData.Abbreviation,
          "description": formData.Description,
          "company_tax_number": formData.TaxNumber,
          "is_tax_num_shown_on_invoice": formData.ShowTaxOnInvoices,
          "is_tax_recoverable": formData.IsRecoverableTax,
          "is_compound_tax": formData.IsCompoundTax,
          "tax_rate_array": [
            {
              "tax_rate_percent": parseInt(formData.TaxRate),
              "effective_date": dayjs().format('YYYY-MM-DD')
            }
          ]
        };

        dispatch(CreateSalesTax(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2){
        // mode = 2 -> edit sales tax
        let obj =  {
          "name": formData.TaxName,
          "abbreviation": formData.Abbreviation,
          "description": formData.Description,
          "company_tax_number": formData.TaxNumber,
          "is_tax_num_shown_on_invoice": formData.ShowTaxOnInvoices,
          "is_tax_recoverable": formData.IsRecoverableTax,
          "is_compound_tax": formData.IsCompoundTax,
          "tax_rate_array": 
            formData.UseEffectiveDate === true
            ?
            // add another tax rate 
            [
              {
                "action" : "ADD",
                "tax_rate_percent": parseInt(formData.TaxRate),
                "effective_date": dayjs(formData.EffectiveDate).format('YYYY-MM-DD')
              }
            ]
            :
            // update existing tax rate
            [
              {
                "action" : "UPDATE",
                "rate_id": update?.associated_sale_taxes[0].rate_id,
                "tax_rate_percent": parseInt(formData.TaxRate),
                "effective_date": dayjs().format('YYYY-MM-DD')
              }
            ]
        };

        dispatch(UpdateSalesTax(companyId, update?.sales_tax_id, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          className={`fullpage__modal animate__animated animate__fadeIn`}>
          
          <ModalTopBar
            onBack={handleClose}
            title={title} 
            />

          <Container 
            maxWidth="sm">

            <Box 
              className="flex__Column"
              gap={"20px"}
              mt={"20px"}
              mb={"20px"}>
              
              <Box 
                className="flexFSSBRow"
                gap="15px">
                <TextField 
                  label="Tax name"
                  fullWidth
                  name="TaxName"
                  value={formData.TaxName}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 80
                  }}
                  />
              </Box>

              <Box 
                className="flexFSSBRow"
                gap="15px">
                <TextField 
                  label="Abbreviation"
                  fullWidth
                  name="Abbreviation"
                  value={formData.Abbreviation}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 80
                  }}
                  />
              </Box>

              <Box 
                className="flexFSSBRow"
                gap="15px">
                <TextField 
                  label="Description"
                  fullWidth
                  name="Description"
                  value={formData.Description}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 80
                  }}
                  />
              </Box>

              <Box 
                className="flexFSSBRow"
                gap="15px">
                <TextField 
                  label="Your tax number"
                  fullWidth
                  name="TaxNumber"
                  value={formData.TaxNumber}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 80
                  }}
                  />
              </Box>

              <Box 
                className="flexCenter_Row"
                gap="15px">
                <Checkbox 
                  checked={formData.ShowTaxOnInvoices}
                  name='ShowTaxOnInvoices'
                  onChange={(e) => 
                    handleChange({target: { name: "ShowTaxOnInvoices", value: e.target.checked }})
                  }
                  />
                <Typography>Show tax number on invoices</Typography>
              </Box>

              <Box 
                className="flexCenter_Row"
                gap="15px">
                <Checkbox 
                  checked={formData.IsRecoverableTax}
                  name='IsRecoverableTax'
                  onChange={(e) => 
                    handleChange({target: { name: "IsRecoverableTax", value: e.target.checked }})
                  }
                  />
                <Typography>This tax is recoverable</Typography>
              </Box>

              <Box 
                className="flexCenter_Row"
                gap="15px">
                <Checkbox 
                  checked={formData.IsCompoundTax}
                  name='IsCompoundTax'
                  onChange={(e) => 
                    handleChange({target: { name: "IsCompoundTax", value: e.target.checked }})
                  }
                  />
                <Typography>This is a compound tax</Typography>
              </Box>

              <Box 
                className="flexFSSBRow"
                gap="15px">
                <TextField 
                  label="Tax rate (%)"
                  fullWidth
                  name="TaxRate"
                  value={formData.TaxRate}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 80
                  }}
                  />
              </Box>

              {
                mode === 2
                &&
                <Box 
                  className="flexCenter_Row"
                  gap="15px">
                  <Checkbox 
                    checked={formData.UseEffectiveDate}
                    name='UseEffectiveDate'
                    onChange={(e) => 
                      handleChange({target: { name: "UseEffectiveDate", value: e.target.checked }})
                    }
                    />
                  <Typography>Tax rate effective from future date</Typography>
                </Box>
              }

              {
                formData.UseEffectiveDate === true
                &&
                <>
                <Box
                    className="flexCenterCenterRow"
                    gap={"5px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}>
                      Effective date
                    </Typography>
                    <Typography
                      variant='caption'
                      fontWeight={500}>
                      {dayjs(formData?.EffectiveDate).format("MMM DD, YYYY")}
                    </Typography>
                  </Box>
                  <LocalizationProvider 
                    dateAdapter={AdapterDayjs}>
                    <DateCalendar 
                      minDate={dayjs().add(1, 'day')}
                      value={formData.EffectiveDate}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "EffectiveDate",
                            value: e
                          }
                        })
                      }} />
                  </LocalizationProvider>
                  
                </>
              }

              <LoadingButton
                variant="contained"
                loading={loading}
                disabled={!(formData.Email !== "" && formData.Phone !== "" &&
                  formData.ContactName !== "" && formData.Name !== "")}
                onClick={handleSubmit}>
                {mode === 1 ? "Submit" : "Update"}
              </LoadingButton>
            </Box>
          </Container>
        </Box>
      </Box>
    </Modal>
  )
}

export default SalesTaxModal;