import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Box,
  CircularProgress,
  Container,
  MenuItem,
  TextField,
  Typography, 
} from '@mui/material';

import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../components/buttons/Button';
import NA from '../../../components/utils/NA';

import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';

import { 
  CreateCompanyBankAccount, 
  CreateVerificationCompanyBankAccount, 
  GetBankAccountTypes, 
  GetBanks,
  VerifyCompanyBankAccount, 
} from '../../../redux/actions/pay/companyBankAccountsActions';
import { 
  GetCompanyInfo 
} from '../../../redux/actions/dashboard/companyRoleActions';

import {
  CLEAR_COMPANY_BANK_ACCOUNT_DETAILS_PAY 
} from '../../../redux/actionTypes';

import "./styles.scss";

const Data = {
  AchRouting: "",
  AccNumber: "",
  // BankId: "",
  BankAccountTypeId: ""
};


const AddCompanyBankAccountPage = () => {
  const navigate = useNavigate();
	const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  // eslint-disable-next-line no-unused-vars
  const [banksList, setBanksList] = useState([]);
  const [bankAccountTypesList, setBankAccountTypesList] = useState([]);

  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  useEffect(() => {
    setStep(1);
    setSelectedBankAccount(null);
    dispatch({ type: CLEAR_COMPANY_BANK_ACCOUNT_DETAILS_PAY });
    dispatch(GetBankAccountTypes(companyId, 1, 50, setLoading));
    dispatch(GetBanks(companyId, 1, 500, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      formatBanksList(state.banksList.records || []);
      formatBankAccountTypesList(state.bankAccountTypesList.records || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const formatBanksList = (list) => {
    setBanksList(list);
  };

  const formatBankAccountTypesList = (list) => {
    setBankAccountTypesList(list);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  const handleNextStep = () => {
    setStep(step + 1);
  };
  
  // const handlePrevStep = () => {
  //   setStep(step - 1);
  // };

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(step === 1){
        // bank account details
        let obj = {
          "ach_routing": formData.AchRouting || "",
          "account_number": formData.AccNumber || "",
          // "ref_bank_id": formData.BankId || "",
          "account_type_id": formData.BankAccountTypeId || "",
          // "is_default": true
        };
        dispatch(CreateCompanyBankAccount(companyId, obj, setLoading)).then(({ res, statusCode }) => {
          if (statusCode === 201){
            setSelectedBankAccount(res.data.id);
            handleNextStep();
          };
        });
      } else if (step === 2){
        // trigger verify
        let obj = {
          "bank_account_id": selectedBankAccount
        };
        dispatch(CreateVerificationCompanyBankAccount(companyId, obj, setLoading)).then(() => {
          handleNextStep();
        });
      } else if (step === 3){
        // confirm verify
        let obj = {
          "code": formData.Code,
          "bank_account_id": selectedBankAccount
        };
        dispatch(VerifyCompanyBankAccount(companyId, obj, setLoading)).then(() => {
          handleNextStep();
        });
      } else if (step === 4){
        dispatch(GetCompanyInfo(companyId, setLoading)).then(async ({ res, statusCode }) => {
          console.log(res, statusCode)
          if(statusCode === 200){
            if(res.data.company_bank_account_exists === false){
              navigate("/add-bank-account");
            } else if (res.data.company_has_subs === false){
              navigate("/add-subscription");
            } else {
              navigate("/");
            }
          } else {
  
          }
        });
      } else {

      }
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Box>
                 
      <ModalTopBar
        onBack={() => navigate("/dashboard")}
        title={"Add your bank account to continue"} 
        />

        {
          step === 1
          // enter bank account details
          ?
          <Container
            maxWidth="sm">
            <Box 
              className="flex__Column"
              gap={"20px"}
              pt="20px">

              <Box
                className="flexCenterSBRow"
                gap={"20px"}>
                {/* <TextField 
                  select
                  label="Bank"
                  fullWidth
                  name="BankId"
                  disabled={mode === 2}
                  value={formData.BankId}
                  onChange={handleChange}>
                  {
                    banksList.length === 0
                    ?
                    <MenuItem
                      disabled>
                      No bank found
                    </MenuItem>
                    :
                      banksList.map((bank, bankIndex) => (
                        <MenuItem
                          key={bankIndex}
                          value={bank.id}>
                          {bank.bank || <NA />}
                        </MenuItem>
                      ))
                  }
                </TextField> */}
                <TextField
                  select
                  label="Account type"
                  fullWidth
                  name="BankAccountTypeId"
                  value={formData.BankAccountTypeId}
                  onChange={handleChange}>
                  {
                    bankAccountTypesList.length === 0
                    ?
                    <MenuItem
                      disabled>
                      No account type found
                    </MenuItem>
                    :
                      bankAccountTypesList.map((accountType, accountTypeIndex) => (
                        <MenuItem
                          key={accountTypeIndex}
                          value={accountType.id}>
                          {accountType.type || <NA />}
                        </MenuItem>
                      ))
                  }
                </TextField>
              </Box>
              <TextField 
                label="ACH routing number"
                fullWidth
                name="AchRouting"
                value={formData.AchRouting}
                onChange={handleChange}
                />
              <TextField 
                label="Account number"
                fullWidth
                name="AccNumber"
                type='number'
                value={formData.AccNumber}
                onChange={handleChange}
                />
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleSubmit}>
                Continue
              </LoadingButton>
            </Box>
          </Container>
          :
            step === 2
            // select verification method
            ?
            <Container 
              maxWidth="sm">
              {
               loading
               ?
               <Box
                 className="flexCenterCenterColumn"
                 gap={"40px"}
                 height={"calc(100vh - 200px)"}>
                 <CircularProgress 
                   size={50}
                   />
               </Box>
               :
                <Box
                  className="flexCenterCenterColumn"
                  gap={"40px"}
                  height={"calc(100vh - 200px)"}>
                  <Typography
                    textAlign={"center"}
                    variant={"h4"}
                    marginBottom={"-30px"}>
                    Connect this bank account
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    variant={"body1"}
                    color={"grey"}>
                    Choose how to verify and connect your bank account.
                  </Typography>

                  <Box
                    className="verify__card">
                    <Typography
                      variant={"h6"}>
                      Connect instantly
                    </Typography>
                    <Typography
                      variant={"body2"}
                      color={'grey'}>
                      Securely log in to your bank and start scheduling transfers right away. If you choose this option, we will perform a balance check for you every time an outbound Bank transfer is scheduled. This is to avoid any bank charges due to insufficient funds.
                    </Typography>
                  </Box>
                  <Box
                    className="verify__card"
                    onClick={handleSubmit}>
                    <Typography
                      variant={"h6"}>
                      Verify with penny deposit
                    </Typography>
                    <Typography
                      variant={"body2"}
                      color={'grey'}>
                      Receive a penny deposit in your bank account instantly or same day. Verify the 4-digit code in the description of that deposit.
                    </Typography>
                  </Box>
                </Box>
              }  
            </Container>
            :
              step === 3
              // ORUM - enter verification code
              ?
              <Container 
                maxWidth="sm">
                <Box
                  className="flexCenterCenterColumn"
                  gap={"20px"}
                  width={"350px"}
                  margin={"auto"}
                  height={"calc(100vh - 200px)"}>
                  <Typography
                    textAlign={"center"}
                    variant={"subtitle1"}>
                    Please enter the 4-digit code in the description of that deposit
                  </Typography>
                  <TextField 
                    fullWidth
                    placeholder='Enter 4-digit code'
                    type='password'
                    name='Code'
                    value={formData.Code}
                    onChange={handleChange}
                    />
                  <LoadingButton
                    sx={{ width: "100%" }}
                    loading={loading}
                    onClick={handleSubmit}>
                    Submit
                  </LoadingButton>
                </Box>
              </Container>
              :
                step === 4
                // success message
                ?
                <Container 
                  maxWidth="sm">
                  <Box
                    className="flexCenterCenterColumn"
                    gap={"40px"}
                    height={"calc(100vh - 200px)"}>
                    <Box
                      height={"250px"}
                      className="flexCenterCenterColumn"
                      gap={"20px"}>
                      <VerifiedTwoToneIcon 
                        color='success'
                        sx={{ width: "70px", height: "70px" }}/>

                      <Typography variant='subtitle1'>
                        Your bank account is verified and ready to use.
                      </Typography>
                      <Box
                        sx={{ width: "125px" }}>
                        <LoadingButton
                          loading={loading}
                          onClick={handleSubmit}>
                          Continue
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Box>
                </Container>
                :
                <></>
        }
    </Box>
  )
}

export default AddCompanyBankAccountPage;