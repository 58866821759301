import * as actionTypes from "../actionTypes.js";

const initialState = {
  paymentHandle: null,
  paymentQrCode: null,
  
  customersList: null,

  invoicesList: null,
  invoiceDetails: null,
  
  receiptsList: null

};

const receiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_HANDLE_RECEIVE:
      return { ...state, paymentHandle: action.payload };
    case actionTypes.GET_PAYMENT_QR_CODE_RECEIVE:
      return { ...state, paymentQrCode: action.payload };

    case actionTypes.GET_CUSTOMERS_RECEIVE:
      return { ...state, customersList: action.payload };

    case actionTypes.GET_INVOICES_RECEIVE:
      return { ...state, invoicesList: action.payload };
    case actionTypes.GET_INVOICE_DETAILS_RECEIVE:
      return { ...state, invoiceDetails: action.payload };

    case actionTypes.GET_RECEIPTS_RECEIVE:
      return { ...state, receiptsList: action.payload };

   
    case actionTypes.SELECT_COMPANY:
      return initialState;
    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default receiveReducer;