import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Menu, 
  MenuItem, 
  Switch, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow,  
  TableSortLabel,  
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../../components/textFields/SearchTextField';
import TableLoading from '../../../../components/loaders/TableLoading';
import NATable from '../../../../components/utils/NATable';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  GetExpenseCategories, 
  ToggleAllExpenseCategoryPaymentMethod,
  ToggleExpenseCategory,
  ToggleExpenseCategoryPaymentMethod,
} from '../../../../redux/actions/expense/categoriesActions';

import CategoryModal from './CategoryModal';

import global from "../../../../global.scss";
import "./styles.scss";

const CategoryPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.expense);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesTotal, setCategoriesTotal] = useState(0);

  const [categoryMenuOpen, setCategoryaMenuOpen] = useState(false);
  const [categoryMenuAnchor, setCategoryMenuAnchor] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [deleting, setDeleting] = useState(false);

  const [toggling, setToggling] = useState(false);
  const [toggleId, setToggleId] = useState(null);
  
  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Category",
      sort: "category",
    },
    {
      label: "Payment methods",
      sort: null,
      align: "center"
    },
    {
      label: "Active",
      sort: "is_active",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetExpenseCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatCategoriesList(state.categoriesList.records || []);
      setCategoriesTotal(state.categoriesList.total || 0);
    } catch (err) {}
  }, [state]);

  // ----------------- list formatter -----------------
  const formatCategoriesList = (list) => {
    setCategoriesList(list);
  }; 

  async function handleGetExpenseCategories(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetExpenseCategories(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const handleToggle = (id, bool) => {
    setToggleId(id);
    let obj = {
      "category_id": id,
      "is_active": bool
    };
    dispatch(ToggleExpenseCategory(companyId, obj, setToggling)).then(() => {
      handleGetExpenseCategories(setToggling).then(() => {
        setToggleId(null);
      })
    });
  };

  const handleTogglePaymentMethod = (id, paymentMethodId, bool) => {
    setToggleId(id);
    let obj = {
      "category_id": id,
      "payment_method_xref_id": paymentMethodId,
      "is_active": bool
    };
    dispatch(ToggleExpenseCategoryPaymentMethod(companyId, obj, setToggling)).then(() => {
      handleGetExpenseCategories(setToggling).then(() => {
        setToggleId(null);
      })
    });
  };

  const handleGetAllPaymentMethodsBool = (list) => {
    let count = 0;
    list.forEach(i => {
      if(i.is_active === true) count += 1 
    });
    return count === list.length ? true : false;
  };

  const handleChangeAllPaymentMethod = (id, bool) => {
    setToggleId(id);
    let obj = {
      "category_id": id,
      "is_active": bool
    };
    dispatch(ToggleAllExpenseCategoryPaymentMethod(companyId, obj, setToggling)).then(() => {
      handleGetExpenseCategories(setToggling).then(() => {
        setToggleId(null);
      })
    });
  };

  return (
    <Box>

      <CategoryModal
        open={categoryModalOpen}
        setOpen={setCategoryModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetExpenseCategories}
        />

      <Box 
        className="flexCenterSBRow" 
        mb={"15px"}>
        <Typography variant='subtitle2'>
          Add expense category and its payment methods
        </Typography>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
          <Button variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Create category");
              setCategoryModalOpen(!categoryModalOpen);
            }}>
            Add Category
          </Button>
        </Box>
      </Box>
      {
        loading
        ?
        <TableLoading />
        :
          <Table className='table'>
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                categoriesList.length === 0
                ?
                <NATable
                  label={"No category found"}
                  />
                :
                categoriesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>{row.category || "NA"}</TableCell>
                    <TableCell align='center'>
                      <Box width={"100%"}>
                        <Box className="flexCenter_Row" 
                          gap={"15px"} 
                          sx={{ width: "450px", margin: "auto" }}> 
                          <Box
                            className="flexCenter_Row">
                            <Checkbox 
                              checked={handleGetAllPaymentMethodsBool(row.payment_methods)}
                              onChange={(e) => {
                                handleChangeAllPaymentMethod(row.category_id, e.target.checked);
                              }}
                              />
                            All
                          </Box>
                          {
                            row.payment_methods.map((item, paymentMethodIndex) => (
                              <Box
                                key={paymentMethodIndex}
                                className="flexCenter_Row">
                                <Checkbox 
                                  checked={item.is_active}
                                  onChange={(e) => {
                                    handleTogglePaymentMethod(row.category_id, item.id, e.target.checked)
                                  }}
                                  />
                                {item.payment_method.name}
                              </Box>
                            ))
                          }
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={row.is_active} 
                        onChange={(e) => {
                          handleToggle(row.category_id, e.target.checked)
                        }}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        row.category_id === toggleId && toggling
                        ?
                        <CircularProgress size={26} sx={{ marginLeft: "7px" }}/>
                        :
                          row.category_id === deleteId && deleting
                          ?
                          <CircularProgress size={26} sx={{ marginLeft: "7px" }}/>
                          :
                          <IconButton
                            onClick={(e) => {
                              setCategoryMenuAnchor(e.currentTarget);
                              setCategoryaMenuOpen(true);
                              setDeleteId(row.category_id);
                              setUpdate(row);
                              setModalTitle(row.category || "");
                            }}>
                            <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                          </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={categoriesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={categoryMenuOpen}
        anchorEl={categoryMenuAnchor}
        onClose={() => setCategoryaMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setCategoryaMenuOpen(false);
            setCategoryModalOpen(!categoryModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default CategoryPage;