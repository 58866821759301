import { createTheme } from "@mui/material";
import { theme } from "./MuiTheme";

import styles from "../global.scss";

export const adminTheme = createTheme({
  typography: {
    ...theme.typography,
  },
  components: {
    ...theme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          fontWeight: "600",
          borderRadius: "12px",
          minWidth: "120px",
          // minHeight: "45px",
          minHeight: "37.13px",
        },
        text: {
          backgroundColor: styles["primary_light"],
          color: styles["secondary_dark"],
          "&:hover": {
              backgroundColor: styles["primary"]
          }
        },
        contained: {
          backgroundColor: styles["purple"],
          color: styles["white"],
          // color: "black",
          // elevation: 0,
          "&:hover": {
            backgroundColor: "#7d6dcd"
          }
        },
        outlined: {
          borderColor: styles["secondary_dark"],
          color: styles["secondary_dark"],
          "&:hover": {
            backgroundColor: styles["primary_light"]
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "40px",
          height: "40px",
          maxHeight: "40px",
          marginBottom: "10px"
        },
        indicator: {
          height: "0px",
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "600",
          minHeight: "40px",
          height: "40px",
          maxHeight: "40px",
          borderRadius: "12px",
          marginRight: "10px",
          "&:hover": {
            backgroundColor: "#f1f1f1",
          },
          "&.Mui-selected": {
            color: styles['purple'],
            backgroundColor: styles['purple_light'],
          }
        },
      }
    },
  }
})