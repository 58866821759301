import React from 'react';

import { 
  Box,
  MenuItem,
  Popover, 
  Typography
} from '@mui/material';

import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AddCardIcon from '@mui/icons-material/AddCard';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

const CreatePopover = ({
  open, 
  anchor,
  onClose
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ pt: "5px", pb: "5px" }}>
        {/* <MenuItem>
          <Box p={"5px"} gap={"10px"}
            className="flexCenter_Row">
            <StoreOutlinedIcon />
            <Typography variant='body2'>Vendor</Typography>
          </Box>
        </MenuItem> */}
        <MenuItem>
          <Box p={"5px"} gap={"10px"}
            className="flexCenter_Row">
            <ReceiptLongOutlinedIcon />
            <Typography variant='body2'>Bill</Typography>
          </Box>
        </MenuItem>
        <MenuItem>
          <Box p={"5px"} gap={"10px"}
            className="flexCenter_Row">
            <PaymentOutlinedIcon />
            <Typography variant='body2'>Payment</Typography>
          </Box>
        </MenuItem>
        {/* <MenuItem>
          <Box p={"5px"} gap={"10px"}
            className="flexCenter_Row">
            <GroupsOutlinedIcon />
            <Typography variant='body2'>Customer</Typography>
          </Box>
        </MenuItem> */}
        <MenuItem>
          <Box p={"5px"} gap={"10px"}
            className="flexCenter_Row">
            <DescriptionOutlinedIcon />
            <Typography variant='body2'>Invoice</Typography>
          </Box>
        </MenuItem>
        <MenuItem>
          <Box p={"5px"} gap={"10px"}
            className="flexCenter_Row">
            <ShoppingCartOutlinedIcon />
            <Typography variant='body2'>Expense</Typography>
          </Box>
        </MenuItem>
        <MenuItem>
          <Box p={"5px"} gap={"10px"}
            className="flexCenter_Row">
            <AddCardIcon />
            <Typography variant='body2'>Virtual Card</Typography>
          </Box>
        </MenuItem>
      </Box>
    </Popover>
  )
}

export default CreatePopover;