import React from 'react';

import {
  Box,
  Button,
  Divider,
  Modal, 
  Typography
} from '@mui/material';

import style from "../../global.scss";

const TimeoutModal = ({
  open, 
  setOpen
}) => {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box minWidth={"400px"} className="modal">
          <Box width={"100%"}
            sx={{ margin: "15px 15px 30px 15px" }}>
            <Typography variant='h6' sx={{ color: style["secondary"] }}>
              Timed out due to inactivity
            </Typography>
            <Typography variant='body1'>
              Click continue to continue using else click logout.
            </Typography>
          </Box>
          <Divider />
          <Box className="flexCenterFERow"
            sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Continue
            </Button>
            <Button 
              variant='contained'>
              Logout
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default TimeoutModal;