import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  Modal, 
  TextField, 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateBranch, 
  GetBranches, 
  UpdateBranch 
} from '../../../../redux/actions/main/BranchesActions';

import "./styles.scss";

const Data = {
  DisplayName: "",
  LegalName: "",
  Address: "",
  City: "",
  State: "",
  Country: "",
  PinCode: "",
  TaxId: "",
  BranchType: ""
};

const BranchesModal = ({
  open, 
  setOpen,
  title,
  update,
}) => {
  const dispatch = useDispatch();
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open) {
      if(update?.defaultMode === 1){
        // mode = 1 - create branch
        setMode(1);
      }else if(update?.defaultMode === 2 || update?.defaultMode === 3){
        // mode = 2 - edit branch
        setMode(2);
        setFormData({
          ...formData,
          // DisplayName: update?.DisplayName || "",
          LegalName: update?.legal_name || "",
          Address: update?.address || "",
          City: update?.city || "",
          State: update?.state || "",
          Country: update?.country || "",
          PinCode: update?.pincode || "",
          TaxId: update?.tax_id || "",
          // BranchType: update?.BranchType || "",
        })
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> create branch
        let obj = {
          "display_name": formData.LegalName,
          "legal_name": formData.LegalName,
          "address": formData.Address,
          "city": formData.City,
          "state": formData.State,
          "country": formData.Country,
          "pincode": formData.PinCode,
          "tax_id": formData.TaxId,
          "branch_type": update?.defaultMode === 3 ? 'head-office' : "sub-office",
        };
        dispatch(CreateBranch(companyId, obj, setLoading)).then(() => {
          dispatch(GetBranches(companyId, 1, 100, setLoading)).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2){
        // mode = 2 -> edit branch
        let obj = {
          "display_name": formData.LegalName,
          "legal_name": formData.LegalName,
          "address": formData.Address,
          "city": formData.City,
          "state": formData.State,
          "country": formData.Country,
          "pincode": formData.PinCode,
          "tax_id": formData.TaxId,
          "branch_type": update?.defaultMode === 3 ? 'head-office' : "sub-office",
        };
        dispatch(UpdateBranch(companyId, update?.id, obj, setLoading)).then(() => {
          dispatch(GetBranches(companyId, 1, 100, setLoading)).then(() => {
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className={`fullpage__modal animate__animated animate__fadeIn`}>
   
        <ModalTopBar
          onBack={handleClose}
          title={title} 
          />

        <Container 
          maxWidth="sm">
          <Box 
            className="flex__Column"
            gap={"20px"}
            mt="20px">
            
            <Box 
              className="flexFSSBRow"
              gap="15px">
              {/* <TextField 
                label="Display Name"
                fullWidth
                name="DisplayName"
                value={formData.DisplayName}
                onChange={handleChange}
                /> */}
              <TextField 
                label="Location name"
                fullWidth
                name="LegalName"
                value={formData.LegalName}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexFSSBRow"
              gap="15px">
              <TextField 
                label="Address"
                fullWidth
                name="Address"
                value={formData.Address}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexFSSBRow"
              gap="15px">
              <TextField 
                label="City"
                fullWidth
                name="City"
                value={formData.City}
                onChange={handleChange}
                />
              <TextField 
                label="State"
                fullWidth
                name="State"
                value={formData.State}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexFSSBRow"
              gap="15px">
              <TextField 
                label="Country"
                fullWidth
                name="Country"
                value={formData.Country}
                onChange={handleChange}
                />
              <TextField 
                label="Postal code"
                fullWidth
                name="PinCode"
                type='number'
                value={formData.PinCode}
                onChange={handleChange}
                />
            </Box>
            {/* <Box 
              className="flexFSSBRow"
              gap="15px">
              <TextField 
                label="Branch Type"
                fullWidth
                name="BranchType"
                value={formData.BranchType}
                onChange={handleChange}
                />
              <TextField 
                label="Tax Id"
                fullWidth
                name="TaxId"
                value={formData.TaxId}
                onChange={handleChange}
                />
            </Box> */}
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleSubmit}>
              {mode === 1 ? "Submit" : "Update"}
            </LoadingButton>
          </Box>
        </Container>
      </Box>
    </Modal>
  )
}

export default BranchesModal;