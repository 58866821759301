import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../../components/topBar/TopBar';
import TabsPanel from '../../../components/tabs/TabsPanel';
import CompanyPage from './companyPage/CompanyPage';
import UsersPage from './usersPage/UsersPage';
import SubscriptionsPage from './subscriptionsPage/SubscriptionsPage';

const MainIndex = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(localStorage.getItem("mainTabSET"));

  useEffect(() => {
    if("child" in params){
      switch (params.child) {
        case "company": 
          localStorage.setItem("mainTabSET", "1"); 
          setCurrentTab("1"); 
          break;
        case "branch": 
          localStorage.setItem("mainTabSET", "2"); 
          setCurrentTab("2"); 
          break;
        case "users": 
          localStorage.setItem("mainTabSET", "3"); 
          setCurrentTab("3"); 
          break;
        case "subscriptions": 
          localStorage.setItem("mainTabSET", "4"); 
          setCurrentTab("4"); 
          break;

        default: {
          localStorage.setItem("mainTabSET", "1");
          setCurrentTab("1");
          navigate("/settings");
        }
      }
      navigate("/settings")
    }else{
      if(!localStorage.getItem("mainTabSET")){
        localStorage.setItem("mainTabSET", "1");
        setCurrentTab("1");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TopBar
        title="Settings - Main"
        />
      
      <Box className="flexCenterSBRow" mb={"10px"}>
        <TabsPanel
          value={currentTab+""}
          handleChange={(e, value) => {
            localStorage.setItem("mainTabSET", value);
            setCurrentTab(value);
          }}
          links={[
            { value: "1", label: "Company", path: "/company" },
            // { value: "2", label: "Branch", path: "/branch" },
            { value: "3", label: "Users", path: "/users" },
            { value: "4", label: "Subscription", path: "/subscriptions" }
          ]}
          />
      </Box>
      {
        currentTab === "1"
        ?
          <CompanyPage />
          :
            currentTab === "2"
            ?
              <UsersPage />
              :
                currentTab === "3"
                ?
                  <UsersPage />
                  :
                    currentTab === "4"
                    ?
                      <SubscriptionsPage />
                      :
                      <></>
      }
    </Box>
  )
}

export default MainIndex;