import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  Modal, 
  TextField, 
} from '@mui/material';

import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../components/buttons/Button';

import { 
  ActOnExpenseForApproval 
} from '../../../redux/actions/expense/expensesActions';

import "./styles.scss";

const Data = {
  Message: ""
};

const ApprovalsModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet,
  handleClearSelectedExpensesList
}) => {
  const dispatch = useDispatch();
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open) {
      if(update?.defaultMode === 1){
        // mode = 1 - reject expense
        setMode(1);
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> reject expense
        let obj = {
          "expenses_list": update?.expensesList.map(i => {
            return {
              "expense_id": i,
              "action": update?.action,
              "reject_message": formData.Message || ""
            };
          })
        };
        dispatch(ActOnExpenseForApproval(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClearSelectedExpensesList();
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className={`fullpage__modal animate__animated animate__fadeIn`}>
          
        <ModalTopBar
          onBack={handleClose}
          title={title} 
          />

        <Container 
          maxWidth="sm">
          <Box 
            className="flex__Column"
            gap={"20px"}
            pt="20px">
            <Box 
              className="flexFSSBRow"
              gap="15px">
              <TextField 
                label={update?.expensesList?.length > 1 
                  ? "Common rejection comment" 
                  : "Rejection comment"}
                fullWidth
                multiline
                minRows={2}
                maxRows={10}
                name="Message"
                value={formData.Message}
                onChange={handleChange}
                inputProps={{
                  maxLength: 2048
                }}
                />
            </Box>
            <LoadingButton
              variant="contained"
              loading={loading}
              disabled={!(formData.Message !== "")}
              onClick={handleSubmit}>
              {mode === 1 ? "Submit" : "Update"}
            </LoadingButton>
          </Box>
        </Container>
      </Box>
    </Modal>
  )
}

export default ApprovalsModal;