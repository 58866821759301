import * as actionTypes from "../actionTypes.js";

const initialState = {
  branchesList: null,
  employeesList: null,

  subscriptionsList: null,
  mySubscription: null, 
  planAddonsList: null
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BRANCHES_MAIN:
      return { ...state, branchesList: action.payload };
    case actionTypes.GET_EMPLOYEE_MAIN:
      return { ...state, employeesList: action.payload };

    case actionTypes.GET_SUBSCRIPTIONS:
      return { ...state, subscriptionsList: action.payload };
    case actionTypes.GET_MY_SUBSCRIPTION:
      return { ...state, mySubscription: action.payload };
    case actionTypes.GET_PLAN_ADDONS:
      return { ...state, planAddonsList: action.payload };

    case actionTypes.SELECT_COMPANY:
      return initialState;
    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default mainReducer;