import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../../components/topBar/TopBar';
import TabsPanel from '../../../components/tabs/TabsPanel';
import ChartOfAccountsPage from './chartOfAccountsPage/ChartOfAccountsPage';
import SalesTaxPage from './salesTaxPage/SalesTaxPage';
import ProductsPage from './productsPage/ProductsPage';

const AccountingIndex = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(localStorage.getItem("accountingTabSET"));

  useEffect(() => {
    if("child" in params){
      switch (params.child) {
        case "chart-of-accounts":
          localStorage.setItem("accountingTabSET", "1"); 
          setCurrentTab("1"); 
          break;
        case "sales-tax":
          localStorage.setItem("receiveTabSET", "2"); 
          setCurrentTab("2"); 
          break;
        case "products":
          localStorage.setItem("receiveTabSET", "3"); 
          setCurrentTab("3"); 
          break;

        default: {
          localStorage.setItem("accountingTabSET", "1");
          setCurrentTab("1");
          navigate("/settings/accounting");
        }
      }
      navigate("/settings/accounting")
    }else{
      if(!localStorage.getItem("accountingTabSET")){
        localStorage.setItem("accountingTabSET", "1");
        setCurrentTab("1");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TopBar
        title="Settings - Accounting"
        />
      
      <Box className="flexCenterSBRow" mb={"10px"}>
        <TabsPanel
          value={currentTab+""}
          handleChange={(e, value) => {
            localStorage.setItem("accountingTabSET", value);
            setCurrentTab(value);
          }}
          links={[
            { value: "1", label: "Chart of Accounts", path: "/chart-of-accounts" },
            { value: "2", label: "Sales tax", path: "/sales-tax" },
            { value: "3", label: "Products", path: "/products" },
          ]}
          />
      </Box>
      {
        currentTab === "1"
        ?
          <ChartOfAccountsPage />
          :
            currentTab === "2"
            ?
              <SalesTaxPage />
              :
                currentTab === "3"
                ?
                  <ProductsPage />
                  :
                    <></>
      }
    </Box>
  )
}

export default AccountingIndex;