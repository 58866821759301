import Router from "./routes/router";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { theme } from "./theme/MuiTheme";

// import { API } from "./api";

// import { 
//   RefreshToken 
// } from "./redux/actions/authActions";

import { 
  ThemeProvider
} from "@mui/material";

// import {
//   LOG_OUT
// } from "./redux/actionTypes";

import './App.scss';

function App() {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const checkTokenOnLoad = async () => {
  //     const refreshToken = JSON.parse(localStorage.getItem("refresh_token"));

  //     if (refreshToken) {
  //       try {
  //         // Attempt to refresh the token on app load
  //         await dispatch(RefreshToken({ refresh_token: refreshToken })).then(({res, statusCode}) => {
  //           if(statusCode === 200){
  //             API.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`;
  //           };
  //         });
  //       } catch (error) {
  //         console.log("LOGGIN out 1", error)
  //         // If refresh token is invalid/expired, log the user out
  //         dispatch({ type: LOG_OUT });
  //       }
  //     } else {
  //       console.log("LOGGIN out 2")
  //       // If no refresh token found, log out the user
  //       dispatch({ type: LOG_OUT });
  //     }
  //   };

  //   checkTokenOnLoad();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
}

export default App;
