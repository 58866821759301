import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { 
  Box, 
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Menu, 
  MenuItem, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../../components/textFields/SearchTextField';
import TableLoading from '../../../../components/loaders/TableLoading';
import NATable from '../../../../components/utils/NATable';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  DeleteSalesTax,
  GetSalesTaxes 
} from '../../../../redux/actions/accounting/salesTaxActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import SalesTaxModal from './SalesTaxModal';

import global from "../../../../global.scss";
import "./styles.scss";

const SalesTaxPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.accounting);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [salesTaxModalOpen, setSalesTaxModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [salesTaxList, setSalesTaxList] = useState([]);
  const [salesTaxTotal, setSalesTaxTotal] = useState(0);

  const [salesTaxMenuOpen, setSalesTaxMenuOpen] = useState(false);
  const [salesTaxMenuAnchor, setSalesTaxMenuAnchor] = useState(null);
    
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Name | Abbreviation",
      sort: "name",
    },
    {
      label: "Rate",
      sort: null,
    },
    {
      label: "Show tax number on invoices",
      sort: "is_tax_num_shown_on_invoice",
    },
    {
      label: "Recoverable tax",
      sort: "is_tax_recoverable",
    },
    {
      label: "",
      sort: null,
      align: "right"
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetSalesTaxes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatSalesTaxList(state.salesTaxesList.records || []);
      setSalesTaxTotal(state.salesTaxesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatSalesTaxList = (list) => {
    setSalesTaxList(list);
  };

  async function handleGetSalesTaxes(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetSalesTaxes(companyId, page+1, rowsPerPage, 0, query, customLoading ? customLoading : setLoading));
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteSalesTax(companyId, deleteId, setDeleting)).then(() => {
      handleGetSalesTaxes(setDeleting).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <SalesTaxModal
        open={salesTaxModalOpen}
        setOpen={setSalesTaxModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetSalesTaxes}
        />

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <Box 
        className="flexCenterSBRow" 
        gap="15px"
        mb={"10px"}>
        <Box></Box>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
          <Button variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Create sales tax");
              setSalesTaxModalOpen(!salesTaxModalOpen);
            }}>
            Add Sales Tax
          </Button>
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <Table className='table'>
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                salesTaxList.length === 0
                ?
                <NATable
                  label={"No sales tax found"}
                  />
                :
                salesTaxList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Box className="flexCenter_Row" gap="10px">
                        <Box>
                          {row.name}&nbsp;({row.abbreviation})
                        </Box>
                        {
                          row.is_compound_tax === true
                          &&
                          <Chip
                            size='small'
                            color='info'
                            label="Compound tax"
                            />
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        className="flexCenter_Row"
                        gap={"10px"}> 
                        {
                          row.associated_sale_taxes.length > 0
                          &&
                          <Typography 
                          fontWeight={600}>
                            {row.associated_sale_taxes[dayjs(row.associated_sale_taxes[0].effective_date) > dayjs() ? 1 : 0].tax_rate_percent}%
                          </Typography>
                        }
                        {
                          dayjs(row.associated_sale_taxes[0].effective_date) > dayjs()
                          &&
                          <Chip 
                            size='small'
                            color='info'
                            label={
                              <>
                                {row.associated_sale_taxes[0].tax_rate_percent}%
                                from&nbsp;
                                {dayjs(row.associated_sale_taxes[0].effective_date).format("MMM DD, YYYY")}
                              </>
                            }
                            />
                        }
                      </Box>
                    </TableCell>
                    <TableCell>{row.is_tax_num_shown_on_invoice ? "Yes" : "No"}</TableCell>
                    <TableCell>{row.is_tax_recoverable ? "Yes" : "No"}</TableCell>
                    <TableCell
                      align='right'>
                      {
                        row.sales_tax_id === deleteId && deleting
                        ?
                        <CircularProgress size={26} sx={{ margin: "7px" }}/>
                        :
                        <IconButton
                          onClick={(e) => {
                            setSalesTaxMenuAnchor(e.currentTarget);
                            setSalesTaxMenuOpen(true);
                            setDeleteId(row.sales_tax_id);
                            setUpdate(row);
                            setModalTitle(row?.name || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      } 
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={salesTaxTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={salesTaxMenuOpen}
        anchorEl={salesTaxMenuAnchor}
        onClose={() => setSalesTaxMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setSalesTaxMenuOpen(false);
            setSalesTaxModalOpen(!salesTaxModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSalesTaxMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default SalesTaxPage;