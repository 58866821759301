import * as actionTypes from "../actionTypes.js";

const initialState = {
  companiesList: null,
  companyInfo: null,

  myProfile: null,
  userBankAccount: null
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANIES_DASHBOARD:
      return { ...state, companiesList: action.payload };
    case actionTypes.GET_COMPANY_DASHBOARD:
      return { ...state, companyInfo: action.payload };
    case actionTypes.SELECT_COMPANY:
      return { ...state, companyInfo: null };

    case actionTypes.GET_MY_PROFILE_DASHBOARD:
      return { ...state, myProfile: action.payload };
    case actionTypes.GET_USER_BANK_ACCOUNT_DASHBOARD:
      return { ...state, userBankAccount: action.payload };


    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default dashboardReducer;