//ERRORS
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//CRASHES
export const SET_CRASHES = "SET_CRASHES";
export const CLEAR_CRASHES = "CLEAR_CRASHES";

//MESSAGE
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//AUTH
export const LOG_IN = "LOG_IN";
export const REGISTER = "REGISTER";
export const LOG_OUT = "LOG_OUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

//GUEST
export const GUEST_AUTHENICATE = "GUEST_AUTHENICATE";


//DASHBOARD
export const GET_COMPANIES_DASHBOARD = "GET_COMPANIES_DASHBOARD";
export const GET_COMPANY_DASHBOARD = "GET_COMPANY_DASHBOARD";
export const GET_MY_PROFILE_DASHBOARD = "GET_MY_PROFILE_DASHBOARD";

export const GET_USER_BANK_ACCOUNT_DASHBOARD = "GET_USER_BANK_ACCOUNT_DASHBOARD";

export const GET_COMPANY_ROLE_DASHBOARD = "GET_COMPANY_ROLE_DASHBOARD";
export const CLEAR_COMPANY_ROLE_DASHBOARD = "CLEAR_COMPANY_ROLE_DASHBOARD";

//PROFILE
export const GET_ASSOCIATED_COMPANIES = "GET_ASSOCIATED_COMPANIES";
export const GET_MY_PROFILE = "GET_MY_PROFILE";
export const SELECT_COMPANY = "SELECT_COMPANY";
export const CLEAR_COMPANY = "CLEAR_COMPANY";

//COMPANY
export const GET_COMPANY_INFO = "GET_COMPANY_INFO";


// ANALYTICS
export const GET_BILLS_STATS_ANA = "GET_BILLS_STATS_ANA";
export const GET_INVOICES_STATS_ANA = "GET_INVOICES_STATS_ANA";
export const GET_EXPENSE_STATS_ANA = "GET_EXPENSE_STATS_ANA";

// MAIN
// branches
export const GET_BRANCHES_MAIN = "GET_BRANCHES_MAIN";
// employees
export const GET_EMPLOYEE_MAIN = "GET_EMPLOYEE_MAIN";
// subscription
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_MY_SUBSCRIPTION = "GET_MY_SUBSCRIPTION";
export const GET_PLAN_ADDONS = "GET_PLAN_ADDONS";
export const GET_MY_PLAN_ADDONS = "GET_MY_PLAN_ADDONS";


// ACCOUNTING
export const GET_ACCOUNT_TYPES_ACCOUNTING = "GET_ACCOUNT_TYPES_ACCOUNTING";
export const GET_CHART_OF_ACCOUNTS_ACCOUNTING = "GET_CHART_OF_ACCOUNTS_ACCOUNTING";
export const GET_CHART_OF_ACCOUNTS_INCOME_ACCOUNTING = "GET_CHART_OF_ACCOUNTS_INCOME_ACCOUNTING";
export const GET_CHART_OF_ACCOUNTS_EXPENSES_ACCOUNTING = "GET_CHART_OF_ACCOUNTS_EXPENSES_ACCOUNTING";


// PAY
// vendors
export const GET_VENDORS_PAY = "GET_VENDORS_PAY";
export const GET_VENDOR_DETAILS_PAY = "GET_VENDOR_DETAILS_PAY";
export const CLEAR_VENDOR_DETAILS_PAY = "CLEAR_VENDOR_DETAILS_PAY";

export const GET_VENDOR_ACCOUNTS_PAY = "GET_VENDOR_ACCOUNTS_PAY";
export const CLEAR_VENDOR_ACCOUNTS_PAY = "CLEAR_VENDOR_ACCOUNTS_PAY";

// company bank accounts
export const GET_BANKS_PAY = "GET_BANKS_PAY";
export const GET_BANK_ACCOUNTS_TYPES_PAY = "GET_BANK_ACCOUNTS_TYPES_PAY";
export const GET_COMPANY_BANK_ACCOUNTS_PAY = "GET_COMPANY_BANK_ACCOUNTS_PAY";
export const GET_COMPANY_BANK_ACCOUNT_DETAILS_PAY = "GET_COMPANY_BANK_ACCOUNT_DETAILS_PAY";
export const CLEAR_COMPANY_BANK_ACCOUNT_DETAILS_PAY = "CLEAR_COMPANY_BANK_ACCOUNT_DETAILS_PAY";

// bills
export const GET_BILLS_PAY = "GET_BILLS_PAY";
export const GET_BILL_PAY = "GET_BILL_PAY";
export const GET_PAYMENT_FREQUENCIES_PAY = "GET_PAYMENT_FREQUENCIES_PAY";

//approval workflows
export const GET_APPROVAL_WORKFLOWS_PAY = "GET_APPROVAL_WORKFLOWS_PAY";

// payments
export const GET_PAYMENTS_PAY = "GET_PAYMENTS_PAY";
export const GET_PAYMENT_PAY = "GET_PAYMENT_PAY";
export const GET_PAYMENT_FEES_PAY = "GET_PAYMENT_FEES_PAY";
export const GET_SERVICES_FOR_PRODUCT_PAY = "GET_SERVICES_FOR_PRODUCT_PAY";
export const GET_SERVICES_FOR_PRODUCT_FOR_GUEST_PAY = "GET_SERVICES_FOR_PRODUCT_FOR_GUEST_PAY";
export const GET_PAYMENT_ACTIVITIES_PAY = "GET_PAYMENT_ACTIVITIES_PAY";

// pay approval
export const GET_PAYMENTS_FOR_APPROVER_PAY = "GET_PAYMENTS_FOR_APPROVER_PAY";


//RECEIVE
// payment handle
export const GET_PAYMENT_HANDLE_RECEIVE = "GET_PAYMENT_HANDLE_RECEIVE";
// payment qrcode
export const GET_PAYMENT_QR_CODE_RECEIVE = "GET_PAYMENT_QR_CODE_RECEIVE";

//sales-tax
export const GET_SALES_TAXES_ACCOUNTING = "GET_SALES_TAXES_ACCOUNTING";

//products
export const GET_PRODUCTS_ACCOUNTING = "GET_PRODUCTS_ACCOUNTING";

//customers
export const GET_CUSTOMERS_RECEIVE = "GET_CUSTOMERS_RECEIVE";

//invoices
export const GET_INVOICES_RECEIVE = "GET_INVOICES_RECEIVE";
export const GET_INVOICE_DETAILS_RECEIVE = "GET_INVOICE_DETAILS_RECEIVE";

// receipts
export const GET_RECEIPTS_RECEIVE = "GET_RECEIPTS_RECEIVE";

//EXPENSE
//expense
export const GET_EXPENSES_EXP = "GET_EXPENSES_EXP";
export const GET_EXPENSES_DETAILS_EXP = "GET_EXPENSES_DETAILS_EXP";
export const GET_EXPENSE_MESSAGAES_EXP = "GET_EXPENSE_MESSAGAES_EXP";
export const GET_EXPENSES_FOR_APPROVER_EXP = "GET_EXPENSES_FOR_APPROVER_EXP";

export const GET_REIMBURSEMENTS_EXP = "GET_REIMBURSEMENTS_EXP";

//cost account
export const GET_COST_ACCOUNTS_EXP = "GET_COST_ACCOUNTS_EXP";

//cost object
export const GET_COST_OBJECTS_EXP = "GET_COST_OBJECTS_EXP";

//categories
export const GET_CATEGORIES_EXP = "GET_CATEGORIES_EXP";

//approval workflows
export const GET_APPROVAL_WORKFLOWS_EXP = "GET_APPROVAL_WORKFLOWS_EXP";

// image view
export const OPEN_IMAGE_VIEWER = "OPEN_IMAGE_VIEWER";
export const CLOSE_IMAGE_VIEWER = "CLOSE_IMAGE_VIEWER";
export const SET_IMAGES_URL = "SET_IMAGES_URL";
export const CLEAR_IMAGES_URL = "CLEAR_IMAGES_URL";


// WALLET
// real cards
export const GET_REAL_CARDS_WALLET = "GET_REAL_CARDS_WALLET";

// spend wallets
export const GET_SPEND_WALLETS_WALLET = "GET_SPEND_WALLETS_WALLET";
export const GET_SPEND_WALLETS_EMPLOYEES_WALLET = "GET_SPEND_WALLETS_EMPLOYEES_WALLET";
export const GET_EMPLOYEE_WALLETS_WALLET = "GET_EMPLOYEE_WALLETS_WALLET";

// vcn
export const GET_VCN_WALLET = "GET_VCN_WALLET";
export const GET_VCN_DETAILS_WALLET = "GET_VCN_DETAILS_WALLET";

// miscellaneous
export const GET_NETWORK_WALLET = "GET_NETWORK_WALLET";
export const GET_WALLET_FREQUENCY_WALLET = "GET_WALLET_FREQUENCY_WALLET";


// BILLING
export const GET_BILLING_PAY_BILL = "GET_BILLING_PAY_BILL";
export const GET_BILLING_RECEIVE_BILL = "GET_BILLING_RECEIVE_BILL";
export const GET_BILLING_EXPENSE_BILL = "GET_BILLING_EXPENSE_BILL";
export const GET_BILLING_CROSS_BORDER_PAYMENT_BILL = "GET_BILLING_CROSS_BORDER_PAYMENT_BILL";
export const GET_PLAN_BILLING_BILL = "GET_PLAN_BILLING_BILL";
export const GET_ADDON_BILLING_BILL = "GET_ADDON_BILLING_BILL";


// MAKE PAYMENT
export const GET_COMPANY_MAKEPAYMENT = "GET_COMPANY_MAKEPAYMENT";
export const CLEAR_COMPANY_MAKEPAYMENT = "CLEAR_COMPANY_MAKEPAYMENT";
export const GET_INVOICE_MAKEPAYMENT = "GET_INVOICE_MAKEPAYMENT";
export const CLEAR_INVOICE_MAKEPAYMENT = "CLEAR_INVOICE_MAKEPAYMENT";


//MISCELLANEOUS
export const GET_EMPLOYEES_SEARCH = "GET_EMPLOYEES_SEARCH";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_HOLIDAYS = "GET_HOLIDAYS";