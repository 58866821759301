import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Menu, 
  MenuItem, 
  Switch, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../../components/textFields/SearchTextField';
import TableLoading from '../../../../components/loaders/TableLoading';
import NameAvatar from '../../../../components/avatars/Avatar';
import NATable from '../../../../components/utils/NATable';
import NA from '../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

import { 
  GetVendors,
  UpdateVendor
} from '../../../../redux/actions/pay/vendorsActions';

import { 
  GetBankAccountTypes, 
  GetBanks 
} from '../../../../redux/actions/pay/companyBankAccountsActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import CreateVendorsModal from './CreateVendorsModal';
import VendorDetailsModal from './VendorDetailsModal';

import global from "../../../../global.scss";
import "./styles.scss";

const VendorsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [createVendorsModalOpen, setCreateVendorsModalOpen] = useState(false);
  const [vendorDetailsModalOpen, setVendorDetailsModalOpen] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [vendorsMenuOpen, setVendorsMenuOpen] = useState(false);
  const [vendorsMenuAnchor, setVendorsMenuAnchor] = useState(null);

  const [vendorsList, setVendorsList] = useState([]);
  const [vendorsTotal, setVendorsTotal] = useState(0);

  const [deleteId, setDeleteId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [deleting, setDeleting] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [toggling, setToggling] = useState(false);
  
  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Vendor",
      sort: "name",
    },
    {
      label: "Contact person",
      sort: "contact_name",
    },
    {
      label: "Active",
      sort: "is_active",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    dispatch(GetBankAccountTypes(companyId, 1, 50, setLoading2));
    dispatch(GetBanks(companyId, 1, 500, setLoading2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetVendors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatVendorsList(state.vendorsList.records || []);
      setVendorsTotal(state.vendorsList.total || 0);
    } catch (error) {}
  }, [state]);

  // -------------- list formatter --------------
  const formatVendorsList = (list) => {
    setVendorsList(list);
  };

  async function handleGetVendors(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetVendors(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const handleDelete = () => {
    // setDeleteConfirmationModalOpen(false);
    // dispatch(DeleteVendor(companyId, deleteId, setDeleting)).then(() => {
    //   dispatch(GetVendors(companyId, page+1, rowsPerPage, setDeleting)).then(() => {
    //     setDeleteId(null);
    //   })
    // })
  };

  const handleToggleVendor = (id, bool) => {
    dispatch(UpdateVendor(companyId, id, { is_active: bool }, setToggling)).then(() => {
      handleGetVendors(setToggling);
    })
  };

  return (
    <Box>
      <CreateVendorsModal 
        open={createVendorsModalOpen}
        setOpen={setCreateVendorsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetVendors}
        />

      <VendorDetailsModal 
        open={vendorDetailsModalOpen}
        setOpen={setVendorDetailsModalOpen}
        title={modalTitle}
        update={update}
        onMethod={(e) => {
          setUpdate(e);
          setCreateVendorsModalOpen(!createVendorsModalOpen);
          setVendorDetailsModalOpen(false);
        }}
        handleGet={handleGetVendors}
        />

      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete vendor - " + modalTitle} 
        onConfirm={handleDelete}
        onCancel={() => setDeleteId(null)}
        />

        
      <Box 
        className="flexCenterSBRow" 
        mb={"15px"}>
        <Box></Box>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
          <Button variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1 
              });
              setModalTitle("");
              setCreateVendorsModalOpen(!createVendorsModalOpen);
            }}>
            Add Vendor
          </Button>
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <Table className="table">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                vendorsList.length === 0
                ?
                <NATable
                  label={"No vendor found"}
                  />
                :
                vendorsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell
                      onClick={() => {
                        setModalTitle(row.name || <NA />);
                        setUpdate(row);
                        setVendorDetailsModalOpen(!vendorDetailsModalOpen);
                      }}>
                      <Box 
                        className="flexCenter_Row"
                        gap={"10px"}>
                        <Box
                          className="flexCenter_Row"
                          gap={"10px"}>
                          <NameAvatar 
                            color={row.color}>
                            {row.name ||  <NA />}
                          </NameAvatar>
                          <Typography
                            className='tablecell__clickable'>
                            {row.name ||  <NA />}
                          </Typography>
                        </Box>
                        {
                          row?.associated_vendor_bank_account.length === 0
                          &&
                          <Chip 
                            color='error'
                            size='small'
                            label={"No bank account addded"}
                            />
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>
                          {row.contact_name || <NA label={"No contact person added"}/>}
                        </Typography>
                      </Box>
                      <Typography 
                        variant='caption'
                        color={"grey"}>
                        {row.email || ""}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={row.is_active} 
                        onChange={(e) => {
                          handleToggleVendor(row.vendor_id, e.target.checked);
                        }}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        row.vendor_id === deleteId && deleting
                        ?
                        <CircularProgress size={26} sx={{ margin: "7px" }}/>
                        :
                        <IconButton
                          onClick={(e) => {
                            setVendorsMenuAnchor(e.currentTarget);
                            setVendorsMenuOpen(true);
                            setDeleteId(row.vendor_id);
                            setUpdate(row);
                            setModalTitle(row.name);
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={vendorsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }

      <Menu
        open={vendorsMenuOpen}
        anchorEl={vendorsMenuAnchor}
        onClose={() => setVendorsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setVendorsMenuOpen(false);
            setVendorDetailsModalOpen(!vendorDetailsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <ReceiptLongOutlinedIcon />
            View
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setUpdate({
              ...update, 
              defaultMode: 2,
            });
            setVendorsMenuOpen(false);
            setVendorDetailsModalOpen(!vendorDetailsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            setVendorsMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem> */}
      </Menu>
    </Box>
  )
}

export default VendorsPage