import React from 'react';

import { 
  Box,
  Skeleton
 } from '@mui/material';

import "../../global.scss";

const TableLoading = () => {
  return (
    <Box 
      className="flex__Column">
      <Skeleton
        variant="rectangular" 
        animation="wave"
        width={"100%"} height={"40.5px"} 
        sx={{ borderRadius: "16px 16px 0px 0px", bgcolor: 'grey.200', mb: "1px" }} />
      <Skeleton
        variant="rectangular" 
        animation="wave"
        width={"100%"} height={"56px"} 
        sx={{ bgcolor: 'grey.100', mb: "1px"  }} />
      <Skeleton
        variant="rectangular" 
        animation="wave"
        width={"100%"} height={"56px"} 
        sx={{ bgcolor: 'grey.100', mb: "1px"  }} />
      <Skeleton
        variant="rectangular" 
        animation="wave"
        width={"100%"} height={"56px"} 
        sx={{ bgcolor: 'grey.100', mb: "1px"  }} />
      <Skeleton
        variant="rectangular" 
        animation="wave"
        width={"100%"} height={"52px"} 
        sx={{ bgcolor: 'grey.100' }} />
    </Box>
  )
}

export default TableLoading;