import * as actionTypes from "../actionTypes.js";

const initialState = {
  currenciesList: null,
  selectedCompany: null,
  employeesList: null,
  holidaysList: null,

  companyRole: null,

  // image viewer
  imageViewerOpen: false,
  imagesList: [],

  //errors  
  errors: null,

  //crashes
  crashes: null
};

const miscellaneousReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CURRENCIES:
      localStorage.setItem("CurrencyList", JSON.stringify(action.payload.records));
      return { ...state, currenciesList: action.payload };
    case actionTypes.SELECT_COMPANY:
      return { ...state, 
        selectedCompany: action.payload,
        errors: null,
        crashes: null,
        imagesList: null,
        imageViewerOpen: false,
        employeesList: null
      };
    case actionTypes.CLEAR_COMPANY:
      return { ...state, selectedCompany: null };
    case actionTypes.GET_EMPLOYEES_SEARCH:
      return { ...state, employeesList: action.payload };
    case actionTypes.GET_HOLIDAYS:
      return { ...state, holidaysList: action.payload };

    case actionTypes.GET_COMPANY_ROLE_DASHBOARD:
      return { ...state, companyRole: action.payload };
    case actionTypes.CLEAR_COMPANY_ROLE_DASHBOARD:
      return { ...state, companyRole: null };

    // image viewer
    case actionTypes.OPEN_IMAGE_VIEWER:
      return { ...state, imageViewerOpen: true };
    case actionTypes.CLOSE_IMAGE_VIEWER:
      return { ...state, imageViewerOpen: false };
    case actionTypes.SET_IMAGES_URL:
      return { ...state, imagesList: action.payload };
    case actionTypes.CLEAR_IMAGES_URL:
      return { ...state, imagesList: [] };

    // errors
    case actionTypes.SET_ERRORS:
      return { ...state, errors: action.payload };
    case actionTypes.CLEAR_ERRORS:
      return { ...state, errors: null };
  
    // crashes 
    case actionTypes.SET_CRASHES:
      return { ...state, crashes: action.payload };
    case actionTypes.CLEAR_CRASHES:
      return { ...state, crashes: null };

    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default miscellaneousReducer;