import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { 
  Avatar,
  Box, 
  Button, 
  CircularProgress, 
  Container, 
  Divider, 
  IconButton, 
  Link, 
  Skeleton, 
  TextField, 
  Typography, 
} from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import useCurrencyService from '../../components/hooks/useCurrencyService';
import AmountTextField from '../../components/textFields/AmountTextField';
import CompanyAvatar from '../../components/avatars/CompanyAvatar';
import LoadingButton from '../../components/buttons/Button';
import Logo from "../../assets/icons/logo-blue-dark2.svg";

import { 
  GetCompanyFromPaymentHandle 
} from '../../redux/actions/makePayment/companiesActions';
import { 
  GetCurrencies 
} from '../../redux/actions/miscellaneousActions';
import { 
  GetInvoiceFromToken, 
} from '../../redux/actions/makePayment/invoicesActions';

import ConfirmationModal from '../../components/modals/ConfirmationModal';
import CompaniesMenu from '../../components/menus/CompaniesMenu';
import ProfileMenu from '../../components/menus/ProfileMenu';

import LoggedInFlow from './loggedInFlow/LoggedInFlow';
import SignUpFlow from './signUpFlow/SignUpFlow';
import GuestFlow from './guestFlow/GuestFlow';

import { 
  LOG_OUT, 
  SELECT_COMPANY 
} from '../../redux/actionTypes';

import global from "../../global.scss";
import "./styles.scss";

const Data = {
  Currency: "USD",
  Price: "",
  Invoice: "",

  Date: dayjs(),
  PaymentDate: dayjs().add(4, 'day'),

  // guest user
  GuestEmail: "",

  GuestName: "",
  GuestAddress: "",
  GuestCity: "",
  GuestState: "",
  GuestCountry: "US",
  GuestPincode: "",
  GuestTaxId: "",
  GuestTaxIdType: "",
}

const MakePaymentPage = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const state = useSelector(state => state.makePayment);
  const dashboard = useSelector(state => state.dashboard);
  const other = useSelector(state => state.other);
  const [formData, setFormData] = useState(Data);
  const { 
    handleEditAmount, 
    handleCalculateAmount
  } = useCurrencyService();
  const [loading, setLoading] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingInvoice, setLoadingInvoice] = useState(false);

  const [company, setCompany] = useState(null);
  const [invoice, setInvoice] = useState(null);

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [companiesMenuOpen, setCompaniesMenuOpen] = useState(false);
  const [companiesMenuAnchor, setCompaniesMenuAnchor] = useState(null);
  const [profile, setProfile] = useState({});
  const [myCompaniesList, setMyCompaniesList] = useState([]);
  const [mySelectedCompany, setMySelectedCompany] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [step, setStep] = useState(1);
  // NEW SCHEME
  // step = 1 - amount and invoie number
  // step = 2 - select mode


  // OLD SCHEME
  // step = 1 - paying invoice
  // step = 2 - select bank account
  // step = 3 - select date & delivery method
  // step = 4 - review & confirm
  // step = 5 - payment paid or scheduled

  const [mode, setMode] = useState(0);  
  // mode = 0 - nothing
  // mode = 1 - loggedin
  // mode = 2 - signup
  // mode = 3 - guest

  useEffect(() => {
    setFormData(Data);

    // redirect from login screen
    if(location.state){
      if("step" in location.state){
        setStep(location.state.step);
      }
      if("mode" in location.state){
        setMode(location.state.mode);
      }
    } else {
      setStep(1);
      setMode(0);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if("id" in params && params.id && params.id.length > 0){
      handleGetCurrencies();
      handleGetCompany(params.id, setLoadingCompany);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      formatCompanyObj(state.company || null);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.company]);

  useEffect(() => {
    try {
      formatInvoiceObj(state.invoice || null);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.invoice]);

  useEffect(() => {
    try { 
      formatProfileObj(dashboard.myProfile || {});
      formatMyCompaniesList(dashboard.companiesList.records  || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard]);

  useEffect(() => {
    try { 
      formatMySelectedCompany(other.selectedCompany || null);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other]);

  // -------------- formatters --------------
  const formatProfileObj = (obj) => {  
    setProfile(obj);
  };

  const formatMySelectedCompany = (obj) => {
    setMySelectedCompany(obj);
  };

  const formatMyCompaniesList = (list) => {
    setMyCompaniesList(list);
  };

  const formatCompanyObj = (list) => {
    if(list.length === 1){
      setCompany(list[0]);
      handleGetInvoice(list[0].company_id, location.search.slice(1).split("="));
    }else{
      setCompany(null);
    }
  };

  const formatInvoiceObj = (list) => {
    if(list.length === 1){
      setInvoice(list[0]);
      setFormData({
        Currency: list[0].currency,
        Price: handleEditAmount(list[0].currency, list[0].total_amount),
        Invoice: list[0].invoice_number,
        InvoiceDueDate: list[0].due_date
      })
    }else{
      setInvoice(null);
    }
  };


  // -------------- api calls --------------
  async function handleGetCurrencies(){
    await dispatch(GetCurrencies());
  };
  
  async function handleGetCompany(paymentHandle, customLoading){
    await dispatch(GetCompanyFromPaymentHandle(paymentHandle, customLoading ? customLoading : setLoading));
  };

  async function handleGetInvoice(companyId, tokenArray, customLoading){
    console.log(tokenArray[1])
    if(companyId && tokenArray[0] === "t" && tokenArray[1].length > 0 ){
      // this is not companyId as `c_id` used in other apis, this route will be non-tokenized
      await dispatch(GetInvoiceFromToken(companyId, tokenArray[1], customLoading ? customLoading : setLoadingInvoice));
    }
  };

  
  // -------------- utility functions ----------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogout = () => {
    dispatch({ type: LOG_OUT });
    handleGetCurrencies();
    setConfirmationModalOpen(false);
  };

  const handleSelectCompany = (e) => {
    navigate("/switch", { state: location.pathname });
    dispatch({ type: SELECT_COMPANY, payload: e });
  };

  const handleSignUp = () => {
    navigate("/auth", { state: {
      redirectPath: location.pathname,
      step: 2,
      mode: 1
    } });
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  return (
    <Box>

      {
        <>
          <ProfileMenu
            open={profileMenuOpen}
            anchor={profileMenuAnchor} 
            profile={profile}
            onClose={() => {
              setProfileMenuOpen(false);
            }}
            navigate={navigate}
            onLogout={() => setConfirmationModalOpen(true)}
            />

          <CompaniesMenu
            open={companiesMenuOpen}
            anchor={companiesMenuAnchor} 
            companiesList={myCompaniesList}
            selectedCompany={mySelectedCompany}
            handleSelectCompany={handleSelectCompany}
            navigateSettings={(path) => navigate(path)}
            onClose={() => {
              setCompaniesMenuOpen(false);
            }}
            />

          <ConfirmationModal
            open={confirmationModalOpen}
            setOpen={setConfirmationModalOpen}
            title={"Log out"}
            onCancel={() => setConfirmationModalOpen(false)}
            onConfirm={handleLogout}
           />
        </>
      }

      <Box 
        className='makepayment__top'>
        <Container
          maxWidth="xl">
          <Box
            className="flexCenterSBRow">
            <Box className='flexCenterCenterRow'
              sx={{ "&:hover": { cursor: "pointer" }, 
                minHeight: "56px" }}
              onClick={() => navigate("/")}>
              <img
                src={Logo}
                alt="lusid-logo"
                style={{ width: "140px" }}
                />
            </Box>
            {
              // localStorage.getItem("user")
              auth.isAuthenticated
              &&
              <Box
                className="flexCenter_Row"
                gap="10px">
                <Button
                  variant='outlined'
                  sx={{ width: "250px", paddingRight: "5px", paddingLeft: "5px" }}
                  // disabled
                  onClick={(e) => {
                    setCompaniesMenuAnchor(e.currentTarget);
                    setCompaniesMenuOpen(true);
                  }}>
                  <Box 
                    className="flexCenterSBRow" 
                    width={"100%"}>
                    <Box
                      className="flexCenter_Row"
                      gap="10px">
                      <CompanyAvatar
                        color={mySelectedCompany?.Color}
                        size="small">
                        {mySelectedCompany?.LegalName || ""}
                      </CompanyAvatar>
                      <Typography 
                        variant='body1'
                        className='longText'
                        sx={{ maxWidth: "180px" }}
                        fontWeight={500}>
                        {mySelectedCompany?.LegalName || ""}
                      </Typography>
                    </Box>
                    <ExpandLessOutlinedIcon 
                      sx={{ 
                        right: "10px",
                        transition: "all .2s",
                        transform: companiesMenuOpen ? "rotate(0deg)" : "rotate(180deg)",
                      }}/>
                  </Box>
                </Button>
                <Box 
                  className="flexCenter_Row"
                  onClick={(e) => {
                    setProfileMenuAnchor(e.currentTarget);
                    setProfileMenuOpen(true);
                  }}>
                  <IconButton>
                    <Avatar
                      sx={{ backgroundColor: global["secondary"] }}>
                      <Typography
                        color={"white"}>
                        {"first_name" in profile && profile?.first_name[0]?.toUpperCase()}
                        {"last_name" in profile && profile?.last_name[0]?.toUpperCase()}
                      </Typography>
                    </Avatar>
                  </IconButton>
                </Box>
              </Box>
            }
          </Box>
        </Container>
      </Box>
     
      {
        step === 1
        ?
        <Container
          maxWidth="sm">
          {
            loadingCompany
            ?
            <Box
              className="makepayment__bottom 
                flexCenterCenterColumn">
              <CircularProgress 
                size={"65px"} 
                />
            </Box>
            :
              company
              ?
              <Box
                className="makepayment__bottom 
                  flexCenter_Column"
                  mt={"20px"}>
                
                <Typography variant='subtitle1' color={"grey"}>
                  Enter the amount and invoice number to pay
                </Typography>
                <Box
                  className="flexCenter_Row"
                  gap={"15px"}>
                  {
                    company.logo_url
                    ?
                    <img
                      src={company.logo_url}
                      alt='company-logo'
                      />
                    :
                    <CompanyAvatar 
                      size="large"
                      color={company.color}>
                      {company.company_name}
                    </CompanyAvatar>
                  }
                  <Typography variant='h4'>
                    {company.company_name}
                  </Typography>
                </Box>
                
                <Box
                  className="flex__Column"
                  sx={{ maxWidth: "260px" }}
                  gap="15px">
                  <AmountTextField 
                    currencyName="Currency"
                    currency={formData.Currency}
                    onCurrencyChange={handleChange}
                    label=""
                    fullWidth
                    autoFocus="true"
                    name="Price"
                    value={formData.Price || ""}
                    onChange={handleChange}
                    />
                  {

                  loadingInvoice
                  ?
                  <Skeleton 
                    variant="rectangular"
                    sx={{ borderRadius: "16px", height: "105px" }}
                    />
                  :
                    <>
                      <TextField 
                        variant='outlined'
                        name='Invoice'
                        fullWidth
                        label="Invoice number"
                        value={formData.Invoice}
                        onChange={handleChange}
                        InputProps={{
                          readOnly: invoice ? true : false,
                        }}
                        inputProps={{
                          maxLength: 20
                        }}
                        />
                      <Button
                        variant='text'>
                        Upload invoice file (optional)
                      </Button>
                      {
                        !invoice &&
                        <Box
                          className="flexCenterCenterRow"
                          sx={{ height: "105px" }}>
                          <Typography
                            textAlign={"center"}>
                            Not paying to any specific invoice. Payment will still go to {company.company_name}
                          </Typography>
                        </Box>

                      }
                    </>
                  }
                </Box>

                {
                  <Box
                    minWidth={"100px"}
                    width={"400px"}
                    className="flexCenterCenterRow"
                    gap={"30px"}
                    >
                    <LoadingButton
                      variant='contained'
                      fullWidth
                      disabled={!(handleCalculateAmount(formData.Currency, formData.Price) > 0)}
                      onClick={() => {
                        if(auth.isAuthenticated){
                          setStep(2);
                          setMode(1);
                        }else {
                          handleNextStep()
                        }
                      }}>
                      Schedule payment
                    </LoadingButton>
                  </Box>
                }
                <Box mb="20px" mt="40px"
                  width={"100%"}>
                  <Typography
                    textAlign={"center"}
                    color={"grey"}
                    mb={"20px"}>
                    You can pay using
                  </Typography>
                  <Box
                    className="flexCenterSERow">
                    <Box className="flexCenter_Column">
                      <Typography variant='subtitle1'>Bank transfer</Typography>
                      <Typography variant='subtitle1' color={"grey"}>Free</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="flexCenter_Column">
                  <Typography>
                    Questions?&nbsp;
                    <Link>support@lusid.ai</Link>
                  </Typography>
                  <Box>
                    <Link
                      href='https://lusid.ai/privacy-policy'
                      target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </Link>
                    &nbsp;|&nbsp;
                    <Link
                      href='https://lusid.ai/terms-and-conditions'
                      target="_blank" rel="noopener noreferrer">
                      Terms of Service
                    </Link>
                  </Box>
                  </Box>
              </Box>
              : 
              <Box
                className="makepayment__bottom 
                flexCenterCenterColumn">
                <ErrorOutlineIcon  
                  color='error'
                  sx={{ width: "60px", height: "60px" }}
                  />
                <Typography
                  variant='h5'>
                  Invalid payment link
                </Typography>
                <Button
                  variant='contained'>
                  Enter payment link manually

                </Button>
              </Box>
          }
        </Container>
        :
          step === 2
          // select mode screen
          ?
            mode === 0
            ?
            <Container
              maxWidth="sm">
              <Box
                className="flexCenter_Row"
                mt={"20px"}>
                <IconButton
                  onClick={handlePrevStep}>
                  <ArrowBackOutlinedIcon />
                </IconButton>
              </Box>
              <Box
                className="flexCenterCenterColumn"
                width={"100%"}
                height={"calc(100vh - 250px)"}>
                <Box
                  className="flex__Column"
                  minWidth={"400px"}
                  gap="20px">
                  <Typography
                    textAlign={"center"}
                    variant='h4'>
                    Let's get started
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    variant='body2'
                    color={"grey"}>
                    Pay as guest or sign up for a Lusid account to use all our payment features.
                  </Typography>
                  <TextField 
                    fullWidth
                    variant='outlined'
                    label="Email address"
                    name="GuestEmail"
                    value={formData.GuestEmail}
                    onChange={handleChange}
                    />
                  <LoadingButton
                    variant={"contained"}
                    loading={loading}
                    onClick={() => {
                      if(formData.GuestEmail !== ""){
                        setMode(3);
                      }
                    }}>
                    Pay as a guest
                  </LoadingButton> 
                  <Typography
                    textAlign={"center"}
                    variant='caption'>
                    By paying as a guest I agree to Lusid's&nbsp;
                    <Link
                      href='https://lusid.ai/privacy-policy'
                      target="_blank" rel="noopener noreferrer"
                      sx={{ fontSize: "12px" }}>
                      Privacy Policy
                    </Link>
                    &nbsp;and&nbsp;
                    <Link
                      href='https://lusid.ai/terms-and-conditions'
                      target="_blank" rel="noopener noreferrer"
                      sx={{ fontSize: "12px" }}>
                      Terms of Service
                    </Link>.
                  </Typography>
                  <Divider
                    sx={{ backgroundColor: "white" }}>
                    or
                  </Divider>
                  <LoadingButton
                    disabled={loading}
                    onClick={() => setMode(2)}>
                    Sign up and pay
                  </LoadingButton> 
                  <Typography
                    textAlign={"center"}
                    variant='body2'>
                    Already have a Lusid account?
                  </Typography>
                  <LoadingButton
                    disabled={loading}
                    variant={"outlined"}
                    onClick={() => {
                      handleLogout();
                      handleSignUp();
                      setMode(1);
                    }}>
                    Login
                  </LoadingButton> 
                </Box>
              </Box>
            </Container>
            :
              mode === 1
              ?
              <LoggedInFlow 
                onBack={() => {
                  setMode(0);
                  setStep(1);
                }}
                company={company}
                invoice={invoice}
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                />
              :
                mode === 2
                ?
                <SignUpFlow 
                  />
                :
                  mode === 3
                  ? 
                  <GuestFlow 
                    onBack={() => {
                      setMode(0);
                      setStep(1);
                    }}
                    company={company}
                    invoice={invoice}
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    />
                  :
                  <></>
            :
            <></>
      }

    </Box>
  )
}

export default MakePaymentPage;