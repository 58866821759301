import { useSelector } from 'react-redux';

const useSelectedCompany = () => {
  const state = useSelector(state => state.other);

  return (
    state.selectedCompany 
    ? state.selectedCompany.Id  
    : null
  )
}

export default useSelectedCompany;