import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Modal, 
  Skeleton, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  TextField, 
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import useCurrencyService from '../../../components/hooks/useCurrencyService';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../components/buttons/Button';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { 
  CreateInvoice, 
  CreateInvoieDuplicate, 
  DeleteInvoice, 
  GetInvoiceDetails, 
  GetInvoiceNumberExists, 
  GetInvoicePDF, 
  MarkAsSentInvoice, 
  SendInvoice, 
  UpdateInvoice
} from '../../../redux/actions/receive/invoicesActions';
import { 
  GetProducts 
} from '../../../redux/actions/accounting/productsActions';
import { 
  GetSalesTaxes 
} from '../../../redux/actions/accounting/salesTaxActions';
import { 
  UploadFiles 
} from '../../../redux/actions/miscellaneousActions';

import "./styles.scss";

const Data = {
  Currency: "USD",
  Amount: "",
  CustomerId: "",
  InvoiceNumber: "",
  POSONumber: "",
  InvoiceDate: dayjs(),
  DueDate: dayjs().add(30, 'day'),
  NoteToCustomer: "",
  ProductId: ""
};

const InvoicesModal = ({
  open, 
  setOpen,
  title,
  setTitle,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.receive);
  const accounting = useSelector(state => state.accounting);
  const companyId = useSelectedCompany();
  const { handleEditAmount, handleShowAmount } = useCurrencyService();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadignMarking, setLoadingMarking] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingInvoiceNumber, setLoadingInvoiceNumber] = useState(false);
  const [invoiceNumberExists, setInvoiceNumberExists] = useState(false);
  const [mode, setMode] = useState(-1);

  const [customersList, setCustomersList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [salesTaxList, setSalesTaxList] = useState([]);

  const [invoiceState, setInvoiceState] = useState({ "associated_products": [] });

  const [addProduct, setAddProduct] = useState(false);
  
  const [invoiceModalMenuOpen, setInvoiceModalMenuOpen] = useState(false);
  const [invoiceModalMenuAnchor, setInvoiceModalMenuAnchor] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setLoading2(false);
    setFormData(Data);
    setSelectedCustomer(null);
    setAddProduct(false);
    setProductsList([]);
    setSalesTaxList([]);
    setInvoiceNumberExists(false);
    setInvoiceState({ "associated_products": [] });
    setSelectedFiles([]);
    setPreviews([]);
    setMode(-1);
  };


  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  useEffect(() => {
    if(open) {
      setSelectedFiles([]);
      setPreviews([]);
      dispatch(GetProducts(companyId, 1, 500, 1,  "&sell_this=true", setLoading2));
      dispatch(GetSalesTaxes(companyId, 1, 500, 1, "", setLoading2));
      if(update?.defaultMode === 1){
        // mode = 1 - create invoice
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - view invoice
        setMode(2);
        setFormData({
          ...formData,
          Amount: update?.total_amount || "",
          CustomerId: update?.ref_customer_id?.customer_id || "",
          InvoiceNumber: update?.invoice_number || "",
          InvoiceDate: dayjs(update?.invoice_date) || dayjs(),
          DueDate: dayjs(update?.due_date) || dayjs(),
          NoteToCustomer: update?.note_to_customer,
          POSONumber: update?.po_so_number || "",
        });
        dispatch(GetInvoiceDetails(companyId, update?.invoice_id, setLoading));
      }else if(update?.defaultMode === 3){
        // mode = 3 - edit invoice
        setMode(3);
        setFormData({
          ...formData,
          Amount: update?.total_amount || "",
          CustomerId: update?.ref_customer_id?.customer_id || "",
          InvoiceNumber: update?.invoice_number || "",
          InvoiceDate: dayjs(update?.invoice_date) || dayjs(),
          DueDate: dayjs(update?.due_date) || dayjs(),
          NoteToCustomer: update?.note_to_customer || "",
          POSONumber: update?.po_so_number || ""
        });
        dispatch(GetInvoiceDetails(companyId, update?.invoice_id, setLoading));
      }else if(update?.defaultMode === 4){
        // mode = 4 - duplicate invoice
        setMode(4);
        
      }else{
        // toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatCustomersList(state.customersList.records || []);     
      if ((mode === 2 || mode === 3) && open){
        // mode = 2 - view invoice
        // mode = 3 - edit invoice
        formatInvoiceView(state);
      }
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    try {
      formatProductsList(accounting.productsList.records || []);
      formatSalesTaxList(accounting.salesTaxesList.records || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounting]);

  const formatInvoiceView = (state) => {
    // formData
    let formDataObj = {
      Currency: state?.invoiceDetails?.currency,
      Amount: handleEditAmount(state?.invoiceDetails?.currency, state?.invoiceDetails?.total_amount || 0),
      CustomerId: state?.invoiceDetails?.ref_customer_id?.customer_id,
      InvoiceNumber: state?.invoiceDetails?.invoice_number,
      POSONumber: state?.invoiceDetails?.po_so_number,
      NoteToCustomer: state?.invoiceDetails?.note_to_customer,
      InvoiceDate: dayjs(state?.invoiceDetails?.invoice_date),
      DueDate: dayjs(state?.invoiceDetails?.due_date), 
    };
    setFormData(formDataObj);

    // selected customer
    let selectedCustomerObj = state?.invoiceDetails?.ref_customer_id;
    setSelectedCustomer(selectedCustomerObj);

    // invoice state
    let invoiceStateObj = state?.invoiceDetails;
    setInvoiceState(invoiceStateObj || []);
  };

  // ---------------- list formatters ----------------
  const formatCustomersList = (list) => {
    setCustomersList(list);
  };

  const formatProductsList = (list) => {
    setProductsList(list.map((product) => {
      return {
        ...product,
        total_amount: product.associated_selling_accounting_product[0]?.selling_price,
      }
    }));
  };

  const formatSalesTaxList = (list) => {
    setSalesTaxList(list);
  }

  // ---------------- handle change ------------------
  const handleChange = (e) => {
    if(e.target.name === "CustomerId"){
      let customer = customersList.find(i => i.customer_id === e.target.value)
      setSelectedCustomer(customer);
      setFormData({ ...formData, [e.target.name]: e.target.value, DueDate: dayjs().add(customer.default_payment_term || 30, 'day') });
    }else if(e.target.name === "InvoiceNumber") {
      handleCheckInvoiceNumberExists(e.target.value);
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }else{
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  };

  const handleChangeInvoiceState = (e) => {
    switch (e.target.name){
      // add new product
      case "AddProduct": {
          let newProductInfo = e.target.value;

          let newProductObj = {
            "ref_product_id": {
              "product_id": newProductInfo.product_id,
              "name": newProductInfo.name,
              "associated_taxes_accounting_product": newProductInfo.associated_taxes_accounting_product,
              "associated_selling_accounting_product": newProductInfo.associated_selling_accounting_product,
            },
            "quantity": 1,
            "unit_price": newProductInfo.total_amount || 0,
            "base_price": newProductInfo.total_amount || 0,
            "product_description": newProductInfo.description || "",
            "account_name": newProductInfo.income_account.account_name || "",
            "account_number": newProductInfo.income_account.account_number || "",
            "associated_product_taxes": newProductInfo.associated_taxes_accounting_product.map((tax) => {
              return {
                "name": tax.ref_sales_tax_id.name,
                "abbreviation": tax.ref_sales_tax_id.abbreviation,
                "is_tax_num_shown_on_invoice": tax.ref_sales_tax_id.is_tax_num_shown_on_invoice,
                "is_compound_tax": tax.ref_sales_tax_id.is_compound_tax,
                "tax_rate": tax.ref_sales_tax_id.associated_sale_taxes[0].tax_rate_percent,
                "amount": handleCalculateTaxForProduct(tax.ref_sales_tax_id.associated_sale_taxes[0].tax_rate_percent, 
                  newProductInfo.total_amount || 0),
                "ref_sales_tax_id": tax.ref_sales_tax_id.sales_tax_id
              }
            })
          };

          let invoiceStateTemp = {
            ...invoiceState,
            "associated_products": [ ...invoiceState.associated_products, newProductObj ]
          };

          invoiceStateTemp = handleCalculateTotalInInvoice(invoiceStateTemp);

          setInvoiceState(invoiceStateTemp);
        } 
      break;

      // remove product
      case "RemoveProduct": {
        let removedProductIndex = e.target.value;

        let invoiceStateTemp = {
          ...invoiceState,
          "associated_products": invoiceState.associated_products.filter((item, index) => index !== removedProductIndex)
        };

        invoiceStateTemp = handleCalculateTotalInInvoice(invoiceStateTemp);

        setInvoiceState(invoiceStateTemp);

      } break;

      // edit product desc
      case "EditProductDescription": {
        let productIndex = e.target.value[0];

        let associatedProductsTemp = invoiceState.associated_products;
        associatedProductsTemp[productIndex].product_description = e.target.value[1];

        let invoiceStateTemp = {
          ...invoiceState,
          "associated_products": associatedProductsTemp
        };

        setInvoiceState(invoiceStateTemp);
      };
      break;

      // edit quantity
      case "EditQuantity": {
        let productIndex = e.target.value[0];

        let associatedProductsTemp = invoiceState.associated_products;
        associatedProductsTemp[productIndex].quantity = e.target.value[1];
        let newUnitPrice = e.target.value[1] * associatedProductsTemp[productIndex]?.ref_product_id?.associated_selling_accounting_product[0]?.selling_price;
        associatedProductsTemp[productIndex].unit_price = newUnitPrice;
        associatedProductsTemp[productIndex].associated_product_taxes = associatedProductsTemp[productIndex].associated_product_taxes.map((tax) => {
          return {
            ...tax,
            "amount": handleCalculateTaxForProduct(tax.tax_rate, newUnitPrice || 0),
          }
        })

        let invoiceStateTemp = {
          ...invoiceState,
          "associated_products": associatedProductsTemp
        };

        invoiceStateTemp = handleCalculateTotalInInvoice(invoiceStateTemp);

        setInvoiceState(invoiceStateTemp);
      };  
      break;

      // add tax
      case "AddTax": {
        let productIndex = e.target.value[0];

        if(invoiceState.associated_products[productIndex].associated_product_taxes.find(i => 
          i.ref_sales_tax_id === e.target.value[1].sales_tax_id))
          return;

        let newTaxObj = {
          "name": e.target.value[1].name,
          "abbreviation": e.target.value[1].abbreviation,
          "is_tax_num_shown_on_invoice": e.target.value[1].is_tax_num_shown_on_invoice,
          "is_compound_tax": e.target.value[1].is_compound_tax,
          "tax_rate": e.target.value[1].associated_sale_taxes[0].tax_rate_percent,
          "amount": handleCalculateTaxForProduct(e.target.value[1].associated_sale_taxes[0].tax_rate_percent,
            invoiceState.associated_products[productIndex].unit_price || 0),
          "ref_sales_tax_id": e.target.value[1].sales_tax_id
        };

        let associatedProductsTemp = invoiceState.associated_products;
        associatedProductsTemp[productIndex].associated_product_taxes = [ ...associatedProductsTemp[productIndex].associated_product_taxes, newTaxObj ];

        let invoiceStateTemp = {
          ...invoiceState,
          "associated_products": associatedProductsTemp
        };

        invoiceStateTemp = handleCalculateTotalInInvoice(invoiceStateTemp);

        setInvoiceState(invoiceStateTemp);
      };
      break;

      // // remove tax
      case "RemoveTax": {
        let productIndex = e.target.value[0];
        let taxIndex = e.target.value[1];

        let associatedProductsTemp = invoiceState.associated_products;
        associatedProductsTemp[productIndex].associated_product_taxes = associatedProductsTemp[productIndex].associated_product_taxes.filter((item, index) => index !== taxIndex);

        let invoiceStateTemp = {
          ...invoiceState,
          "associated_products": associatedProductsTemp
        };

        invoiceStateTemp = handleCalculateTotalInInvoice(invoiceStateTemp);

        setInvoiceState(invoiceStateTemp);

      };
      break;

      default: ;
    }
  };  

  // ---------------- handle submit ------------------
  const handleSubmit = async (e, chained=false, loading) => {
    let invoiceId = "";
    try {
      if(mode === 1){
        // mode = 1 -> create invoice
        let obj = {
          "total_amount": parseInt(invoiceState.total_amount || 0),
          "balance_amount": parseInt(invoiceState.total_amount || 0),
          "ref_customer_id": formData.CustomerId || "",
          "invoice_number": formData.InvoiceNumber.length > 0 ? formData.InvoiceNumber : null,
          "po_so_number": formData.POSONumber || "",
          "invoice_date": dayjs(formData.InvoiceDate).format('YYYY-MM-DD'),
          "due_date": dayjs(formData.DueDate).format('YYYY-MM-DD'),
          "note_to_customer": formData.NoteToCustomer,
          "attachment_id_list": selectedFiles.length > 0 ? await handleUploadImages(setLoadingSave) : [],
          "invoice_lines": invoiceState.associated_products.map((product, productIndex) => {
            return {
              "ref_product_id": product.ref_product_id.product_id,
              "quantity": parseInt(product.quantity),
              "product_description": product.product_description,
              "unit_price": product.unit_price,
              "base_price": product.base_price,
              "account_name": product.account_name, 
              "account_number": product.account_number,
              "taxes_lines": product.associated_product_taxes.map((tax) => {
                return {
                  "tax_rate": tax.tax_rate,
                  "amount": parseInt(tax.amount),
                  "ref_sales_tax_id": tax.ref_sales_tax_id
                }
              })
            }
          })
        };
        await dispatch(CreateInvoice(companyId, obj, loading ? loading : setLoadingSave)).then(({res, statusCode}) => {
          if(statusCode === 201){
            if(chained === true) {
              invoiceId = res.data.invoice_id;
            } else {
              handleGet(setLoadingSave).then(() => {
                handleClose();
              });
            }
          }
        });
      } else if(mode === 2){
        // mode = 2 -> view invoice

      } else if(mode === 3){
        // mode = 3 -> edit invoice
        let obj = {
          "total_amount": parseInt(invoiceState.total_amount || 0),
          "balance_amount": parseInt(invoiceState.total_amount || 0),
          // "ref_customer_id": formData.CustomerId || "",
          "invoice_number": formData.InvoiceNumber.length > 0 ? formData.InvoiceNumber : null,
          "po_so_number": formData.POSONumber || "",
          "invoice_date": dayjs(formData.InvoiceDate).format('YYYY-MM-DD'),
          "due_date": dayjs(formData.DueDate).format('YYYY-MM-DD'),
          "note_to_customer": formData.NoteToCustomer,
          "invoice_lines": invoiceState.associated_products.map((product, productIndex) => {
            return {
              "ref_product_id": product.ref_product_id.product_id,
              "quantity": parseInt(product.quantity),
              "product_description": product.product_description,
              "unit_price": product.unit_price,        
              "base_price": product.base_price,  
              "account_name": product.account_name, 
              "account_number": product.account_number,
              "taxes_lines": product.associated_product_taxes.map((tax) => {
                return {
                  "tax_rate": tax.tax_rate,
                  "amount": parseInt(tax.amount),
                  "ref_sales_tax_id": tax.ref_sales_tax_id
                }
              })
            }
          })
        };
        await dispatch(UpdateInvoice(companyId, update?.invoice_id, obj, loading ? loading : setLoadingSave)).then(({res, statusCode}) => {
          if(statusCode === 202){
            if(chained === true) {
              dispatch(GetInvoiceDetails(companyId, update?.invoice_id, setLoadingSave))
              invoiceId = res.data.invoice_id;
            } else {
              handleGet(setLoadingSave).then(() => {
                handleClose();
              });
            }
          }
        });

      } else if(mode === 4){
        // mode = 4 -> view invoice
        let obj = {
          invoice_number: formData.InvoiceNumber
        }
        dispatch(CreateInvoieDuplicate(companyId, update?.invoice_id, obj, setLoadingDuplicate)).then(() => {
          handleGet(setLoadingDuplicate).then(() => {
            handleClose();
          });
        })
      } else {
        throw new Error();
      };
    } catch(err) {
      console.error(err)
      toast.error("Something went wrong!");
    }
    if(chained === true) return invoiceId;
  };

  // --------------- utility functions ---------------
  function disableWeekends(date) {
    let currentDay = dayjs(date).day();
    if(currentDay >= 1 && currentDay <= 5 ){
      return false;
    } else {
      return true;
    };
  };

  const handleCalculateTaxForProduct = (rate, amount) => {
    let tax_amount = 0;
    tax_amount = amount*(rate/100);
    return tax_amount;
  };

  const handleCalculateTotalInInvoice = (invoiceState) => {
    let total = 0;
    invoiceState.associated_products.forEach((product) => {
      let tax_total = 0;
      product.associated_product_taxes.forEach((tax) => tax_total += tax.amount);
      total += (product.unit_price + tax_total)
    });

    return { 
      ...invoiceState,
      total_amount: total
    };
  };

  const handleCheckInvoiceNumberExists = (number) => {
    dispatch(GetInvoiceNumberExists(companyId, number, setLoadingInvoiceNumber)).then(({res, statusCode}) => {
      if(statusCode === 200){
        if(mode === 3){
          // mode = 3 - edit invoice
          if(update?.invoice_number === number){
            setInvoiceNumberExists(false);
          }else {
            setInvoiceNumberExists(res.data);
          }
        } else if (mode === 1){
          // mode = 1 - create invoice
          setInvoiceNumberExists(res.data);
        }
      }
    });
  };

  const handleDisableButtons = () => {
    return !(formData.CustomerId !== "" && formData.InvoiceNumber !== "" && 
        invoiceState.associated_products.length > 0 &&
        !invoiceNumberExists && !loadingSave && !loadingPDF && !loadignMarking &&
        !loadingSend && !loadingDuplicate && !loadingDelete && !loadingInvoiceNumber
      )
  };

  // --------------- loader --------------------------
  const InvoiceLoading = () => {
    return (
      <Box>
        <Skeleton 
          variant="rectangular"
          sx={{ borderRadius: "16px",
            width: "100%",
            height: "233px", 
            marginBottom: "50px"
          }}
          />
        <Skeleton 
          variant="rectangular"
          sx={{ borderRadius: "16px",
            width: "100%",
            height: "80px", 
            marginBottom: "20px"
          }}
          />
        <Skeleton 
          variant="rectangular"
          sx={{ borderRadius: "16px",
            width: "100%",
            height: "80px", 
            marginBottom: "20px"
          }}
          />
      </Box>
    )
  };

  // --------------- handle other actions ------------
  const handleGeneratePDF = (id) => {
    dispatch(GetInvoicePDF(companyId, id ? id : update?.invoice_id, setLoadingPDF)).then(async ({res, statusCode}) => {
      if(statusCode === 200){
        const pdfUrl = res.data.url;
        // const response = await fetch(pdfUrl);
        // const blob = await response.blob();
        const link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        link.href = pdfUrl;
        link.target = "_blank"
        // link.download = 'file.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
  };

  const handleMarkAsSent = () => {
    if(mode === 1){
      // do nothing
    } else if (mode === 2){
      dispatch(MarkAsSentInvoice(companyId, update?.invoice_id, setLoadingMarking)).then(() => {
        handleGet(setLoadingMarking).then(() => {
          handleClose();
        })
      });
    } else if (mode === 3){
      // do nothing
    }
  };

  const handleSend = async () => {
    if(mode === 1){
      let invoiceId = await handleSubmit(null, true, setLoadingSend);
      dispatch(SendInvoice(companyId, invoiceId, setLoadingSend)).then(() => {
        handleGet(setLoadingMarking).then(() => {
          handleClose();
        })
      });
    } else if (mode === 2){
      dispatch(SendInvoice(companyId, update?.invoice_id, setLoadingSend)).then(() => {
        handleGet(setLoadingMarking).then(() => {
          handleClose();
        })
      });
    } else if (mode === 3){
      await handleSubmit(null, true, setLoadingSend);
      dispatch(SendInvoice(companyId, update?.invoice_id, setLoadingSend)).then(() => {
        handleGet(setLoadingMarking).then(() => {
          handleClose();
        })
      });
    }
  };

  const handleDelete = () => {
    dispatch(DeleteInvoice(companyId, update?.invoice_id, setLoadingDelete)).then(() => {
      handleGet(setLoadingDelete).then(() => {
        handleClose();
      })
    })
  };

  const handleUploadImages = async (customLoading) => {
    let path = [];

    for(let i = 0 ; i < selectedFiles.length ; i++){
      const image = new FormData();
      image.append("new_file", selectedFiles[i]);
      await dispatch(UploadFiles(image, customLoading ? customLoading : setLoading)).then(({res, statusCode}) => {
        if(statusCode === 201) path.push(res.data.file_path)
      });
    };

    return path;
  };

  useEffect(() => {
    let previewTemp = [];

    for(let i = 0 ; i < selectedFiles.length ; i++){
      const objectUrl = URL.createObjectURL(selectedFiles[i])
      previewTemp.push(objectUrl);
    };
    setPreviews(previewTemp);
  }, [selectedFiles]);

  const onSelectFile = e => {
    const max_file_select = 1

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFiles([]);
      return;
    }  

    let temp = [];

    let index = 0;
    for(let i = 0 ; i < e.target.files.length ; i++){
      if(index > max_file_select) break;
      index++;
      temp.push(e.target.files[i]);
    };

    temp = [ ...selectedFiles, ...temp ];

    setSelectedFiles(temp.slice(0, max_file_select));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          className={`fullpage__modal animate__animated animate__fadeIn`}>
          
          <ModalTopBar
            onBack={handleClose}
            title={title} 
            />

          <Container
            maxWidth={mode === 1 ? "xl" : "md"}>
            <Box
              className="flexCenter_Row"
              gap={"20px"}>
              {/* invoice attachment */}
              {
                mode === 1
                &&
                <Box
                  className="flexCenterCenterColumn"
                  sx={{
                    minWidth: "500px",
                    height: "calc(100vh - 120px)",
                    borderRadius: "12px",
                    border: "dashed 1px lightgrey"
                  }}>
                  {
                    selectedFiles.length < 4 &&  
                      <Box
                        className="flexCenter_Row"
                        gap={"20px"}>
                        {
                          previews.map((image, imageIndex) => (
                            <Box
                              key={imageIndex}
                              sx={{ position: "relative" }}>
                              <Box
                                color='white'
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "10px",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  top: "-15px",
                                  right: "-15px",
                                  backgroundColor: "lightgrey",
                                  cursor: "pointer",
                                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                }}
                                onClick={() => {
                                  let temp = selectedFiles.filter((item, index) => index !== imageIndex);
                                  setSelectedFiles(temp);
                                }}>
                                <CloseRoundedIcon 
                                  fontSize='small'
                                  sx={{ color: "black" }}/>
                              </Box>
                              {
                                selectedFiles[imageIndex]?.type === "application/pdf"
                                ?
                                <iframe
                                  src={image}
                                  title="PDF Viewer"
                                  width="470px"
                                  height="700px"
                                ></iframe>
                                :
                                <img 
                                  key={imageIndex}
                                  src={image}
                                  alt='expense-receipt'
                                  className='expense__receipt'
                                  style={{ width: "300px" }}
                                  /> 
                              }
                            </Box>
                          ))
                        }
                      </Box>
                  }
                  {
                    selectedFiles.length === 0
                    &&
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      disabled={selectedFiles.length > 2}
                      tabIndex={-1}>
                      {
                        selectedFiles.length === 0
                        ?
                          "Upload invoice"
                        :
                          "Add more invoice"
                      }
                      <VisuallyHiddenInput 
                        type="file" 
                        name="myImage"
                        multiple
                        accept=".png, .jpeg, .jpg, .pdf" 
                        onChange={onSelectFile} />
                    </Button>
                  }
                </Box>
              }
              {/* invoice */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "calc(100vh - 120px)",
                  overflow: "scroll"
                }}>
                {
                  mode === 4
                  ?
                  <Box 
                    className="flexCenter_Column"
                    gap={"20px"}
                    mb={"20px"}
                    pt={"20px"}>
                    <Box
                      width={"350px"}
                      className="flex__Column"
                      gap={"20px"}>

                      <Typography
                        textAlign={"center"}>
                        Please enter invoice number of new invoice
                      </Typography>

                      <TextField 
                        size='small'
                        label="Invoice number"
                        fullWidth
                        name="InvoiceNumber"
                        value={formData.InvoiceNumber}
                        onChange={handleChange}
                        InputProps={{ 
                          readOnly: mode === 2,
                          endAdornment: (
                            <InputAdornment position="end">
                              {loadingInvoiceNumber ? <CircularProgress size={20} /> : null}
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          maxLength: 20
                        }}
                        error={invoiceNumberExists}
                        helperText={invoiceNumberExists && "Invoice number already exists"}
                          />

                      <LoadingButton
                        variant="contained"
                        loading={loadingDuplicate}
                        disabled={!(formData.InvoiceNumber !== "" && !invoiceNumberExists && !loadingInvoiceNumber)}
                        onClick={handleSubmit}>
                        Submit
                      </LoadingButton>
                    </Box>
                  </Box>
                  :
                    loading && mode !== 1
                    ?
                    <InvoiceLoading />
                    :
                    <Box 
                      className="flex__Column"
                      gap={"20px"}
                      mb={"20px"}
                      pt={"20px"}>

                      <Box
                        className="flexFSSBRow"
                        gap={"20px"}>
                        <Box
                          width={"350px"}>
                          <TextField 
                            select
                            size='small'
                            label="Customer"
                            fullWidth
                            name="CustomerId"
                            value={formData.CustomerId}
                            onChange={handleChange}
                            InputProps={{ readOnly: mode === 2 }}
                            >
                            {
                              customersList.length === 0
                              ?
                                <MenuItem disabled>
                                  No customer found
                                </MenuItem>
                              :
                              customersList.map((item, index) => (
                                <MenuItem key={index} value={item.customer_id}>
                                  <Box className="flexCenter_Row" gap={"10px"}>
                                    <Typography variant='body1'>
                                      {item.name}
                                    </Typography>
                                  </Box>
                                </MenuItem>
                              ))
                            }
                          </TextField>   
                          {
                            selectedCustomer
                            &&
                            <Box
                              sx={{ margin: "8px" }}>
                              <Typography>{selectedCustomer.contact_name}</Typography>
                              <Typography>{selectedCustomer.email}</Typography>
                            </Box>
                          } 
                        </Box>

                        <Box
                          width={"350px"}
                          className="flex__Column"
                          gap={"20px"}>
                          <TextField 
                            size='small'
                            label="Invoice number"
                            fullWidth
                            name="InvoiceNumber"
                            value={formData.InvoiceNumber}
                            onChange={handleChange}
                            InputProps={{ 
                              readOnly: mode === 2,
                              endAdornment: (
                                <InputAdornment position="end">
                                  {loadingInvoiceNumber ? <CircularProgress size={20} /> : null}
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              maxLength: 20
                            }}
                            error={invoiceNumberExists}
                            helperText={invoiceNumberExists && "Invoice number already exists"}
                            />
                          <TextField 
                            size='small'
                            label="P.O./S.O. number (optional)"
                            fullWidth
                            name="POSONumber"
                            value={formData.POSONumber}
                            onChange={handleChange}
                            InputProps={{ readOnly: mode === 2 }}
                            />
                          <TextField 
                            size='small'
                            label="Note to customer"
                            fullWidth
                            name="NoteToCustomer"
                            value={formData?.NoteToCustomer}
                            onChange={handleChange}
                            InputProps={{ 
                              readOnly: mode === 2, 
                              maxLength: 200
                            }}
                            />
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              slotProps={{ 
                                textField: { 
                                  fullWidth: true,
                                  size: "small",
                                  readOnly: mode === 2
                                } 
                              }}
                              shouldDisableDate={disableWeekends}
                              label="Invoice date" 
                              name="InvoiceDate"
                              disableOpenPicker={mode === 2}
                              value={formData.InvoiceDate || dayjs()}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "InvoiceDate",
                                    value: e
                                  }
                                });
                              }}/>
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              slotProps={{ 
                                textField: { 
                                  fullWidth: true,
                                  size: "small",
                                  helperText: formData.DueDate > formData.InvoiceDate 
                                    ? `Within ${formData.DueDate.diff(formData.InvoiceDate, 'days')} days`
                                    : "",
                                  readOnly: mode === 2
                                } 
                              }}
                              shouldDisableDate={disableWeekends}
                              label="Due date" 
                              name="DueDate"
                              disableOpenPicker={mode === 2}
                              value={formData.DueDate || dayjs()}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "DueDate",
                                    value: e
                                  }
                                });
                              }}/>
                          </LocalizationProvider>
                        </Box>
                      </Box>
                      <Box>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width={"50%"}>Product name</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell align='right'>Rate</TableCell>
                              <TableCell align='right'>Amount</TableCell>
                              <TableCell align='right' width={"40px"}></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {
                            invoiceState?.associated_products?.map((product, productIndex) => (
                              <>
                              <TableRow
                                sx={{ "& td": { border: 0 } }}
                                key={productIndex}>
                                <TableCell>
                                  <Typography
                                    fontWeight={500}>
                                    {product?.ref_product_id?.name || ""}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <TextField 
                                    type='number'
                                    value={product.quantity}
                                    onChange={(e) => {
                                      handleChangeInvoiceState({ target: { 
                                        name: "EditQuantity", 
                                        value: [productIndex, e.target.value] 
                                      }});
                                    }}
                                    size='small'
                                    sx={{ width: "70px" }}
                                    error={product.quantity === 0 ? true : false}
                                    InputProps={{ readOnly: mode === 2 }}
                                    />
                                </TableCell>
                                <TableCell align='right'>
                                  {
                                    product?.ref_product_id?.associated_selling_accounting_product?.length > 0
                                    &&
                                    <Typography>
                                      {handleShowAmount("USD", product.base_price)}
                                    </Typography>
                                  }
                                </TableCell>
                                <TableCell
                                  align='right'>
                                  {
                                    product?.ref_product_id?.associated_selling_accounting_product?.length > 0
                                    &&
                                    <Typography fontWeight={500}>
                                      {handleShowAmount("USD", product.unit_price)}
                                    </Typography>
                                  }
                                </TableCell>
                                {
                                  mode !== 2
                                  &&
                                  <TableCell>
                                    <IconButton
                                      onClick={() => handleChangeInvoiceState({ target: { name: "RemoveProduct", value: productIndex }} )}>
                                      <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                  </TableCell>
                                }
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ 
                                    verticalAlign: 'top',
                                    paddingTop: "0px"
                                  }}>
                                  <Box
                                    gap={"10px"}
                                    className="flexFE_Column">
                                    <TextField 
                                      fullWidth
                                      multiline
                                      sx={{ minWidth: "200px" }}
                                      minRows={2}
                                      size="small"
                                      value={product.product_description}
                                      onChange={(e) => {
                                        handleChangeInvoiceState({ target: { 
                                          name: "EditProductDescription", 
                                          value: [productIndex, e.target.value] 
                                        }});
                                      }}  
                                      InputProps={{ readOnly: mode === 2 }}
                                      />
                                    {
                                      product.account_name
                                      &&
                                      <Box
                                        className="flexCenter_Row"
                                        gap={"5px"}
                                        mt={"5px"}>
                                        <Typography
                                          color={"grey"}
                                          variant='caption'>
                                          Income account
                                        </Typography>
                                        <Typography
                                          variant='caption'>
                                          {product.account_name}
                                        </Typography>
                                      </Box>
                                    }
                                  </Box>
                                </TableCell>
                                <TableCell 
                                  colSpan={4}
                                  sx={{ 
                                    verticalAlign: 'top',
                                  }}>
                                  <Box
                                    className="flexFE_Column"
                                    gap={"5px"}>
                                    {
                                      product.associated_product_taxes.map((tax, taxIndex) => (
                                        <Box
                                          className="flexCenter_Row"
                                          gap={"10px"}
                                          sx={{ marginRight: "8px" }}
                                          key={taxIndex}>
                                          Tax
                                          <TextField 
                                            select
                                            size='small'
                                            sx={{ width: "140px" }}
                                            value={tax?.ref_sales_tax_id}
                                            onChange={(e) => {
                                              
                                            }}
                                            InputProps={{
                                              readOnly: mode === 2
                                            }}
                                            >
                                            {
                                              salesTaxList.length === 0
                                              ?
                                                <MenuItem disabled>
                                                  No sales tax found
                                                </MenuItem>
                                              :
                                              salesTaxList.map((item, index) => (
                                                <MenuItem key={index} value={item?.sales_tax_id}>
                                                  <Box className="flexCenter_Row" gap={"10px"}>
                                                    <Typography variant='body1'>
                                                      {item.abbreviation}&nbsp;({item.associated_sale_taxes[0]?.tax_rate_percent})%
                                                    </Typography>
                                                  </Box>
                                                </MenuItem>
                                              ))
                                            }
                                          </TextField>  
                                          <Typography
                                            textAlign={"right"}
                                            width={"95px"}
                                            variant='body2'>
                                            {handleShowAmount("USD", tax?.amount || 0 )}
                                          </Typography>
                                          {
                                            mode === 2
                                            ?
                                            <Box 
                                              sx={{ width: "40px" }}
                                              />
                                            : 
                                            <IconButton
                                              p={1}
                                              onClick={() => handleChangeInvoiceState({ target: {
                                                name: "RemoveTax",
                                                value: [productIndex, taxIndex]
                                              }})}>
                                              <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                          }
                                        </Box>
                                      ))
                                    }
                                    <Box
                                      className="flexCenter_Row"
                                      gap={"10px"}
                                      sx={{ marginRight: "57px" }}>
                                      Add new tax
                                      <TextField 
                                        select
                                        size='small'
                                        sx={{ width: "140px" }}
                                        placeholder={'Add new tax'}
                                        value={null}
                                        onChange={(e) => {
                                          handleChangeInvoiceState({ target: {
                                            name: "AddTax",
                                            value: [productIndex, e.target.value]
                                          }});
                                        }}
                                        InputProps={{
                                          readOnly: mode === 2
                                        }}
                                        >
                                        {
                                          salesTaxList.length === 0
                                          ?
                                            <MenuItem disabled>
                                              No sales tax found
                                            </MenuItem>
                                          :
                                          salesTaxList.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                              <Box className="flexCenter_Row" gap={"10px"}>
                                                <Typography variant='body1'>
                                                  {item.abbreviation}&nbsp;({item.associated_sale_taxes[0]?.tax_rate_percent})%
                                                </Typography>
                                              </Box>
                                            </MenuItem>
                                          ))
                                        }
                                      </TextField>  
                                      <Typography
                                        textAlign={"right"}
                                        width={"95px"}
                                        variant='body2'>
                                      </Typography>
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableRow>
                              </>
                            ))
                          }
                          </TableBody>
                        </Table>
                        <Box
                          className="flexCenter_Row"
                          sx={{ padding: "16px 16px 0px 16px" }}>
                          {
                            addProduct
                            ?
                            <TextField 
                              select
                              label="Product"
                              sx={{ width: "300px" }}
                              name="AddProduct"
                              value={formData.ProductId}
                              size='small'
                              onChange={(e) => {
                                handleChangeInvoiceState(e);
                                setAddProduct(false);
                              }}
                              >
                              {
                                productsList.length === 0
                                ?
                                  <MenuItem disabled>
                                    No product found
                                  </MenuItem>
                                :
                                productsList.map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    <Box className="flexCenter_Row" gap={"10px"}>
                                      <Typography variant='body1'>
                                        {item.name}
                                      </Typography>
                                    </Box>
                                  </MenuItem>
                                ))
                              }
                            </TextField>   
                            :
                            <Link
                              variant='outlined'
                              onClick={() => {
                                setFormData({ ...formData, ProductId: "" })
                                setAddProduct(true);
                              }}>
                              + Add product
                            </Link>
                          }
                        </Box>
                      </Box>
                      <Divider />
                      <Box
                        className="flex_FEColumn"
                        gap={"15px"}
                        sx={{ marginRight: "72px" }}>
                        <Box
                          className="flexCenterFERow"
                          gap={"20px"}>
                          <Typography fontWeight={600}>Total</Typography>
                          <Typography fontWeight={600} width={"200px"}
                            textAlign={"right"}>
                            {handleShowAmount("USD", invoiceState?.total_amount || 0)}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        className="flexCenterFERow"
                        gap={"20px"}
                        marginTop={"20px"}>
                        {
                        mode === 1
                        ?
                        <>
                          <LoadingButton
                            variant="text"
                            loading={loadingPDF}
                            disabled={handleDisableButtons()}
                            onClick={async () => {
                              let invoiceId = await handleSubmit(null, true, setLoadingPDF);
                              handleGeneratePDF(invoiceId);
                              handleGet();
                            }}>
                            Export as PDF
                          </LoadingButton>
                          <LoadingButton
                            variant="text"
                            loading={loadingSave}
                            disabled={handleDisableButtons()}
                            onClick={handleSubmit}>
                            Save
                          </LoadingButton>
                          <LoadingButton
                            variant="contained"
                            loading={loadingSend}
                            disabled={handleDisableButtons()}
                            onClick={handleSend}>
                            Send
                          </LoadingButton>
                        </>
                        : 
                          mode === 2
                          ?
                          <>
                            <LoadingButton
                              variant="text"
                              loading={loadingPDF}
                              disabled={handleDisableButtons()}
                              onClick={() => {
                                handleGeneratePDF();
                              }}>
                              Export as PDF
                            </LoadingButton>
                            {
                              update?.status === "Created"
                              &&
                              <LoadingButton
                                variant="contained"
                                loading={loadignMarking}
                                disabled={handleDisableButtons()}
                                onClick={handleMarkAsSent}>
                                Mark as sent
                              </LoadingButton>
                            }
                            {
                              update?.status === "Created"
                              &&
                              <LoadingButton
                                variant="contained"
                                loading={loadingSend}
                                disabled={handleDisableButtons()}
                                onClick={handleSend}>
                                Send
                              </LoadingButton>
                            }
                          </>
                          :
                            mode === 3
                            ?
                            <>
                              <LoadingButton
                                variant="text"
                                loading={loadingPDF}
                                disabled={handleDisableButtons()}
                                onClick={async () => {
                                  let invoiceId = await handleSubmit(null, true, setLoadingPDF);
                                  handleGeneratePDF(invoiceId);
                                  handleGet();
                                }}>
                                Export as PDFs
                              </LoadingButton>
                              <LoadingButton
                                variant="text"
                                loading={loadingSave}
                                disabled={handleDisableButtons()}
                                onClick={handleSubmit}>
                                Save
                              </LoadingButton>
                              <LoadingButton
                                variant="contained"
                                loading={loadingSend}
                                disabled={handleDisableButtons()}
                                onClick={handleSend}>
                                Send
                              </LoadingButton>
                            </>
                            :
                            <></>
                        }
                        {
                          loadingDelete
                          ?
                          <CircularProgress size={26} sx={{ margin: "7px" }} />
                          :
                            mode !== 1
                            &&
                            <IconButton
                              onClick={(e) => {
                                setInvoiceModalMenuAnchor(e.currentTarget);
                                setInvoiceModalMenuOpen(true);
                              }}>
                              <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                            </IconButton>
                        }
                      </Box>
                    </Box>
                }
              </Box>
            </Box>
          </Container>
        </Box>
        <Menu
          open={invoiceModalMenuOpen}
          anchorEl={invoiceModalMenuAnchor}
          onClose={() => setInvoiceModalMenuOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {
            mode === 2 
            ?
            <>
            <MenuItem
              onClick={() => {
                setInvoiceModalMenuOpen(false);
                setMode(3);
                setTitle(title.replace("View", "Edit"));
              }}>
              <Box
                className="flexCenter_Row"
                gap={"10px"}>
                <EditOutlinedIcon />
                Edit
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setInvoiceModalMenuOpen(false);
                setMode(4);
                setFormData(Data);
                setInvoiceState({ "associated_products": [] });
              }}>
              <Box
                className="flexCenter_Row"
                gap={"10px"}>
                <ContentCopyIcon />
                Duplicate
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setInvoiceModalMenuOpen(false);
                handleDelete();
              }}>
              <Box
                className="flexCenter_Row"
                gap={"10px"}>
                <DeleteOutlineOutlinedIcon />
                Delete
              </Box>
            </MenuItem>
            </>
            :
              mode === 3
              ?
              <>
              <MenuItem
                onClick={() => {
                  setInvoiceModalMenuOpen(false);
                  setMode(4);
                  setFormData(Data);
                  setInvoiceState({ "associated_products": [] });
                }}>
                <Box
                  className="flexCenter_Row"
                  gap={"10px"}>
                  <ContentCopyIcon />
                  Duplicate
                </Box>
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  setInvoiceModalMenuOpen(false);
                }}>
                <Box
                  className="flexCenter_Row"
                  gap={"10px"}>
                  <FileDownloadIcon />
                  Export as PDFs
                </Box>
              </MenuItem> */}
              </>
              :
              <></>
          }
        </Menu>
      </Box>
    </Modal>
  )
}

export default InvoicesModal;