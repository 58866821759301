import React from 'react';

import { 
  InputAdornment,
  TextField 
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

const SearchTextField = (props) => {
  return (
    <TextField
      placeholder='Search' 
      size='small'
      value={props.value}
      onChange={props.onChange}
      InputProps={{
        startAdornment: 
          <InputAdornment position="start">
            <SearchIcon 
              sx={{ width: "18px" }}/>
          </InputAdornment>,
      }}
    />
  )
}

export default SearchTextField