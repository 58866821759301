import React from "react";

import { 
  Box 
} from "@mui/material";

import Logo from "../../assets/icons/logo-blue-dark2.svg";

const SideBarLogo = ({isNavExpanded}) => {
  return(
    <Box height={"60px"}
      sx={{
        position: "absolute", 
        zIndex: 100, top: 0, left: 0, 
        padding: "10px" }}>
      {/* {
        isNavExpanded
        ? */}
        <img
          src={Logo}
          alt="logo"
          style={{ width: "150px", marginTop: "5px" }}
          /> 
        {/* :
        <Box 
          className={"mini-logo flexCenterCenterRow"}>
          <img
            src={MiniLogo}
            alt="mini-logo"
            style={{ width: "56px", borderRadius: "12px", padding: "5px" }}
            /> 
        </Box>
      } */}
    </Box>
  )
}

export default React.memo(SideBarLogo);