import React from 'react';

import { 
  Box,
  Menu, 
  MenuItem,
  Typography,
} from '@mui/material';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

import global from "../../global.scss";

const ProfileMenu = ({
  open,
  anchor,
  profile,
  onClose,
  navigate,
  onLogout
}) => {
  return (
    <Menu
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {
        profile
        &&
        <Box
          sx={{ margin: "-10px 0px 10px 0px", 
            padding: "15px",
            borderRadius: "12px 12px 0px 0px",
            backgroundColor: global['primary_light'] }}>
          <Box>
            <Typography
              variant='subtitle1'>
              {profile.first_name || ""}&nbsp;{profile.last_name || ""}
            </Typography>
          </Box>
          <Typography
            variant='body1'>
            {profile.user_name || ""}&nbsp;
          </Typography>
        </Box>
      }

      <MenuItem
        className='flexCenter_Row'
        sx={{ gap: "15px" }}
        onClick={() => {
          navigate("/profile");
          onClose();
        }}>
        <AccountCircleOutlinedIcon />  
        <Typography sx={{ marginRight: "20px" }}>Profile</Typography>
      </MenuItem>
      <MenuItem
        className='flexCenter_Row'
        sx={{ gap: "15px" }}
        onClick={() => {
          onLogout();
          onClose();
        }}>
        <LogoutIcon />  
        <Typography sx={{ marginRight: "20px" }}>Logout</Typography>
      </MenuItem>
    </Menu>
  )
}

export default ProfileMenu;