import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Modal, 
  Skeleton, 
  TextField, 
  Typography
} from '@mui/material';

import useCurrencyService from '../../../../components/hooks/useCurrencyService';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';
import NameAvatar from '../../../../components/avatars/Avatar';
import NA from '../../../../components/utils/NA';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import { 
  GetVendorDetails, 
  UpdateVendor
} from '../../../../redux/actions/pay/vendorsActions';
import {
  GetVendorAccount,
  GetVendorAccounts, 
} from '../../../../redux/actions/pay/vendorBankAccountsActions';

import { 
  CLEAR_VENDOR_ACCOUNTS_PAY, 
  CLEAR_VENDOR_DETAILS_PAY 
} from '../../../../redux/actionTypes';

import "../../../../global.scss";
import "./styles.scss";

const Data = {

};

const VendorDetailsModal = ({
  open, 
  setOpen,
  title,
  update,
  onMethod,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingBankDetails, setLoadingBankDetails] = useState(false);
  const { handleShowAmount } = useCurrencyService();
  const [formData, setFormData] = useState(Data);

  const [vendorDetails, setVendorDetails] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState(null);

  const [mode, setMode] = useState(-1);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
    setFormData(Data);
    setVendorDetails(null);
    setMode(-1);
    setDeliveryMethod(null);
    dispatch({ type: CLEAR_VENDOR_ACCOUNTS_PAY });
    dispatch({ type: CLEAR_VENDOR_DETAILS_PAY });
  };

  useEffect(() => {
    try {
      if(open){
        console.log(update)
        setDeliveryMethod(null);
        dispatch(GetVendorDetails(companyId, update.vendor_id, setLoading));
        dispatch(GetVendorAccounts(companyId, update.vendor_id, 1, 500, setLoading));

        if(update.defaultMode === 2){
          // edit vendor
          setMode(2);
          setFormData({
            ...formData,
            name: update.name || "",
            contact_name: update.contact_name || "",
            email: update.email || "",
            phone: update.phone || ""
          });
        }else{
          // view vendor details
          setMode(1);
        }
      }
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      // console.log(state)
      if(open) {
        formatVendorDetailsObj(state.vendorDetails || null);
        formatDeliveryMethodObj(state.vendorAccounts.records[0] || null);
      }
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const formatVendorDetailsObj = (obj) => {
    setVendorDetails(obj);
  };

  const formatDeliveryMethodObj = (obj) => {
    setDeliveryMethod(obj);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  const handleSubmit = () => {
    try {
      if(mode === 2){
        // edit vendor
        let obj = {
          name: formData.name,
          contact_name: formData.contact_name,
          email: formData.email,
          phone: formData.phone
        };
  
        dispatch(UpdateVendor(companyId, update.vendor_id, obj, setLoading2)).then(({ data, status }) => {
          if(status === 202) {
            dispatch(GetVendorDetails(companyId, update.vendor_id, setLoading2));
            handleGet(setLoading2);
            setMode(1);
          }
        });
      }else{
        throw new Error();
      }
    } catch (err) {
      toast.error("Something went wrong!");
    }
  };

  const handleEditBankAccount = () => {
    
    dispatch(GetVendorAccount(companyId, update.vendor_id, deliveryMethod.id, setLoadingBankDetails)).then(({res, statusCode}) => {
      if(statusCode === 200){
        onMethod({
          vendorId: update.vendor_id,
          defaultMode: 4,
          ach: res.data.ach_routing,
          account: res.data.account_number,
          vendorBankAccountId: res.data.id
        });
      }
    })

  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          className={`fullpage__modal animate__animated animate__fadeIn`}>
          
          <ModalTopBar
            onBack={handleClose}
            title={
              <Box className="flexCenter_Row" gap={"10px"}>
                <NameAvatar
                  color={update?.color} 
                  size="large">
                  {title}
                </NameAvatar>
                <Typography variant='h6'>{title}</Typography>
              </Box>
            } 
            />

          <Container
            maxWidth="sm">

            <Box
              mb={"60px"}>
              <Typography
                variant='h4'
                mb={"20px"}>
                Payments overview
              </Typography>
              {
                loading
                ?
                <Skeleton 
                  variant="rectangular"
                  sx={{ height: "75px", borderRadius: "16px"}}
                  />
                :
                  vendorDetails &&
                  <Box
                    className="flexCenterSBRow">
                    <Box>
                      <Typography
                        variant='caption'
                        color={"grey"}
                        fontWeight={600}>
                        Open balance
                      </Typography>
                      <Typography>
                        {handleShowAmount(vendorDetails?.open_balance?.total_bill_currency, 
                          vendorDetails?.open_balance?.total_bill_amount)}
                      </Typography>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {vendorDetails?.open_balance?.total_bills || 0} bills
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant='caption'
                        color={"grey"}
                        fontWeight={600}>
                        Scheduled
                      </Typography>
                      <Typography>
                        {handleShowAmount(vendorDetails?.scheduled?.total_scheduled_currency, 
                          vendorDetails?.scheduled?.total_scheduled_amount)}
                      </Typography>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {vendorDetails?.scheduled?.total_scheduled || 0} payments
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant='caption'
                        color={"grey"}
                        fontWeight={600}>
                        Paid
                      </Typography>
                      <Typography>
                        {handleShowAmount(vendorDetails?.paid?.total_paid_currency, 
                          vendorDetails?.paid?.total_paid_amount)}
                      </Typography>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {vendorDetails?.paid?.total_paid || 0} payments
                      </Typography>
                    </Box>
                  </Box>
              }
            </Box>

            <Box
              mb={"60px"}>
              <Typography
                variant='h4'>
                Payment method
              </Typography>

              {
                loading
                ?
                  <>  
                  <Skeleton 
                    variant="rectangular"
                    sx={{ height: "120px", borderRadius: "16px", margin: "20px 0px" }}
                    />
                  <Skeleton 
                    variant="rectangular"
                    sx={{ height: "280px", borderRadius: "16px", margin: "20px 0px" }}
                    />
                  </>
                :
                <>
                  <Box
                    className="flexCenterSBRow vendorset__bank"
                    margin={"30px 0px 20px 0px"}>
                    <Box
                      className="flexCenter_Row" 
                      gap={"20px"}>
                      <AccountBalanceOutlinedIcon 
                        fontSize='large'/>
                      <Box>
                        <Typography 
                          variant='subtitle1'
                          fontWeight={600}>
                          Bank transfer (ACH)
                        </Typography>
                        {
                          deliveryMethod === null
                          ?
                          <NA 
                            label={"No vendor bank account added."}/>
                          :
                          <Box>
                            <Typography>
                              {deliveryMethod.account_number}
                              <Chip 
                                size='small'
                                sx={{ marginLeft: "10px" }}
                                label={deliveryMethod?.ref_bank_acc_type_id?.type || ""} 
                                />
                                
                            </Typography>
                            <Typography 
                              variant='caption' 
                              color={"grey"}>
                              added on {deliveryMethod.created_at && new Date(deliveryMethod.created_at).toLocaleDateString()}
                            </Typography>
                          </Box>
                        }

                      </Box>
                    </Box>
                    {
                      deliveryMethod === null
                      ?
                      <Button
                        marginLeft="auto"
                        variant='text'
                        onClick={() => {
                          onMethod({
                            vendorId: update.vendor_id,
                            defaultMode: 3,
                          });
                        }}>
                        Add Method
                      </Button>
                      :
                      <LoadingButton
                        marginLeft="auto"
                        variant='text'
                        loading={loadingBankDetails}
                        onClick={() => {
                          handleEditBankAccount()
                        }}>
                        Edit
                      </LoadingButton>
                    }
                  </Box>
                  {
                    deliveryMethod === null
                    &&
                    <>
                      <Divider sx={{ mb: "10px"}}/>
                      <Typography color={"grey"}>
                        Deposit funds into your vendor's bank account.
                      </Typography>
                    </>
                  }
                </>
              }
            </Box>

            {
              vendorDetails && !loading
              &&
              mode === 1
              ?
              <Box
                mb={"60px"}>
                <Typography
                  variant='h4'
                  mb={"20px"}>
                  Vendor details
                </Typography>
                <Box
                  mb={"20px"}>
                  <Typography
                    variant='caption'
                    color={"grey"}
                    fontWeight={600}>
                    Vendor's business name
                  </Typography>
                  <Typography
                    variant='subtitle1'>
                    {vendorDetails.name}
                  </Typography>
                </Box>
                <Box
                  mb={"20px"}>
                  <Typography
                    variant='caption'
                    color={"grey"}
                    fontWeight={600}>
                    Vendor's contact name
                  </Typography>
                  <Typography
                    variant='subtitle1'>
                    {vendorDetails.contact_name}
                  </Typography>
                </Box>
                <Box
                  mb={"20px"}>
                  <Typography
                    variant='caption'
                    color={"grey"}
                    fontWeight={600}>
                    Email address
                  </Typography>
                  <Typography
                    variant='subtitle1'>
                    {vendorDetails.email}
                  </Typography>
                </Box>
                <Box
                  mb={"20px"}>
                  <Typography
                    variant='caption'
                    color={"grey"}
                    fontWeight={600}>
                    Phone number
                    <Typography
                      variant='subtitle1'>
                      {vendorDetails.phone}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              :
                mode === 2 && !loading
                ?
                <Box
                  mb={"60px"}>
                  <Typography
                    variant='h4'
                    mb={"20px"}>
                    Vendor details
                  </Typography>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Vendor's business name
                    </Typography>
                    <TextField
                      fullWidth
                      autoFocus={true}
                      name='name'
                      value={formData.name}
                      onChange={handleChange}
                      />
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Vendor's contact name
                    </Typography>
                    <TextField 
                      fullWidth
                      name='contact_name'
                      value={formData.contact_name}
                      onChange={handleChange}
                      />
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Email address
                    </Typography>
                    <TextField 
                      fullWidth
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      />
                  </Box>
                  <Box
                    mb={"20px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}
                      fontWeight={600}>
                      Phone number
                      <TextField 
                        fullWidth
                        name='phone'
                        value={formData.phone}
                        onChange={handleChange}
                        />
                    </Typography>
                  </Box>

                  <LoadingButton
                    variant='contained'
                    fullWidth
                    disabled={!(formData.name !== "")}
                    loading={loading2}
                    onClick={handleSubmit}>
                    Submit
                  </LoadingButton>
                </Box>
                :
                <></>
              
            }
          </Container>
        </Box>
      </Box>
    </Modal>
  )
}

export default VendorDetailsModal;