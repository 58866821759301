import React from 'react';

import { 
  Box,
  TableCell,
  TableRow,
  Typography 
} from '@mui/material';

import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';

import "../../global.scss";

const NATable = ({ label }) => {
  return (
    <TableRow
      height={"200px"} 
      className="flexCenterCenterRow">
      <TableCell colSpan={10}>
        <Box
          className="flexCenterCenterColumn"
          gap={"15px"}>
          <InboxOutlinedIcon 
            sx={{ color: "grey", width: "50px", height: "50px" }}/>
          <Typography
            textAlign={"center"}
            color={"grey"}>
            <i>{label ? label : "NA"}</i>
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
   
  )
}

export default NATable;