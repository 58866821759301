import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  IconButton,
  MenuItem,
  Modal, 
  TextField,
  Typography, 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';
import NA from '../../../../components/utils/NA';

import DeleteIcon from '@mui/icons-material/Delete';

import {  
  CreateExpenseApprovalWorkflowMembers 
} from '../../../../redux/actions/expense/approvalWorkflowsActions';

import "./styles.scss";

const Data = {
  EmployeeId: ""
};

const ApprovalEmployeesModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.other);
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const [employeesList, setEmployeesList] = useState([]);
  const [selectedEmployeesList, setSelectedEmployeesList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
    setSelectedEmployeesList([]);
  };

  useEffect(() => {
    if(open) {
      if(update?.defaultMode === 1){
        // mode = 1 - expense submitter group
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - first level approver
        setMode(2);
      }else if(update?.defaultMode === 3){
        // mode = 3 - second level approver
        setMode(3);
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatEmployeesList(state.employeesList || []);
    } catch (err) {}
  }, [state]);

  const formatEmployeesList = (list) => {
    setEmployeesList(list);
  }; 

  const handleChange = (e) => {
    if(e.target.name === "EmployeeId"){
      setSelectedEmployeesList([ ...selectedEmployeesList, e.target.value ]);
      setEmployeesList(employeesList.filter(i => i.employee_id !== e.target.value.employee_id ));
      setFormData({ ...formData, [e.target.name]: "" });
    }else{
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 - expense submitter group
        let obj = {
          "employees": selectedEmployeesList.map(i => {
            return i.employee_id;
          }),
          "workflowId": update?.workflowId
        };
        dispatch(CreateExpenseApprovalWorkflowMembers(companyId, update?.groupId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose()
          })
        });
      } else if(mode === 2){
        // mode = 2 - first level approver
        let obj = {
          "employees": selectedEmployeesList.map(i => {
            return i.employee_id;
          }),
          "workflowId": update?.workflowId
        };
        dispatch(CreateExpenseApprovalWorkflowMembers(companyId, update?.groupId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose()
          });
        });
      } else if(mode === 3){
        // mode = 3 - second level approver
        let obj = {
          "employees": selectedEmployeesList.map(i => {
            return i.employee_id;
          }),
          "workflowId": update?.workflowId
        };
        dispatch(CreateExpenseApprovalWorkflowMembers(companyId, update?.groupId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose()
          });
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  const handleRemoveSelectedEmployee = (id) => {
    let selectedTemp = selectedEmployeesList;
    setSelectedEmployeesList(selectedTemp.filter(i => i.employee_id !== id));
    formatEmployeesList(state.employeesList || []);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className={`fullpage__modal animate__animated animate__fadeIn`}>
          
        <ModalTopBar
          onBack={handleClose}
          title={title} 
          />

        <Container 
          maxWidth="sm">
          <Box 
            className="flex__Column"
            gap={"20px"}
            pt="20px">
            <TextField 
              select
              label="Select employees"
              fullWidth
              name="EmployeeId"
              value={formData.EmployeeId}
              onChange={handleChange}
              >
              {
                employeesList.length === 0
                ?
                  <MenuItem disabled>
                    No employee found
                  </MenuItem>
                :
                employeesList.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    <Box className="flexCenter_Row" gap={"10px"}>
                      <Typography>
                        {item.employee_name} 
                      </Typography>
                      <Typography
                        variant='body2'
                        color={"grey"}>
                        ({item.employee_email})
                      </Typography>
                    </Box>
                  </MenuItem>
                ))
              }
            </TextField>
            {
              selectedEmployeesList.length > 0
              &&
              <Typography
                variant='subtitle1'>
                Selected employees
              </Typography>
            }
            <Box
              className="flex__Column"
              gap={"20px"}>
              {
                selectedEmployeesList.map((item, index) => (
                <Box
                  key={index}
                  className="flexCenterSBRow spendwallet__emp"
                  gap={"10px"}>
                  <Box
                    className="flexCenter_Row"
                    gap={"10px"}>
                    <Typography>
                      {item.employee_name || <NA />}
                    </Typography>
                    <Typography
                      variant='body2'
                      color={"grey"}>
                      ({item.employee_email})
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => {
                      handleRemoveSelectedEmployee(item.employee_id);
                    }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleSubmit}>
              {mode === 1 ? "Submit" : "Update"}
            </LoadingButton>
          </Box>
        </Container>
      </Box>
    </Modal>
  )
}

export default ApprovalEmployeesModal;