import * as actionTypes from "../actionTypes.js";

const initialState = {
  billingPayList: null,
  billingReceiveList: null, 
  billingExpenseList: null,
  billingCrossBorderPaymentList: null,

  planBillingList: null, 
  addonBillingList: null
};

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BILLING_PAY_BILL:
      return { ...state, billingPayList: action.payload };
    case actionTypes.GET_BILLING_RECEIVE_BILL:
      return { ...state, billingReceiveList: action.payload };
    case actionTypes.GET_BILLING_EXPENSE_BILL:
      return { ...state, billingExpenseList: action.payload };
    case actionTypes.GET_BILLING_CROSS_BORDER_PAYMENT_BILL:
      return { ...state, billingCrossBorderPaymentList: action.payload };

    case actionTypes.GET_PLAN_BILLING_BILL:
      return { ...state, planBillingList: action.payload };
    case actionTypes.GET_ADDON_BILLING_BILL:
      return { ...state, addonBillingList: action.payload };


    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default billingReducer;