import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const GetCurrencies = () => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  try {
    const { data, status } = await api.getCurrencies();
    console.log(data)
    if (status === 200) {
      dispatch({ type: actionTypes.GET_CURRENCIES, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
  }
};

export const GetEmployeesSearch = (companyId, page, limit) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  try {
    const { data, status } = await api.getEmployeesSearch(companyId, page, limit);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_EMPLOYEES_SEARCH, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
  }
};

// ===================== Resource Uploader =====================
export const UploadFiles = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode
  try {
    const { data, status } = await api.uploadFiles(formData);
    res = data;
    statusCode = status;
    if (status === 201) {

    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode }
};

// ===================== Holidays ========================
export const GetHolidays = (companyId) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  try {
    const { data, status } = await api.getHolidays(companyId);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_HOLIDAYS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
  }
};