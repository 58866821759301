import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  Modal, 
  TextField, 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateExpenseCategory,
  UpdateExpenseCategory 
} from '../../../../redux/actions/expense/categoriesActions';

import "./styles.scss";

const Data = {
  Category: "",
  Description: ""
};

const CategoryModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open) {
      if(update?.defaultMode === 1){
        // mode = 1 - create category
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - edit category
        setMode(2);
        setFormData({
          ...Data, 
          Category: update?.category || "",
          Description: update?.description || ""
        });
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> create category
        let obj = {
          "category": formData.Category || "",
          "description": formData.Description || "",
        };
        dispatch(CreateExpenseCategory(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2){
        // mode = 2 -> edit category
        let obj = {
          "category": formData.Category || "",
          "description": formData.Description || "",
        };
        dispatch(UpdateExpenseCategory(companyId, update?.category_id, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className={`fullpage__modal animate__animated animate__fadeIn`}>
          
        <ModalTopBar
          onBack={handleClose}
          title={title} 
          />

        <Container 
          maxWidth="sm">
          <Box 
            className="flex__Column"
            gap={"20px"}
            pt="20px">
            <Box 
              className="flexFSSBRow"
              gap="15px">
              <TextField 
                label="Category"
                fullWidth
                name="Category"
                value={formData.Category}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexFSSBRow"
              gap="15px">
              <TextField 
                label="Description"
                fullWidth
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                />
            </Box>
            <LoadingButton
              variant="contained"
              loading={loading}
              disabled={!(formData.Category !== "" && formData.Description !== "")}
              onClick={handleSubmit}>
              {mode === 1 ? "Submit" : "Update"}
            </LoadingButton>
          </Box>
        </Container>
      </Box>
    </Modal>
  )
}

export default CategoryModal;