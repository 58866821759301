import * as actionTypes from "../../actionTypes";
import * as api from "../../../api/index";
import { toast } from 'react-toastify';

export const AuthenicateGuest = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.authenicateGuest(formData);
    res = data;
    statusCode = status;
    if (status === 201) {
      // toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};

// ====================== guest otp ==========================
export const SendGuestOtp = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.sendGuestOtp(formData);
    res = data;
    statusCode = status;
    if (status === 201) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};
  
export const ResendGuestOtp = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.resendGuestOtp(formData);
    res = data;
    statusCode = status;
    if (status === 201) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};

export const VerifyGuestOtp = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.verifyGuestOtp(formData);
    res = data;
    statusCode = status;
    if (status === 202) {
      toast.success(data.message);
      dispatch({ type: actionTypes.GUEST_AUTHENICATE, payload: data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};
  