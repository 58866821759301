import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../components/topBar/TopBar';
import TabsPanel from '../../components/tabs/TabsPanel';
import VendorsPage from './vendorsPage/VendorsPage';
import BillsPage from './billsPage/BillsPage';
import ApprovalsPage from './approvalsPage/ApprovalsPage';
import PaymentsPage from './paymentsPage/PaymentsPage';

const PayIndex = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(localStorage.getItem("payTab"));

  console.log(currentTab)

  useEffect(() => {
    if("child" in params){
      switch (params.child) {
        case "vendors": 
          localStorage.setItem("payTab", "1"); 
          setCurrentTab("1"); 
          break;
        case "bills": 
          localStorage.setItem("payTab", "2"); 
          setCurrentTab("2");
          break;
        case "approvals": 
          localStorage.setItem("payTab", "3"); 
          setCurrentTab("3"); 
          break;
        case "payments": 
          localStorage.setItem("payTab", "4"); 
          setCurrentTab("4"); 
          break;

        default: {
          localStorage.setItem("payTab", "1");
          setCurrentTab("1");
          navigate("/pay");
        }
      }
      navigate("/pay")
    }else{
      if(!localStorage.getItem("payTab")){
        localStorage.setItem("payTab", "1");
        setCurrentTab("1");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <Box>
      <TopBar
        title="Pay"
        />
      
      <Box className="flexCenterSBRow" mb={"10px"}>
        <TabsPanel
          value={currentTab+""}
          handleChange={(e, value) => {
            localStorage.setItem("payTab", value);
            setCurrentTab(value);
          }}
          links={[
            { value: "1", label: "Vendors", path: "/vendors" },
            { value: "2", label: "Bills", path: "/bills" },
            { value: "3", label: "Approvals", path: "/approvals" },
            { value: "4", label: "Payments", path: "/payments" }
          ]}
          />
      </Box>
      {
        currentTab === "1"
        ?
          <VendorsPage />
          :
            currentTab === "2"
            ?
              <BillsPage />
              :
                currentTab === "3"
                ?
                  <ApprovalsPage />
                  :
                    currentTab === "4"
                    ?
                      <PaymentsPage />
                      :
                        <></>
      }
    </Box>
  )
}

export default PayIndex;