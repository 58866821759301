import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Skeleton, 
  Table, 
  TableFooter, 
  TablePagination, 
  TableRow, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import useCurrencyService from '../../../../components/hooks/useCurrencyService';
import SearchTextField from '../../../../components/textFields/SearchTextField';

import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';

import { 
  DeletePayApprovalWorkflow,
  DeletePayApprovalWorkflowMember,
  GetPayApprovalWorkflows 
} from '../../../../redux/actions/pay/approvalWorkflowsActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import ApprovalEmployeesModal from './ApprovalEmployeesModal';
import ApprovalsModal from './ApprovalsModal';

import { 
  GetEmployeesSearch 
} from '../../../../redux/actions/miscellaneousActions';

import "./styles.scss";

const ApprovalsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  const { handleShowAmount } = useCurrencyService();
  const [search, setSearch] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [sort, setSort] = useState("created_at");
  // eslint-disable-next-line no-unused-vars
  const [sortDirection, setSortDirection] = useState("desc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [approvalEmployeesModalOpen, setApprovalEmployeesModalOpen] = useState(false);
  const [approvalsModalOpen, setApprovalsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);
    
  const [approvalWorkflowsList, setApprovalWorkflowsList] = useState([]);
  const [approvalWorkflowsTotal, setApprovalWorkflowsTotal] = useState(0);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePaginationInit(0, 5);

  useEffect(() => {
    dispatch(GetEmployeesSearch(companyId, 1, 1000));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetPayApprovalWorkflows();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatApprovalWorkflowsList(state.approvalWorkflowsList.records || []);
      setApprovalWorkflowsTotal(state.approvalWorkflowsList.total || 0);
    } catch (err) {}
  }, [state]);  

  const formatApprovalWorkflowsList = (list) => {
    setApprovalWorkflowsList(list);
  };

  async function handleGetPayApprovalWorkflows(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetPayApprovalWorkflows(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeletePayApprovalWorkflow(companyId, deleteId, setDeleting)).then(() => {
      handleGetPayApprovalWorkflows(setDeleting).then(() => {
        setDeleteId(null);
      })
    });
  };

  const handleDeleteUserFromGroup = (groupId, userId) => {
    dispatch(DeletePayApprovalWorkflowMember(companyId, groupId, userId, setDeleting)).then(() => {
      handleGetPayApprovalWorkflows(setDeleting).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <ApprovalsModal
        open={approvalsModalOpen}
        setOpen={setApprovalsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetPayApprovalWorkflows}
        />

      <ApprovalEmployeesModal
        open={approvalEmployeesModalOpen}
        setOpen={setApprovalEmployeesModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetPayApprovalWorkflows}
        />

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <Box 
        className="flexCenterSBRow" 
        mb={"15px"}>
        <Typography variant='subtitle2'>
          Add approval workflows for pay
        </Typography>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
          <Button variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Create approval workflow");
              setApprovalsModalOpen(!approvalsModalOpen);
            }}>
            Add Workflow
          </Button>
        </Box>
      </Box>

      {
        loading
        ?
        <Skeleton
          variant="rectangular"
          sx={{ borderRadius: "16px" }}
          width={"100%"}
          height={"200px"}
          />
        :
          approvalWorkflowsList.length === 0
          ?
          <Box
            height={"200px"}
            className="flexCenterCenterRow">
            <Typography>
              No approval workflow found
            </Typography>
          </Box>
          :
            <>
            {
              approvalWorkflowsList.map((item, index) => (
                <Box
                  key={index}
                  className="approval">
                  <Box
                    className="flexFSSBRow">
                    <Box
                      className="flexCenter_Row"
                      gap={"15px"}>
                      <Typography variant='subtitle1'>
                        {item.name}
                      </Typography>
                      {
                        (item?.ref_workflow_grp_id_req?.ref_workflow_group_employees.length === 0 ||
                        item?.ref_workflow_grp_id_app1?.ref_workflow_group_employees.length === 0)
                        &&
                        <Chip
                          color='error'
                          size='small'
                          label={"Incomplete - add users in submitter and approver groups"} 
                          />
                      }
                    </Box>
                    <Box
                      className="flexCenterFERow"
                      gap={"20px"}>
                      <Tooltip
                        title={"Edit " + item?.name || ""}>
                        <IconButton
                          onClick={() => {
                            setUpdate({
                              ...item, 
                              defaultMode: 2
                            });
                            setModalTitle("Edit " + item?.name || "");
                            setApprovalsModalOpen(!approvalsModalOpen);
                          }}>
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>

                      {
                        item.emp_approver_id === deleteId && deleting
                        ?
                        <CircularProgress size={26} sx={{ margin: "7px" }}/>
                        :
                        <Tooltip
                          title={"Delete " + item?.name || ""}>
                          <IconButton
                            onClick={() => {
                              setDeleteId(item.emp_approver_id);
                              setModalTitle(item.name || "");
                              setDeleteConfirmationModalOpen(!deleteConfirmationModalOpen);
                            }}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </Box>
                  </Box>

                  <Box
                    className="flexCenterSBRow"
                    gap={"0px"}>
                    <Box
                      className="approval__box"
                      width={"35%"}>
                      <Box
                        className="flexCenterSBRow"
                        p={"5px 8px"}>
                        <Typography
                          variant='body2'
                          fontWeight={500}>
                          Bill submitters
                        </Typography>
                        <Box>
                          <Tooltip
                            title={"Add user in submitter group of " + item?.name || "" }>
                            <IconButton
                              onClick={() => {
                                setModalTitle("Add users in submitter group");
                                setUpdate({
                                  groupId: item?.ref_workflow_grp_id_req?.group_id,
                                  workflowId: item?.emp_approver_id,
                                  defaultMode: 1
                                });
                                setApprovalEmployeesModalOpen(!approvalEmployeesModalOpen);
                              }}>
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Divider />
                      <Box 
                        p={"8px"}>
                        {
                          item?.ref_workflow_grp_id_req?.ref_workflow_group_employees.length === 0
                          ?
                          <Typography
                            textAlign={"center"}
                            width={"100%"}
                            variant='caption'
                            color="grey">
                            No submitter added
                          </Typography>
                          :
                          item?.ref_workflow_grp_id_req?.ref_workflow_group_employees.map((employee, employeeIndex) => (
                            <Tooltip
                              title={employee?.ref_employee_id?.employee_email}>
                              <Chip 
                                key={employeeIndex}
                                size='small'
                                onDelete={() => {
                                  handleDeleteUserFromGroup(item?.ref_workflow_grp_id_req?.group_id, employee.id)
                                }}
                                sx={{ margin: "0px 8px 8px 0px" }}
                                label={employee?.ref_employee_id?.employee_name}/>
                            </Tooltip>
                          ))
                        }
                      </Box>
                    </Box>
                    
                    <Box>
                      <ArrowForwardIosRoundedIcon 
                        fontSize='large'
                        sx={{ color: "lightgrey"}}/>
                    </Box>

                    <Box>
                      <Typography
                        textAlign={"center"}
                        variant='h6'
                        fontWeight={500}>
                        Range
                      </Typography>
                      <Box
                        className="flexCenter_Row">
                        <Typography
                          textAlign={"center"}
                          variant='subtitle1'>
                          {handleShowAmount(item.currency, item.min_limit)}
                        </Typography>
                        {" - "}
                        <Typography
                          textAlign={"center"}
                          variant='subtitle1'>
                          {handleShowAmount(item.currency, item.max_limit)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <ArrowForwardIosRoundedIcon 
                        fontSize='large'
                        sx={{ color: "lightgrey" }}/>
                    </Box>

                    <Box
                      className="approval__box"
                      width={"25%"}>
                      <Box
                        className="flexCenterSBRow"
                        p={"5px 8px"}>
                        <Typography
                          minWidth={"50%"}
                          variant='body2'
                          fontWeight={500}>
                          First level approvers
                        </Typography>
                        <Box>
                          <Tooltip
                            title={"Add user in primary approver group of " + item?.name || "" }>
                            <IconButton
                              onClick={() => {
                                setModalTitle("Add users in approval group");
                                setUpdate({        
                                  groupId: item?.ref_workflow_grp_id_app1?.group_id,
                                  workflowId: item?.emp_approver_id,
                                  defaultMode: 2
                                });
                                setApprovalEmployeesModalOpen(!approvalEmployeesModalOpen);
                              }}>
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Divider />
                      <Box 
                        p={"8px"}>
                        {
                          item?.ref_workflow_grp_id_app1?.ref_workflow_group_employees.length === 0
                          ?
                          <Typography
                            textAlign={"center"}
                            width={"100%"}
                            variant='caption'
                            color="grey">
                            No first level approver added
                          </Typography>
                          :
                          item?.ref_workflow_grp_id_app1?.ref_workflow_group_employees.map((employee, employeeIndex) => (
                            <Tooltip
                              title={employee?.ref_employee_id?.employee_email}>
                              <Chip 
                                key={employeeIndex}
                                size='small'
                                label={employee?.ref_employee_id?.employee_name}
                                onDelete={() => { 
                                  handleDeleteUserFromGroup(item?.ref_workflow_grp_id_app1?.group_id, employee.id);
                                }}
                                sx={{ margin: "0px 8px 8px 0px" }}/>
                            </Tooltip>
                          ))
                        }
                      </Box>
                    </Box>

                    <Box>
                      <ArrowForwardIosRoundedIcon 
                        fontSize='large'
                        sx={{ color: "lightgrey" }}/>
                    </Box>

                    <Box
                      className="approval__box"
                      width={"25%"}>
                      <Box
                        className="flexCenterSBRow"
                        p={"5px 8px"}>
                        <Typography
                          minWidth={"50%"}
                          variant='body2'
                          fontWeight={500}>
                          Second level approvers
                        </Typography>
                        <Box>
                          <Tooltip
                            title={"Add user in secondary approver group of " + item?.name || "" }>
                            <IconButton
                              onClick={() => {
                                setModalTitle("Add users in second level approval group");
                                setUpdate({
                                  groupId: item?.ref_workflow_grp_id_app2?.group_id,
                                  workflowId: item?.emp_approver_id,
                                  defaultMode: 3
                                });
                                setApprovalEmployeesModalOpen(!approvalEmployeesModalOpen);
                              }}>
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Divider />
                      <Box 
                        p={"8px"}>
                        {
                          item?.ref_workflow_grp_id_app2?.ref_workflow_group_employees.length === 0
                          ?
                          <Typography
                            textAlign={"center"}
                            width={"100%"}
                            variant='caption'
                            color="grey">
                            No second level approver added
                          </Typography>
                          :
                          item?.ref_workflow_grp_id_app2?.ref_workflow_group_employees.map((employee, employeeIndex) => (
                            <Tooltip
                              title={employee?.ref_employee_id?.employee_email}>
                              <Chip 
                                key={employeeIndex}
                                size='small'
                                label={employee?.ref_employee_id?.employee_name}
                                onDelete={() => {
                                  handleDeleteUserFromGroup(item?.ref_workflow_grp_id_app2?.group_id, employee.id);
                                }}
                                sx={{ margin: "0px 8px 8px 0px" }}/>
                            </Tooltip>
                          ))
                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))
            }
            <Table
              className='table'>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    sx={{ borderBottom: 0 }}
                    rowsPerPageOptions={[3, 5, 10, 25]} 
                    colSpan={10}
                    count={approvalWorkflowsTotal}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page'
                      },
                      native: true
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
              </TableFooter>
            </Table>
          </>
      }

    </Box>
  )
}

export default ApprovalsPage;