import React from 'react';
import dayjs from 'dayjs';

import { 
  Chip 
} from '@mui/material';

const BillsStatusChip = (props) => {

  const calculateLabel = () => {
    let label = "";
    let color = "";

    let date = dayjs(props.dueDate);
    let now = dayjs(Date.now());
    let difference = date.diff(now, "d"); 
   
    if(difference < 0){
      label = "Overdue";
      color = "error";
    } else if (difference === 0){
      label = "Due today";
      color = "warning";
    } else if (difference >= 1 && difference < 7) {
      label = "Due in " + difference + (difference === 1 ? " day" : " days");
      color = "info";
    } else if (difference >= 7){
      label = "Due later";
      color = "success";
    }

    return {label, color};
  };

  return (
    <>
    {
      props.dueDate !== null
      ?
      <Chip
        { ...props }
        size='small'
        color={calculateLabel().color}
        label={calculateLabel().label || "NA"}
        />
      :
      <></>
    }
    </>
  )
}

export default BillsStatusChip;