import * as actionTypes from "../../actionTypes";
import * as api from "../../../api/index";
import { toast } from "react-toastify";

export const GetGuestBasicInfo = (setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.getGuestBasicInfo();
    res = data;
    statusCode = status;
    if (status === 200) {
      // toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};

export const CreateCompanyForGuest = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.createCompanyForGuest(formData);
    if (status === 201) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};


