import * as actionTypes from "../actionTypes.js";

const initialState = {
  company: null,
  invoice: null,

};

const makePaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_MAKEPAYMENT:
      return { ...state, company: action.payload };
    case actionTypes.CLEAR_COMPANY_MAKEPAYMENT:
      return { ...state, company: null };
      
    case actionTypes.GET_INVOICE_MAKEPAYMENT:
      return { ...state, invoice: action.payload };
    case actionTypes.CLEAR_INVOICE_MAKEPAYMENT:
        return { ...state, invoice: null };


    case actionTypes.SELECT_COMPANY:
      return initialState;
    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default makePaymentReducer;