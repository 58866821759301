import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
	Autocomplete,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import useCurrencyService from '../../../components/hooks/useCurrencyService';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../components/buttons/Button';
import NA from '../../../components/utils/NA';

import DeleteIcon from '@mui/icons-material/Delete';

import { 
	GetInvoices 
} from '../../../redux/actions/receive/invoicesActions';
import { 
	UpdateReceipt 
} from '../../../redux/actions/receive/receiptsActions';

import "./styles.scss";

const Data = {
	InvoiceNumber: ""
};

const PaymentsReceivedModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.receive);
  const companyId = useSelectedCompany();
  const { handleShowAmount } = useCurrencyService();
  // eslint-disable-next-line no-unused-vars
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
	const [loadingInvoiceNumber, setLoadingInvoiceNumber] = useState(false);
  const [mode, setMode] = useState(-1);

	const [invoicesList, setInvoicesList] = useState([]);	
	const [selectedInvoice, setSelectedInvoice] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
		setSelectedInvoice(null);
    setMode(-1);
  };

  useEffect(() => {
    try {
      if(open){
        if(update?.defaultMode === 1){
          // mode = 1 - edit invoice number
          setMode(1);
        }else{
          toast.error("Something went wrong!");
          handleClose();
        }
      }
    } catch (err) {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      if(mode === 1) formatInvoices(state.invoicesList.records || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // ------------------- obj formatter -------------------
  const formatInvoices = (list) => {
    setInvoicesList(list);
  };

	const handleGetInvoiceByInvoiceNumber = (invoice_number) => {
		let query = `&search=${invoice_number}&status__in=Created,Sent`
		dispatch(GetInvoices(companyId, 1, 10, query, setLoadingInvoiceNumber));
	};

	const handleRemoveSelectedInvoice = () => {
		setSelectedInvoice(null);
	}

	const handleSubmit = () => {
		try {
			if(mode === 1){
				// mode = 1 - edit invoice number
				let obj = {
					"ref_invoice_id": selectedInvoice.invoice_id
				};

				dispatch(UpdateReceipt(companyId, update?.receipt_id, obj, setLoading)).then(() => {
					handleGet(setLoading).then(() => {
						handleClose();
					})
				});
			} else {
				throw new Error();
			}
		} catch (err) {	
			toast.error("Something went wrong!");
		}
	}

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          className="fullpage__modal">

          <ModalTopBar 
            title={title}
            onBack={handleClose}
            />

					<Container 
						maxWidth="sm">

						<Box 
              className="flexCenter_Column"
              gap={"20px"}
              mb={"20px"}
              pt={"20px"}>
              <Box
								width={"100%"}
                className="flex__Column"
                gap={"20px"}>
								<Autocomplete
									fullWidth={true}
									getOptionLabel={(option) => "#"+option.invoice_number}
									options={invoicesList}
									loading={loadingInvoiceNumber}
									value={null}
									onChange={(e, newValue) => {
										setSelectedInvoice(newValue)
									}}
									inputValue={formData.InvoiceNumber}
									onInputChange={(e, newValue) => {
										console.log(newValue)
										setFormData({ InvoiceNumber: newValue })
										handleGetInvoiceByInvoiceNumber(newValue);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Invoice number"
											placeholder='Enter a number to show matching invoice numbers'
											slotProps={{
												input: {
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{loadingInvoiceNumber ? <CircularProgress size={20} /> : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													),
												},
											}}
										/>
									)}
								/>

								{
									selectedInvoice
									&&
									<Box
										className="flex__Column"
										gap={"10px"}>
										<Typography
											variant='body2'>
											Selected invoice
										</Typography>

										<Box
											className="flexCenterSBRow receipts__invoice"
											gap={"10px"}>
											<Box
												className="flexCenterSBRow"
												width={"100%"}
												gap={"10px"}>
												<Typography>
													{"#"+selectedInvoice.invoice_number || <NA />}
												</Typography>
												<Typography>
													Invoice total&nbsp;
													<strong>{handleShowAmount(selectedInvoice.currency, selectedInvoice.total_amount || 0)}</strong>
												</Typography>
											</Box>
											<IconButton
												onClick={() => {
													handleRemoveSelectedInvoice();
												}}>
												<DeleteIcon />
											</IconButton>
                    </Box>
									</Box>
								}
									
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  disabled={!(selectedInvoice)}
                  onClick={handleSubmit}>
                  Submit
                </LoadingButton>
              </Box>
              </Box>
									
					</Container>  

        </Box>
      </Box>
    </Modal>
  )
}

export default PaymentsReceivedModal;