import React from 'react';

import { 
  Box,
  Divider,
  Menu, 
  MenuItem,
  Typography,
} from '@mui/material';

import CompanyAvatar from '../avatars/CompanyAvatar';

import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';

import "../../global.scss";

const CompaniesMenu = ({
  open, 
  anchor,
  companiesList,
  selectedCompany,
  handleSelectCompany,
  navigateSettings,
  onClose
}) => {
  return (
    <Menu
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {
        companiesList
        &&
        companiesList.length === 0
        ?
          <MenuItem disabled
            sx={{ width: "200px" }}>
            No company found
          </MenuItem>
        :
          companiesList.map((item, index) => (
            <MenuItem 
              key={index} 
              value={item.Id}
              onClick={ 
              //   selectedCompany && selectedCompany.Id !== item?.Id
              // ? () => {
              //   handleSelectCompany(item);
              //   onClose();
              // } 
              // : 
              () => {
                if(selectedCompany){
                  if (selectedCompany.Id !== item?.Id){
                    handleSelectCompany(item);
                    onClose();
                  }
                } else {
                  handleSelectCompany(item);
                  onClose();
                }
              }}
              selected={selectedCompany && selectedCompany.Id === item?.Id ? true : false}
              >
              <Box
                className="flexCenter_Row"
                gap="10px">
                {
                  item?.LogoUrl
                  ?
                  <Box
                    sx={{ 
                      width: "24px", 
                      height: "24px", 
                      borderRadius: "8px" ,
                      overflow: "hidden",
                      border: "solid 1px #f1f1f1" }}>
                      <img 
                         src={item?.LogoUrl}
                        alt='company-logo'
                        style={{
                          width: "24px", 
                          height: "24px", 
                          objectFit: "contain"
                        }}
                        />
                  </Box>
                  :
                  <CompanyAvatar 
                    color={item?.Color}
                    size="small">
                    {item?.LegalName || ""}
                  </CompanyAvatar>
                }
                <Typography 
                  variant='body1'
                  className='longText'
                  sx={{ width: "220px" }}
                  fontWeight={500}>
                  {item?.LegalName || ""}
                </Typography>
              </Box>
            </MenuItem>
          ))
      }
      <Divider />
      {
        companiesList
        &&
        <MenuItem
          width={"200px"}
          onClick={() => {
            navigateSettings("/dashboard");
            onClose();
          }}>
          <Box
            className='flexCenterSBRow'
            gap={"5px"}>
            <BusinessIcon 
              fontSize='small'/>
            Companies
          </Box>
        </MenuItem>
      }
        <Divider />
        <MenuItem
          width={"200px"}
          onClick={() => {
            navigateSettings("/dashboard?addCompany=true");
            onClose();
          }}>
          <Box
            className='flexCenterSBRow'
            gap={"5px"}>
            <AddIcon 
              fontSize='small'/>
            Add Company
          </Box>
        </MenuItem>
    </Menu>
  )
}

export default CompaniesMenu