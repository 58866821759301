import * as actionTypes from "../../actionTypes";
import * as api from "../../../api/index";
import { toast } from "react-toastify";

export const GetSubscriptions = (page, limit, query, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getSubscriptions(page, limit, query);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_SUBSCRIPTIONS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const AddSubscription = (companyId, formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.addSubscription(companyId, formData);
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetMySubscription = (companyId, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getMySubscription(companyId);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_MY_SUBSCRIPTION, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};