import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  Modal, 
  TextField, 
  Typography,
  Button
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateCostObject, 
  GetCostObjects 
} from '../../../../redux/actions/expense/costObjectsActions';

import "./styles.scss";

const Data = {
  CostObject: ""
};

const CreateCostObjectsModal = ({
  open, 
  setOpen,
  title,
  onCreate
}) => {
  const dispatch = useDispatch();
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
  };

  useEffect(() => {
    if(open) {
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      let obj = {
        "name": formData.CostObject || "",
      };
      dispatch(CreateCostObject(companyId, obj, setLoading)).then(({res, statusCode}) => {
        if(statusCode === 201){
          onCreate(res)
        };
        dispatch(GetCostObjects(companyId, 1, 1000, setLoading)).then(() => {
          handleClose();
        })
      });
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          minWidth={"400px"}
          sx={{ backgroundColor: "white", borderRadius: "20px", padding: "20px 0px"}}
          className={` animate__animated animate__fadeIn`}>
          
          <Container 
            maxWidth="sm">

            <Box 
              className="flex__Column"
              gap={"30px"}>
            
              <Typography
                variant='h5'>
                {title}
              </Typography>

              <TextField 
                label="Cost object name"
                fullWidth
                name="CostObject"
                value={formData.CostObject}
                onChange={handleChange}
                />

              <Box
                className="flexCenterFERow"
                gap={"20px"}>
                <Button
                  variant="text"
                  onClick={handleClose}>
                  Close
                </Button>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={handleSubmit}>
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateCostObjectsModal;