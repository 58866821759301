import axios from "axios";
import { LOG_OUT } from "../redux/actionTypes";
import { store } from "../redux/store";

export const API = axios.create({
  baseURL: `https://api.lusid.ai/b2b/`,
  // baseURL: "http://beta-backend-alb-876200342.ap-south-1.elb.amazonaws.com/b2b/"
});

export const LocalAPI = axios.create({
  baseURL: `http://localhost:8000/b2b/`,
});

export const LocalAPI2 = axios.create({
  baseURL: `http://localhost:8001/b2b/`,
});

// REQUEST INTERCEPTOR
API.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("user"))}`;
  }
  return req;
});

// RESPONSE INTERCEPTOR
API.interceptors.response.use((response) => { 
    return response 
  },
  async (error) => {


    // TOKEN REFRESH LOGIC
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Prevent infinite loop of retries

      try {
        // Attempt to refresh the token
        const { data, status } = await refreshToken(
          { refresh_token: JSON.parse(localStorage.getItem("refresh_token")) },
          handleRequestCanceller()
        );

        console.log("interceptors", data, status)

        if(status === 200){
          // store it in local storage
          localStorage.setItem("user", JSON.stringify(data.data.access_token));
        }
        // Set the new access token in headers and retry the original request
        API.defaults.headers.common['Authorization'] = `Bearer ${data.data.access_token}`;
        originalRequest.headers['Authorization'] = `Bearer ${data.data.access_token}`;

        return API(originalRequest); // Retry the original request with new token
      } catch (refreshError) {
        console.log("refreshError", refreshError)
        if(refreshError.message !== "REQUEST_CANCELLED") {
          store.dispatch({ type: LOG_OUT });
        }
        return Promise.reject(refreshError);
      }
    }

    console.log("refreshError 2", error)
    return Promise.reject(error);
  }
);

// LOCAL - REQUEST INTERCEPTOR
LocalAPI.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("user"))}`;
  }
  return req;
});

LocalAPI2.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("user"))}`;
  }
  return req;
});


// REQUEST CANCELLER
const handleRequestCanceller = () => {
  // if (cancelToken) {
  //   cancelToken.cancel('REQUEST_CANCELLED');
  // }

  // cancelToken = axios.CancelToken.source();

  // return {
  //   cancelToken: cancelToken.token,
  // }
}

// ======================== Auth ===========================
export const login = (form) =>  API.post(`identity/web/v1/auth/login`, form);
export const register = (form) => API.post(`identity/web/v1/auth/register`, form);
export const forgotPassword = () => API.post(``);
export const refreshToken = (form) => API.post(`identity/auth/v2/generate-access-token`, form, handleRequestCanceller());

export const sendRegisterOtp = (form) => API.post(`identity/web/v1/auth/otp-send`, form);
export const resendRegisterOtp = (form) => API.post(`identity/web/v1/auth/otp-resend`, form);
export const verifyRegisterOtp = (form) => API.post(`identity/web/v1/auth/otp-verify`, form);

// ======================== Auth - Guest ====================
export const authenicateGuest = (form) => API.post(`identity/web/v1/guest/authenicate`, form);

export const sendGuestOtp = (form) => API.post(`identity/web/v1/guest/otp-send`, form);
export const resendGuestOtp = (form) => API.post(`identity/web/v1/guest/otp-resend`, form);
export const verifyGuestOtp = (form) => API.post(`identity/web/v1/guest/otp-verify`, form);

export const getGuestBasicInfo = () => API.get(`identity/web/v1/guest/info`);
export const createCompanyForGuest = (form) => API.post(`identity/web/v1/guest/info`, form);


// ======================== Dashboard =======================
export const getCompanies = (page, limit, query) => API.get(`identity/web/v1/companies?page=${page}&limit=${limit}${query}`);
export const getCompany = (id) => API.get(`identity/web/v1/companies/${id}`);
export const createCompany = (form) => API.post(`identity/web/v1/companies`, form);
export const updateCompany = (id, form) => API.put(`identity/web/v1/companies/${id}`, form);
export const deleteCompany = (id)  => API.delete(`identity/companies/v1/${id}`);

export const getMyProfile = () => API.get(`identity/web/v1/employees/my-profile`);
export const updateMyProfile = (form) => API.put(`identity/web/v1/employees/my-profile`, form);

export const getUserBankAccount = () => API.get(`virtual-cards/web/v1/account/user-bank-accounts`);
export const createUserBankAccount = (form) => API.post(`virtual-cards/web/v1/account/user-bank-accounts`, form);
export const deleteUserBankAccount = (id) => API.delete(`virtual-cards/web/v1/account/user-bank-accounts/${id}`);
export const createVerificationUserBankAccount = (form) => API.post(`virtual-cards/web/v1/account/user-bank-accounts/verification`, form);
export const verifyUserBankAccount = (form) => API.post(`virtual-cards/web/v1/account/user-bank-accounts/verify`, form);

export const getCompanyInfo = (companyId) => API.get(`identity/web/v1/employees/company-info?c_id=${companyId}`);


// ======================== Main =======================
export const getBranches = (companyId, page, limit) => API.get(`identity/web/v1/branches?c_id=${companyId}&page=${page}&limit=${limit}`);
export const createBranch = (companyId, form) => API.post(`identity/web/v1/branches?c_id=${companyId}`, form);
export const updateBranch = (companyId, id, form) => API.put(`identity/web/v1/branches/${id}?c_id=${companyId}`, form);
export const deleteBranch = (companyId, id)  => API.delete(`identity/web/v1/branches/${id}?c_id=${companyId}`);

export const getEmployees = (companyId, page, limit) => API.get(`identity/web/v1/employees/?c_id=${companyId}&page=${page}&limit=${limit}`);
export const createEmployee = (companyId, form) => API.post(`identity/web/v1/employees/?c_id=${companyId}`, form);
export const updateEmployee = (companyId, id, form) => API.put(`identity/web/v1/employees/${id}/?c_id=${companyId}`, form);
export const deleteEmployee = (companyId, id)  => API.delete(`identity/web/v1/employees/${id}/?c_id=${companyId}`);

export const getSubscriptions = (page, limit, query) => API.get(`identity/web/v1/subscriptions?page=${page}&limit=${limit}${query}`);
export const addSubscription = (companyId, form) => API.put(`identity/web/v1/companies/add-subscription?c_id=${companyId}`, form);
export const getMySubscription = (companyId) => API.get(`identity/web/v1/subscriptions/my-plan?c_id=${companyId}`);
export const getPlanAddons = (page, limit, query) => API.get(`identity/web/v1/plan-addons?page=${page}&limit=${limit}${query}`);


// ======================== UNSORTED =======================
export const associatedCompanies = () => API.get(`identity/web/v1/users/associated-companies`);

export const getBankAccounts = (companyId) => API.get(`virtual-cards/identity/v1/bank-accounts/?c_id=${companyId}`);

export const getRecurringBills = () => API.get(`virtual-cards/invoice/v1/recurring-bill/`);
export const getRecurringBill = (id) => API.get(`virtual-cards/invoice/v1/recurring-bill/${id}/`);
export const createRecurringBill = (form) => API.post(`virtual-cards/invoice/v1/recurring-bill/`, form);

export const getPaymentFrequencies = (companyId) => API.get(`virtual-cards/invoice/v1/pmt-frequency/?c_id=${companyId}`);
export const getPaymentFees = (companyId) => API.get(`virtual-cards/invoice/v1/pmt-fees/?c_id=${companyId}`);

export const getDeliverySpeed = (companyId, id) => API.get(`virtual-cards/invoice/v1/delivery-speed/?c_id=${companyId}&method_id=${id}`);

export const getSuppliers = (companyId, page, limit) => API.get(`virtual-cards/supplier/v1/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const createSupplier = (companyId, form) => API.post(`virtual-cards/supplier/v1/?c_id=${companyId}`, form);
export const updateSupplier = (companyId, id, form) => API.put(`virtual-cards/supplier/v1/${id}/?c_id=${companyId}`, form);
export const deleteSupplier = (companyId, id)  => API.delete(`virtual-cards/supplier/v1/${id}/?c_id=${companyId}`);

export const getWallets = (page, limit) => API.get(`virtual-cards/spend-wallet/wallet/v1/?page=${page}&page_size=${limit}`);
export const createWallet = (form) => API.post(`virtual-cards/spend-wallet/wallet/v1`, form);
export const updateWallet = (id, form) => API.put(`virtual-cards/spend-wallet/wallet/v1/${id}`, form);
export const deleteWallet = (id)  => API.delete(`virtual-cards/spend-wallet/wallet/v1/${id}`);

export const getVirtualCards = (page, limit) => API.get(`virtual-cards/my-card/v1/?page=${page}&page_size=${limit}`);
export const createVirtualCard = (form) => API.post(`virtual-cards/my-card/v1`, form);
export const updateVirtualCard = (id, form) => API.put(`virtual-cards/my-card/v1/${id}`, form);
export const deleteVirtualCard = (id)  => API.delete(`virtual-cards/my-card/v1/${id}`);


// ======================== Analytics =======================
export const getBillsStats = (companyId) => API.get(`virtual-cards/invoice/v1/bill-stats-by-days/?c_id=${companyId}`);
export const getExpenseStats = (companyId) => API.get(`virtual-cards/exp/v1/expense-stats-by-category/?c_id=${companyId}`);
export const getInvoiceStats = (companyId) => API.get(`virtual-cards/web/v1/receive/invoice-stats-by-days?c_id=${companyId}`);


// ======================== Accounting ======================
export const getAccountTypes = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/accounting/account-type?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);

export const getChartOfAccounts = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/accounting/chart-of-accounts?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const createChartOfAccount = (companyId, form) => API.post(`virtual-cards/web/v1/accounting/chart-of-accounts?c_id=${companyId}`, form);
export const updateChartOfAccount = (companyId, id, form) => API.put(`virtual-cards/web/v1/accounting/chart-of-accounts/${id}?c_id=${companyId}`, form);

export const getSalesTaxes = (companyId, page, limit, isHelper, query) => API.get(`virtual-cards/web/v1/accounting/sales-taxes?c_id=${companyId}&page=${page}&page_size=${limit}&is_helper=${isHelper}${query}`);
export const createSalesTax = (companyId, form) => API.post(`virtual-cards/web/v1/accounting/sales-taxes?c_id=${companyId}`, form);
export const updateSalesTax = (companyId, id, form) => API.put(`virtual-cards/web/v1/accounting/sales-taxes/${id}?c_id=${companyId}`, form);
export const deleteSalesTax = (companyId, id) => API.delete(`virtual-cards/accounting/v1/sales-tax/${id}/?c_id=${companyId}`);

export const getProducts = (companyId, page, limit, isHelper, query) => API.get(`virtual-cards/web/v1/accounting/products?c_id=${companyId}&page=${page}&page_size=${limit}&is_helper=${isHelper}${query}`);
export const createProduct = (companyId, form) => API.post(`virtual-cards/web/v1/accounting/products?c_id=${companyId}`, form);
export const updateProduct = (companyId, id, form) => API.put(`virtual-cards/web/v1/accounting/products/${id}?c_id=${companyId}`, form);
export const deleteProduct = (companyId, id) => API.delete(`virtual-cards/accounting/v1/product/${id}/?c_id=${companyId}`);



// ======================== Pay =============================
export const getDeliveryMethods = (companyId, page, limit) => API.get(`identity/web/v1/delivery-methods?c_id=${companyId}&page=${page}&limit=${limit}`);
export const getServicesForProduct = (companyId, name) => API.get(`identity/web/v1/services/for-product/${name}?c_id=${companyId}`);
export const getServicesForProductForGuest = (companyId, name) => API.get(`identity/web/v1/services/for-product/${name}/guest?c_id=${companyId}`);
export const getServiceFeePrice = (companyId, id, amount) => API.get(`virtual-cards/web/v1/billing/service-fees-price/${id}?c_id=${companyId}&amount=${amount}`);
export const getDeliveryMethodsPrice = (companyId, name, amount) => API.get(`virtual-cards/web/v1/billing/service-fees-price/${name}?c_id=${companyId}&amount=${amount}`);

export const getBanks = (companyId, page, limit) => API.get(`virtual-cards/web/v1/account/banks?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const getBankAccountTypes = (companyId, page, limit) => API.get(`virtual-cards/web/v1/account/bank-account-types?c_id=${companyId}&page=${page}&page_size=${limit}`);

export const getCompanyBankAccounts = (companyId, page, limit) => API.get(`virtual-cards/web/v1/account/company-bank-accounts?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const getCompanyBankAccountDetails = (companyId, id) => API.get(`virtual-cards/web/v1/account/company-bank-accounts/${id}?c_id=${companyId}`);
export const createCompanyBankAccount = (companyId, form) => API.post(`virtual-cards/web/v1/account/company-bank-accounts?c_id=${companyId}`, form);
export const updateCompanyBankAccount = (companyId, id, form) => API.put(`virtual-cards/web/v1/account/company-bank-accounts/${id}?c_id=${companyId}`, form);
export const toggleDefaultCompanyBankAccount = (companyId, id) => API.put(`virtual-cards/web/v1/account/company-bank-accounts/toggle-default/${id}?c_id=${companyId}`);

export const createVerificationCompanyBankAccount = (companyId, form) => API.post(`virtual-cards/web/v1/account/company-bank-accounts/verification?c_id=${companyId}`, form);
export const verifyCompanyBankAccount = (companyId, form) => API.post(`virtual-cards/web/v1/account/company-bank-accounts/verify?c_id=${companyId}`, form);

export const getVendors = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/pay/vendors?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const getVendorDetails = (companyId, id) => API.get(`virtual-cards/web/v1/pay/vendors/${id}?c_id=${companyId}`);
export const createVendor = (companyId, form) => API.post(`virtual-cards/web/v1/pay/vendors?c_id=${companyId}`, form);
export const updateVendor = (companyId, id, form) => API.put(`virtual-cards/web/v1/pay/vendors/${id}?c_id=${companyId}`, form);

export const getVendorAccounts = (companyId, id, page, limit) => API.get(`virtual-cards/web/v1/pay/vendor-bank-accounts/${id}?c_id=${companyId}&page=${page}&page_size=${limit}`, handleRequestCanceller());
export const getVendorAccount = (companyId, vendorId, id) => API.get(`virtual-cards/web/v1/pay/vendor-bank-accounts/${vendorId}/${id}?c_id=${companyId}`);
export const createVendorAccount = (companyId, id, form) => API.post(`virtual-cards/web/v1/pay/vendor-bank-accounts/${id}?c_id=${companyId}`, form);
export const updateVendorAccount = (companyId, vendorId, id, form) => API.put(`virtual-cards/web/v1/pay/vendor-bank-accounts/${vendorId}/${id}?c_id=${companyId}`, form);

export const getPayApprovalWorkflows = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/pay/workflows?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const createPayApprovalWorkflow = (companyId, form) => API.post(`virtual-cards/web/v1/pay/workflows?c_id=${companyId}`, form);
export const updatePayApprovalWorkflow = (companyId, id, form) => API.put(`virtual-cards/web/v1/pay/workflows/${id}?c_id=${companyId}`, form);
export const deletePayApprovalWorkflow = (companyId, id) => API.delete(`virtual-cards/web/v1/pay/workflows/${id}?c_id=${companyId}`);
export const createPayApprovalWorkflowMembers = (companyId, id, form) => API.post(`virtual-cards/web/v1/pay/workflows-members/${id}?c_id=${companyId}`, form);
export const deletePayApprovalWorkflowMember = (companyId, groupId, userId) => API.delete(`virtual-cards/web/v1/pay/workflows-members/${groupId}/${userId}?c_id=${companyId}`);

export const getBills = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/pay/bills?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const getBill = (companyId, id) => API.get(`virtual-cards/invoice/v1/bill/${id}/?c_id=${companyId}`);
export const createBill = (companyId, form) => API.post(`virtual-cards/web/v1/pay/bills?c_id=${companyId}`, form);
export const updateBill = (companyId, id, form) => API.put(`virtual-cards/invoice/v1/bill/${id}/?c_id=${companyId}`, form);
export const deleteBill = (companyId, id) => API.delete(`virtual-cards/invoice/v1/bill/${id}/?c_id=${companyId}`);

export const getPayments = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/pay/payments?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const getPayment = (companyId, id) => API.get(`virtual-cards/web/v1/pay/payments/${id}?c_id=${companyId}`);
export const createPayment = (companyId, form) => API.post(`virtual-cards/web/v1/pay/payments?c_id=${companyId}`, form);
export const updatePayment = (companyId, id, form) => API.put(`virtual-cards/invoice/v1/payment/${id}/?c_id=${companyId}`, form);
export const deletePayment = (companyId, id) => API.delete(`virtual-cards/invoice/v1/payment/${id}/?c_id=${companyId}`);

export const getPaymentActivities = (companyId, id) => API.get(`virtual-cards/web/v1/pay/payment-activities/${id}?c_id=${companyId}`);

export const getPaymentsForApprover = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/pay/pay-approvals?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const submitPaymentForApproval = (companyId, form) => API.post(`virtual-cards/web/v1/pay/pay-approvals-for-employees?c_id=${companyId}`, form);
export const actOnPaymentForApproval = (companyId, form) => API.post(`virtual-cards/web/v1/pay/pay-approvals-for-approvers?c_id=${companyId}`, form);


// ======================== Receive ========================
export const getPaymentHandle = (companyId) => API.get(`virtual-cards/web/v1/account/payment-handle?c_id=${companyId}`);
export const getPaymentQrCode = (companyId) => API.get(`virtual-cards/web/v1/account/company-qr-code?c_id=${companyId}`);
export const createPaymentQrCode = (companyId) => API.post(`virtual-cards/web/v1/account/company-qr-code?c_id=${companyId}`);

export const getCustomers = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/receive/customers?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const createCustomer = (companyId, form) => API.post(`virtual-cards/web/v1/receive/customers?c_id=${companyId}`, form);
export const updateCustomer = (companyId, id, form) => API.put(`virtual-cards/receive/v1/customer/${id}/?c_id=${companyId}`, form);
export const deleteCustomer = (companyId, id) => API.delete(`virtual-cards/receive/v1/customer/${id}/?c_id=${companyId}`);

export const getInvoices = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/receive/invoices?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getInvoiceDetails = (companyId, id) => API.get(`virtual-cards/web/v1/receive/invoices/${id}?c_id=${companyId}`);
// export const getInvoiceDetails = (companyId, id) => API.get(`virtual-cards/receive/v1/invoice/${id}/?c_id=${companyId}`);
export const createInvoice = (companyId, form) => API.post(`virtual-cards/web/v1/receive/invoices?c_id=${companyId}`, form);
export const updateInvoice = (companyId, id, form) => API.put(`virtual-cards/web/v1/receive/invoices/${id}?c_id=${companyId}`, form);
export const deleteInvoice = (companyId, id) => API.delete(`virtual-cards/web/v1/receive/invoices/${id}?c_id=${companyId}`);
export const getInvoiceNumberExists = (companyId, invoiceNumber) => API.get(`virtual-cards/web/v1/receive/invoice-number-exists?c_id=${companyId}&invoice_number=${invoiceNumber}`);

export const markAsSentInvoice = (companyId, id) => API.put(`virtual-cards/web/v1/receive/invoice-mark-as-sent/${id}?c_id=${companyId}`);
export const sendInvoice = (companyId, id) => API.put(`virtual-cards/web/v1/receive/invoice-send/${id}?c_id=${companyId}`);
export const createInvoieDuplicate = (companyId, id, form) => API.post(`virtual-cards/web/v1/receive/invoice-duplicate/${id}?c_id=${companyId}`, form);
export const getInvoicePDF = (companyId, id) => API.get(`virtual-cards/web/v1/receive/invoice-pdf/${id}?c_id=${companyId}`);

export const getReceipts = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/receive/receipts?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const updateReceipt = (companyId, id, form) => API.put(`virtual-cards/web/v1/receive/receipts/${id}?c_id=${companyId}`, form);


// ======================== Expense ========================
export const getExpenseCategories = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/expense/categories?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const createExpenseCategory = (companyId, form) => API.post(`virtual-cards/web/v1/expense/categories?c_id=${companyId}`, form);
export const updateExpenseCategory = (companyId, id, form) => API.put(`virtual-cards/web/v1/expense/categories/${id}?c_id=${companyId}`, form);
export const toggleExpenseCategory = (companyId, form) => API.put(`virtual-cards/web/v1/expense/categories/toggle?c_id=${companyId}`, form);
export const toggleExpenseCategoryPaymentMethod = (companyId, form) => API.put(`virtual-cards/web/v1/expense/categories/toggle-payment-method?c_id=${companyId}`, form);
export const toggleAllExpenseCategoryPaymentMethod = (companyId, form) => API.put(`virtual-cards/web/v1/expense/categories/all-toggle-payment-method?c_id=${companyId}`, form);

export const getCostObjects = (companyId) => API.get(`virtual-cards/web/v1/account/cost-objects?c_id=${companyId}`);
export const createCostObject = (companyId, form) => API.post(`virtual-cards/web/v1/account/cost-objects?c_id=${companyId}`, form);
export const updateCostObject = (companyId, id, form) => API.put(`virtual-cards/web/v1/account/cost-objects/${id}?c_id=${companyId}`, form);
export const deleteCostObject = (companyId, id) => API.delete(`virtual-cards/web/v1/account/cost-objects/${id}?c_id=${companyId}`);

export const getCostAccounts = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/account/cost-accounts?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const createCostAccount = (companyId, form) => API.post(`virtual-cards/web/v1/account/cost-accounts?c_id=${companyId}`, form);
export const updateCostAccount = (companyId, id, form) => API.put(`virtual-cards/web/v1/account/cost-accounts/${id}?c_id=${companyId}`, form);
export const deleteCostAccount = (companyId, id) => API.delete(`virtual-cards/web/v1/account/cost-accounts/${id}?c_id=${companyId}`);

export const getExpenseApprovalWorkflows = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/expense/workflows?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const createExpenseApprovalWorkflow = (companyId, form) => API.post(`virtual-cards/web/v1/expense/workflows?c_id=${companyId}`, form);
export const updateExpenseApprovalWorkflow = (companyId, id, form) => API.put(`virtual-cards/web/v1/expense/workflows/${id}?c_id=${companyId}`, form);
export const deleteExpenseApprovalWorkflow = (companyId, id) => API.delete(`virtual-cards/web/v1/expense/workflows/${id}?c_id=${companyId}`);
export const createExpenseApprovalWorkflowMembers = (companyId, id, form) => API.post(`virtual-cards/web/v1/expense/workflows-members/${id}?c_id=${companyId}`, form);
export const deleteExpenseApprovalWorkflowMember = (companyId, groupId, userId) => API.delete(`virtual-cards/web/v1/expense/workflows-members/${groupId}/${userId}?c_id=${companyId}`);


export const getExpenses = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/expense/expenses?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const createExpense = (companyId, form) => API.post(`virtual-cards/web/v1/expense/expenses?c_id=${companyId}`, form);
export const updateExpense = (companyId, id, form) => API.put(`virtual-cards/web/v1/expense/expenses/${id}?c_id=${companyId}`, form);
export const deleteExpense = (companyId, id) => API.delete(`virtual-cards/web/v1/expense/expenses/${id}?c_id=${companyId}`);
export const deleteMultiExpenses = (companyId, form) => API.put(`virtual-cards/web/v1/expense/expenses-multi-delete?c_id=${companyId}`, form);
export const getExpenseMessages = (companyId, id) => API.get(`virtual-cards/web/v1/expense/expense-activities/${id}?c_id=${companyId}`);

export const getExpensesForApprover = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/expense/expense-approvals?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());

export const sendExpenseForApproval = (companyId, form) => API.post(`virtual-cards/web/v1/expense/expense-approvals?c_id=${companyId}`, form);
export const submitExpenseForApproval = (companyId, form) => API.post(`virtual-cards/web/v1/expense/expense-approvals-for-employees?c_id=${companyId}`, form);
export const actOnExpenseForApproval = (companyId, form) => API.post(`virtual-cards/web/v1/expense/expense-approvals-for-approvers?c_id=${companyId}`, form);

export const getReimbursements = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/expense/reimbursements?c_id=${companyId}&page=${page}&page_size=${limit}${query}`, handleRequestCanceller());
export const payReimbursements = (companyId, form) => API.post(`virtual-cards/web/v1/expense/pay-reimbursement?c_id=${companyId}`, form);



// ======================== Wallet ========================
export const getNetworks = (companyId) => API.get(`virtual-cards/wallet/v1/network/?c_id=${companyId}`);
export const getWalletFrequencies = (companyId) => API.get(`virtual-cards/wallet/v1/wallet-frequency/?c_id=${companyId}`);

export const getRealCards = (companyId, page, limit) => API.get(`virtual-cards/wallet/v1/real-card/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const createRealCard = (companyId, form) => API.post(`virtual-cards/wallet/v1/real-card/?c_id=${companyId}`, form);
export const updateRealCard = (companyId, id, form) => API.put(`virtual-cards/wallet/v1/real-card/${id}/?c_id=${companyId}`, form);
export const deleteRealCard = (companyId, id) => API.delete(`virtual-cards/wallet/v1/real-card/${id}/?c_id=${companyId}`);

export const getSpendWallets = (companyId, page, limit) => API.get(`virtual-cards/wallet/v1/spend-wallet/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const createSpendWallet = (companyId, form) => API.post(`virtual-cards/wallet/v1/spend-wallet/?c_id=${companyId}`, form);
export const updateSpendWallet = (companyId, id, form) => API.put(`virtual-cards/wallet/v1/spend-wallet/${id}/?c_id=${companyId}`, form);
export const deleteSpendWallet = (companyId, id) => API.delete(`virtual-cards/wallet/v1/spend-wallet/${id}/?c_id=${companyId}`);

export const getSpendWalletEmployees = (companyId, id) => API.get(`virtual-cards/wallet/v1/spend-wallet-employees/?c_id=${companyId}&ref_spend_wallet_id=${id}`);

export const getEmployeeWallets = (companyId, page, limit) => API.get(`virtual-cards/wallet/v1/employee-wallet/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const createEmployeeWallet = (companyId, form) => API.post(`virtual-cards/wallet/v1/employee-wallet/?c_id=${companyId}`, form);
export const updateEmployeeWallet = (companyId, id, form) => API.put(`virtual-cards/wallet/v1/employee-wallet/${id}/?c_id=${companyId}`, form);
export const deleteEmployeeWallet = (companyId, id) => API.delete(`virtual-cards/wallet/v1/employee-wallet/${id}/?c_id=${companyId}`);

export const getVCN = (companyId, page, limit) => API.get(`virtual-cards/vc/v1/vcn/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const getVCNDetails = (companyId, id) => API.get(`virtual-cards/vc/v1/vcn/${id}/?c_id=${companyId}`);
export const createVCN = (companyId, form) => API.post(`virtual-cards/vc/v1/vcn/?c_id=${companyId}`, form);
export const deleteVCN = (companyId, id) => API.delete(`virtual-cards/vc/v1/vcn/${id}/?c_id=${companyId}`);
export const getVCNCardsOnFile = (companyId, page, limit) => API.get(`virtual-cards/vc/v1/card-on-file/?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const sendCardsOnFile = (companyId, form) => API.post(`virtual-cards/vc/v1/card-on-file-send/?c_id=${companyId}`, form);


// ======================== Billing =============================
export const getBillingPay = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/billing/billing-pay?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getBillingReceive = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/billing/billing-receive?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getBillingExpense = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/billing/billing-expense?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getBillingCrossBorderPayment = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/billing/billing-cross-border-payment?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getPlanBilling = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/billing/plan-billing?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const getAddonBilling = (companyId, page, limit, query) => API.get(`virtual-cards/web/v1/billing/addon-billing?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);


// ======================== Payment page ========================
export const getCompanyFromPaymentHandle = (paymentHandle) => API.get(`virtual-cards/web/v1/account/company-payment-handle/${paymentHandle}`);
export const getInvoiceFromToken = (companyId, token) => API.get(`virtual-cards/web/v1/receive/invoice-from-token/${token}?c_id=${companyId}`);

// test
export const payInvoice = (companyId, form) => API.post(`virtual-cards/web/v1/receive/pay-invoice?c_id=${companyId}`, form);


// ======================== Miscellaneous ========================
export const getEmployeesSearch = (companyId, page, limit) => API.get(`virtual-cards/web/v1/account/employees-list?c_id=${companyId}&page=${page}&page_size=${limit}`);
export const uploadFiles = (form) => API.post(`identity/upload-files/v1`, form);
export const getCurrencies = () => API.get(`virtual-cards/web/v1/currency/?page=1&page_size=500`);

export const getHolidays = (companyId) => API.get(`virtual-cards/web/v1/account/holidays?c_id=${companyId}`);