import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { 
  Box, 
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Menu, 
  MenuItem, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../../components/textFields/SearchTextField';
import useCurrencyService from '../../../../components/hooks/useCurrencyService';
import TableLoading from '../../../../components/loaders/TableLoading';
import NATable from '../../../../components/utils/NATable';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  DeleteProduct,
  GetProducts 
} from '../../../../redux/actions/accounting/productsActions';
import { 
  GetChartOfAccountsForExpenses, 
  GetChartOfAccountsForIncome
} from '../../../../redux/actions/accounting/chartOfAccountsActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import ProductsModal from './ProductsModal';

import global from "../../../../global.scss";
import "./styles.scss";

const ProductsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.accounting);
  const companyId = useSelectedCompany();
  const { handleShowAmount } = useCurrencyService();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [productsModalOpen, setProductsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [productsList, setProductsList] = useState([]);
  const [productsTotal, setProductsTotal] = useState(0);

  const [productsMenuOpen, setProductsMenuOpen] = useState(false);
  const [productsMenuAnchor, setProductsMenuAnchor] = useState(null);
    
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Name",
      sort: "name",
    },
    {
      label: "Description",
      sort: "description",
    },
    {
      label: "Price",
      sort: null,
    },
    {
      label: "Taxes",
      sort: null,
    },
    {
      label: "",
      sort: null,
      align: "right"
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    dispatch(GetChartOfAccountsForIncome(companyId, 1, 1000, "", setLoading2));
    dispatch(GetChartOfAccountsForExpenses(companyId, 1, 1000, "", setLoading2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetProducts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatProductsList(state.productsList.records || []);
      setProductsTotal(state.productsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatProductsList = (list) => {
    setProductsList(list);
  };

  async function handleGetProducts(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetProducts(companyId, page+1, rowsPerPage, 0, query, customLoading ? customLoading : setLoading));
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteProduct(companyId, deleteId, setDeleting)).then(() => {
      handleGetProducts(setDeleting).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <ProductsModal
        open={productsModalOpen}
        setOpen={setProductsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetProducts}
        />

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <Box 
        className="flexCenterSBRow" 
        gap="15px"
        mb={"10px"}>
        <Box></Box>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
          <Button variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Create product");
              setProductsModalOpen(!productsModalOpen);
            }}>
            Add Product
          </Button>
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <Table className='table'>
            <TableHead>
              <TableRow>
                { 
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                productsList.length === 0
                ?
                <NATable
                  label={"No product found"}
                  />
                :
                productsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <Typography
                        className='longText'
                        variant='body2'
                        maxWidth={"350px"}>
                        {row.description || ""}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box
                        className="flexCenter_Row"
                        gap={"10px"}> 
                        {
                          row.associated_selling_accounting_product.length > 0
                          &&
                          <Typography 
                            fontWeight={600}>
                            {handleShowAmount(
                              row.associated_selling_accounting_product[dayjs(row.associated_selling_accounting_product[0].effective_date) > dayjs() ? 1 : 0].currency,
                              row.associated_selling_accounting_product[dayjs(row.associated_selling_accounting_product[0].effective_date) > dayjs() ? 1 : 0].selling_price)}
                          </Typography>
                        }
                        {
                          dayjs(row.associated_selling_accounting_product[0].effective_date) > dayjs()
                          &&
                          <Chip 
                            size='small'
                            color='info'
                            label={
                              <>
                                {handleShowAmount(
                                  row.associated_selling_accounting_product[0].currency,
                                  row.associated_selling_accounting_product[0].selling_price)}
                                &nbsp;from&nbsp;
                                {dayjs(row.associated_selling_accounting_product[0].effective_date).format("MMM DD, YYYY")}
                              </>
                            }
                            />
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      {
                        row.associated_taxes_accounting_product.length === 0
                        ?
                          <Typography 
                            variant='caption' 
                            color={"grey"}>
                            No tax found
                          </Typography>
                        :
                          row.associated_taxes_accounting_product.map((tax, taxIndex) => (
                            <Chip
                              key={taxIndex}
                              size='small'
                              label={tax?.ref_sales_tax_id?.abbreviation}
                              sx={{ marginRight: "5px" }}
                              />
                          ))
                      }
                    </TableCell>
                    <TableCell
                      align='right'>
                      {
                        row.product_id === deleteId && deleting
                        ?
                        <CircularProgress size={26} sx={{ margin: "7px" }}/>
                        :
                        <IconButton
                          onClick={(e) => {
                            setProductsMenuAnchor(e.currentTarget);
                            setProductsMenuOpen(true);
                            setDeleteId(row.product_id);
                            setUpdate(row);
                            setModalTitle(row?.name || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      } 
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={productsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={productsMenuOpen}
        anchorEl={productsMenuAnchor}
        onClose={() => setProductsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setProductsMenuOpen(false);
            setProductsModalOpen(!productsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setProductsMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default ProductsPage;