import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Chip,
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../components/textFields/SearchTextField';
import useCurrencyService from '../../../components/hooks/useCurrencyService';
import TableLoading from '../../../components/loaders/TableLoading';
import NATable from '../../../components/utils/NATable';
import NA from '../../../components/utils/NA';

import { 
  GetCustomers 
} from '../../../redux/actions/receive/customersActions';

import "./styles.scss";

const CustomersPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.receive);
  const companyId = useSelectedCompany();
  const { handleShowAmount } = useCurrencyService();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const [customersList, setCustomersList] = useState([]);
  const [customersTotal, setCustomersTotal] = useState(0);
    
  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Name",
      sort: "name",
    },
    {
      label: "Email",
      sort: "email",
    },
    {
      label: "Phone",
      sort: "phone",
    },
    {
      label: "Balance",
      sort: "balance",
      align: "right"
    },
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetCustomers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatCustomersList(state.customersList.records || []);
      setCustomersTotal(state.customersList.total || 0);
    } catch (err) {}
  }, [state]);

  // ---------------- list formatter ----------------
  const formatCustomersList = (list) => {
    setCustomersList(list);
  };

  async function handleGetCustomers(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetCustomers(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  return (
    <Box>

      <Box 
        className="flexCenterSBRow" 
        gap="15px"
        mb={"10px"}>
        <Box></Box>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <Table className='table'>
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                customersList.length === 0
                ?
                <NATable
                  label={"No customer found"}
                  />
                :
                customersList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Box>
                        <Typography variant='subtitle2'>{row.contact_name || <NA />}</Typography>
                      </Box>
                      <Typography color={"grey"}>{row.name}</Typography>
                    </TableCell>  
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell align='right'>
                      {
                        row.balance === 0
                        ?
                        <Typography fontWeight={600}>
                          {row.overdue > 0 && handleShowAmount("USD", row.overdue)}
                        </Typography>
                        :
                        <Typography fontWeight={600}>
                          {row.balance > 0 && handleShowAmount("USD", row.balance)}
                        </Typography>
                      }
                      {
                        row.overdue === 0 
                        ?
                        <Typography fontWeight={600}>
                         $0.00
                        </Typography>
                        :
                        <Box
                          className="flexCenterFERow">
                          <Typography color={"error"} fontWeight={600}>
                            {handleShowAmount("USD", row.overdue)}&nbsp;
                          </Typography>
                          <Chip
                            size={"small"}
                            color='error'
                            label={"Overdue"}
                            />
                        </Box>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, ]} 
                  colSpan={10}
                  count={customersTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default CustomersPage;