import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ModalTopBar from '../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../components/buttons/Button';

import { stateList } from '../../../components/utils/usStateCodeList';

import { 
  GetCompanies,
  CreateCompany 
} from '../../../redux/actions/dashboard/companiesActions';

import dayjs from 'dayjs';

const taxIdTypeList = [
  { label: "tin", value: "tin" },
  { label: "ein", value: "ein" }
];

const Data = {
  LegalName: "",
  TradingName: "",
  Address: "",
  City: "",
  State: "",
  Country: "US",
  Pincode: "",
  TaxIdType: "",
  TaxId: "",
  IncorporationDate: null
};

const CompaniesModal = ({
  open, 
  setOpen, 
  update
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(-1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open){
      if(update.defaultMode === 1){
        // mode = 1 - create company
        setMode(1);
      } else if (update.defaultMode === 2){

      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (e) => {
    if(e.target.name === "Pincode") {
      setFormData({ ...formData, [e.target.name]: e.target.value.slice(0, 5) });
    } else if(e.target.name === "TaxId") {
      setFormData({ ...formData, [e.target.name]: e.target.value.slice(0, 9) });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 - create company
        let obj = {
          "legal_name": formData.LegalName || "",
          "address": formData.Address || "",
          "city": formData.City || "",
          "state": formData.State || "",
          "country": formData.Country || "",
          "pincode": formData.Pincode || "",
          "tax_id": formData.TaxId || "",
          "tax_id_type": formData.TaxIdType || "",
          "incorporation_date": dayjs(formData.IncorporationDate).format("YYYY-MM-DD") || ""
        };

        dispatch(CreateCompany(obj, setLoading)).then(() => {
          dispatch(GetCompanies(1, 500, "", setLoading)).then(() => {
            handleClose();
          })
        });
      } else {
        toast.error("Something went wrong");
        handleClose()
      }
    } catch (err){
      console.log(err)
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className="fullpage__modal animate__animated animate__fadeIn">
        
        <ModalTopBar 
          onBack={handleClose}
          title={"Details for new company"}
          />

        <Container 
          maxWidth="sm">
          <Box
            className="flex__Column"
            gap={"15px"}
            pt="20px">

            <TextField 
              label="Company name *"
              fullWidth
              name="LegalName"
              value={formData.LegalName}
              onChange={handleChange}
              inputProps={{
                maxLength: 128
              }}
              />
            <TextField 
              label="Company address *"
              fullWidth
              name="Address"
              value={formData.Address}
              onChange={handleChange}
              inputProps={{
                maxLength: 256
              }}
              />
            <Box
              className="flexCenter_Row"
              gap="15px">
              <TextField 
                label="City *"
                fullWidth
                name="City"
                value={formData.City}
                onChange={handleChange}
                inputProps={{
                  maxLength: 128
                }}
                />
              <TextField 
                select
                label="State *"
                fullWidth
                name="State"
                value={formData.State}
                onChange={handleChange}
                inputProps={{
                  maxLength: 128
                }}
                >
                {
                  stateList.map((state, stateIndex) => (
                    <MenuItem
                      key={stateIndex}
                      value={state.code}>
                      <Typography>
                        {state.name}
                      </Typography>
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box
              className="flexCenter_Row"
              gap="15px">
              <TextField 
                label="Country *"
                fullWidth
                name="Country"
                disabled
                value={formData.Country}
                onChange={handleChange}
                inputProps={{
                  maxLength: 128
                }}
                />
              <TextField 
                label="Postal code *"
                fullWidth
                type='number'
                name="Pincode"
                value={formData.Pincode}
                onChange={handleChange}
                />
            </Box>

            <Typography
              mt={"20px"}
              mb={"10px"}
              variant='substile1'>
              Tax Information
            </Typography>

            <Box
              className="flexCenter_Row"
              gap="15px">
              <TextField 
                select
                label="Tax Id Type *"
                fullWidth
                name="TaxIdType"
                value={formData.TaxIdType}
                onChange={handleChange}>
                {
                  taxIdTypeList.map((taxIdType, taxIdTypeIndex) => (
                    <MenuItem
                      key={taxIdTypeIndex}
                      value={taxIdType.value}>
                      <Typography>
                        {taxIdType.label}
                      </Typography>
                    </MenuItem>
                  ))
                }
              </TextField>
              <TextField 
                label="Tax Id *"
                fullWidth
                type='number'
                name="TaxId"
                value={formData.TaxId}
                onChange={handleChange}
                inputProps={{
                  maxLength: 9
                }}
                />
            </Box>
            <Box
              className="flexCenter_Row"
              gap="15px">
              <Box
                width={"50%"}>
                <LocalizationProvider 
                  dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ 
                      textField: { 
                        fullWidth: true,
                        // width: "400px"
                      } 
                    }}
                    label="Incorporation date" 
                    name="IncorporationDate"
                    value={formData.IncorporationDate || null}
                    maxDate={dayjs()}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: "IncorporationDate",
                          value: e
                        }
                      })
                    }}/>
                </LocalizationProvider>
              </Box>
              <Box  
                width={"50%"}>
              </Box>
            </Box>

            <Box
              className="flexCenterFERow"
              gap={"15px"}>
              <LoadingButton
                variant="contained"
                disabled={!(formData.LegalName !== "" && formData.Address !== "" &&
                  formData.City !== "" && formData.State !== "" &&
                  formData.Country !== "" && formData.Pincode !== ""
                )}
                onClick={handleSubmit}
                loading={loading}>
                Create
              </LoadingButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </Modal>
  )
}

export default CompaniesModal;