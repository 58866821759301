import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button,
  CircularProgress,
  IconButton,
  Menu, 
  MenuItem, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../../components/textFields/SearchTextField';
import TableLoading from '../../../../components/loaders/TableLoading';
import NATable from '../../../../components/utils/NATable';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  DeleteCostAccount,
  GetCostAccounts 
} from '../../../../redux/actions/expense/costAccountsActions';
import { 
  GetCostObjects 
} from '../../../../redux/actions/expense/costObjectsActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import CostAccountsModal from './CostAccountsModal';

import global from "../../../../global.scss";
import "./styles.scss";

const CostAccountsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.expense);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [costAccountsModalOpen, setCostAccountsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [costAccountsList, setCostAccountsList] = useState([]);
  const [costAccountsTotal, setCostAccountsTotal] = useState(0);

  const [costAccountMenuOpen, setCostAccountMenuOpen] = useState(false);
  const [costAccountMenuAnchor, setCostAccountMenuAnchor] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Cost object",
      sort: "ref_cost_object_id",
    },
    {
      label: "Cost account",
      sort: "name",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    dispatch(GetCostObjects(companyId, 1, 1000, setLoading2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    handleGetCostAccounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatCostAccountsList(state.costAccountsList.records || []);
      setCostAccountsTotal(state.costAccountsList.total || 0);
    } catch (err) {}
  }, [state]);

  // ----------------- list formatter -----------------
  const formatCostAccountsList = (list) => {
    setCostAccountsList(list);
  };

  async function handleGetCostAccounts(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetCostAccounts(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteCostAccount(companyId, deleteId, setDeleting)).then(() => {
      handleGetCostAccounts(setDeleting).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <CostAccountsModal 
        open={costAccountsModalOpen}
        setOpen={setCostAccountsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetCostAccounts}
        />

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <Box 
        className="flexCenterSBRow" 
        mb={"15px"}>
        <Typography variant='subtitle2'>
          Add cost accounts for charging expenses
        </Typography>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
          <Button variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Create cost account");
              setCostAccountsModalOpen(!costAccountsModalOpen);
            }}>
            Add Cost Account
          </Button>
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <Table className='costacc'>
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                costAccountsList.length === 0
                ?
                <NATable
                   label={"No cost account found"}
                   />
                :
                costAccountsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>{row?.ref_cost_object_id?.name || "NA"}</TableCell>
                    <TableCell>{row?.name || ""}</TableCell>
                    <TableCell>
                      {
                        row.id === deleteId && deleting
                        ?
                        <CircularProgress size={26} sx={{ margin: "7px" }}/>
                        :
                        <IconButton
                          onClick={(e) => {
                            setCostAccountMenuAnchor(e.currentTarget);
                            setCostAccountMenuOpen(true);
                            setDeleteId(row.id);
                            setUpdate(row);
                            setModalTitle(row.name || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={costAccountsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={costAccountMenuOpen}
        anchorEl={costAccountMenuAnchor}
        onClose={() => setCostAccountMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setCostAccountMenuOpen(false);
            setCostAccountsModalOpen(!costAccountsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCostAccountMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default CostAccountsPage;