import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { 
  Box,
  Link,
  Container, 
  TextField, 
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';

import Logo from "../../assets/icons/logo-blue-dark2.svg";
import LoadingButton from '../../components/buttons/Button';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { 
  LogIn 
} from '../../redux/actions/authActions';

import { 
  CLEAR_COMPANY_ROLE_DASHBOARD, 
  LOG_OUT 
} from '../../redux/actionTypes';

import style from "../../global.scss";
import "./styles.scss";

const Data = {
  UserName: "",
  Password: ""
};

const AuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);

  const [passwordShow, setPasswordShow] = useState(false);

  useEffect(() => {
    try {
      dispatch({ type: CLEAR_COMPANY_ROLE_DASHBOARD });
    } catch (err){
      dispatch({ type: LOG_OUT });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = () => {
    let obj = {
      "user_name": formData.UserName,
      "password": formData.Password
    };

    dispatch(LogIn(obj, navigate, setLoading)).then(({res, statusCode}) => {
      if(statusCode === 200){
        if(location.state){
          // login from `make payment page`
          navigate(location.state.redirectPath, { 
            replace: true,
            state: location.state
           });
        } else {
          // regular login
          navigate("/dashboard");
        }
      }
      setFormData(Data);
    })
  };

  return (
    <Box >
      <Box className='auth__top'>
        <Container maxWidth="lg">
          <Box className='flexCenterSBRow'>
            <img
              src={Logo}
              alt="lusid-logo"
              style={{ width: "140px" }}
              />
            <Typography variant='h5' sx={{ color: style["secondary"] }}>
              {"Business"}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box className="auth flexCenterCenterRow" marginTop={"-40px"}>
          <Box className="auth__content flexCenterCenterRow" 
            p={"25px"}>
            <Box 
              className="flex_CenterColumn" 
              gap={"20px"} 
              width={"360px"}>
              <Typography
                variant='h6'
                textAlign={"center"}>
                Login into your account
              </Typography>
              <TextField 
                variant="outlined"
                label="Email"
                name="UserName"
                fullWidth={true}
                placeholder='Enter your email'
                value={formData.UserName}
                onChange={handleChange}
                />
              <TextField 
                variant="outlined"
                label="Password"
                name="Password"
                type={passwordShow ? "text" : "password"}
                fullWidth
                placeholder='Enter password'
                value={formData.Password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: 
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setPasswordShow(!passwordShow);
                        }}>
                      {
                        passwordShow
                        ? <VisibilityOffOutlinedIcon />   
                        : <VisibilityOutlinedIcon />
                      }
                      </IconButton>
                    </InputAdornment>,
                }}
                />
              <Link
                onClick={() => navigate("/forgot-password")}>
                Forgot password?
              </Link>
              <LoadingButton
                variant='contained' 
                loading={loading}
                onClick={handleLogin}>
                Login
              </LoadingButton>
              {/* {
                !location.state
                &&
                <Button
                  variant='text'
                  onClick={() => navigate("/register")}>
                  Create new account
                </Button>
              } */}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default AuthPage;