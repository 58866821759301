import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Avatar,
  Box, 
  Button, 
  IconButton,
  Typography
} from '@mui/material';

import CompanyAvatar from '../avatars/CompanyAvatar';
import ProfileMenu from '../menus/ProfileMenu';
import CompaniesMenu from '../menus/CompaniesMenu';

import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

import { 
  LOG_OUT, 
  SELECT_COMPANY,
} from '../../redux/actionTypes';

import ConfirmationModal from '../modals/ConfirmationModal';

import global from "../../global.scss";
import "./styles.scss";

const TopNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector(state => state.dashboard);
  const other = useSelector(state => state.other);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [companiesMenuOpen, setCompaniesMenuOpen] = useState(false);
  const [companiesMenuAnchor, setCompaniesMenuAnchor] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [profile, setProfile] = useState({});
  const [companiesList, setCompaniesList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    try {
      console.log(state)
      formatProfileObj(state.myProfile || {});
      formatCompaniesList(state.companiesList.records  || []);
    } catch (error) {
      console.log("ERR_TOP_NAV_1", error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    try { 
      formatSelectedCompany(other.selectedCompany || null);
    } catch (error) {
      console.log("ERR_TOP_NAV_2", error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other]);

  // ----------------- obj formatter -----------------
  const formatProfileObj = (obj) => {  
    setProfile(obj);
  };

  const formatCompaniesList = (list) => {
    // if(list.length > 0){
    //   dispatch({ type: SELECT_COMPANY, payload: list[0] });
    // }

    // console.log(list);
    setCompaniesList(list);
  };

  const formatSelectedCompany = (obj) => {
    setSelectedCompany(obj);
  };

  const handleLogout = () => {
    navigate("/");
    dispatch({ type: LOG_OUT });
  };

  const handleSelectCompany = (e) => {
    navigate("/switch", { state: location.pathname });
    dispatch({ type: SELECT_COMPANY, payload: e });
  };

  return (
    <Box
      className="nav__top flexCenterSBRow">
      <ProfileMenu
        open={profileMenuOpen}
        anchor={profileMenuAnchor} 
        profile={profile}
        onClose={() => {
          setProfileMenuOpen(false);
        }}
        navigate={navigate}
        onLogout={() => setConfirmationModalOpen(true)}
        />

      <CompaniesMenu
        open={companiesMenuOpen}
        anchor={companiesMenuAnchor} 
        companiesList={companiesList}
        selectedCompany={selectedCompany}
        handleSelectCompany={handleSelectCompany}
        navigateSettings={(path) => navigate(path)}
        onClose={() => {
          setCompaniesMenuOpen(false);
        }}
        />

      <ConfirmationModal
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        title={"Log out"}
        onCancel={() => setConfirmationModalOpen(false)}
        onConfirm={handleLogout}
        />

      <Box></Box>

      <Box 
        className="flexCenter_Row" 
        gap={"10px"}>
        {
          (location.pathname !== "/dashboard" && location.pathname !== "/profile")
          &&
          <Button
            variant='outlined'
            sx={{ width: "250px", paddingRight: "5px", paddingLeft: "5px" }}
            onClick={(e) => {
              setCompaniesMenuAnchor(e.currentTarget);
              setCompaniesMenuOpen(true);
            }}>
            <Box 
              className="flexCenterSBRow" 
              width={"100%"}>
              <Box
                className="flexCenter_Row"
                gap="10px">
                {
                  selectedCompany?.LogoUrl
                  ?
                  <Box
                    sx={{ 
                      width: "24px", 
                      height: "24px", 
                      borderRadius: "8px" ,
                      overflow: "hidden",
                      border: "solid 1px #f1f1f1" }}>
                      <img 
                         src={selectedCompany?.LogoUrl}
                        alt='company-logo'
                        style={{
                          width: "24px", 
                          height: "24px", 
                          objectFit: "contain"
                        }}
                        />
                  </Box>
                  :
                  <CompanyAvatar
                    color={selectedCompany?.Color}
                    size="small">
                    {selectedCompany?.LegalName || ""}
                  </CompanyAvatar>
                }
                <Typography 
                  variant='body1'
                  className='longText'
                  sx={{ maxWidth: "180px" }}
                  fontWeight={500}>
                  {selectedCompany?.LegalName || ""}
                </Typography>
              </Box>
              <ExpandLessOutlinedIcon 
                sx={{ 
                  right: "10px",
                  transition: "all .2s",
                  transform: companiesMenuOpen ? "rotate(0deg)" : "rotate(180deg)",
                }}/>
            </Box>
          </Button>
        }
        <Box 
          className="flexCenter_Row nav__top-profile"
          onClick={(e) => {
            setProfileMenuAnchor(e.currentTarget);
            setProfileMenuOpen(true);
          }}>
          <IconButton>
            <Avatar
              sx={{ backgroundColor: global["secondary"] }}>
              <Typography
                color={"white"}>
                {"first_name" in profile && profile?.first_name[0]?.toUpperCase()}
                {"last_name" in profile && profile?.last_name[0]?.toUpperCase()}
              </Typography>
            </Avatar>
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default TopNavigation;