import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../components/textFields/SearchTextField';
import useCurrencyService from '../../../components/hooks/useCurrencyService';
import CompanyAvatar from '../../../components/avatars/CompanyAvatar';
import DateFormatter from '../../../components/utils/DateFormatter';
import TableLoading from '../../../components/loaders/TableLoading';
import NATable from '../../../components/utils/NATable';
import NA from '../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
    GetReceipts 
} from '../../../redux/actions/receive/receiptsActions';

import PaymentsReceivedModal from './PaymentsReceivedModal';

import global from "../../../global.scss";
import "./styles.scss";

const PaymentsReceivedPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.receive);
  const companyId = useSelectedCompany();
  const { handleShowAmount } = useCurrencyService();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  // eslint-disable-next-line no-unused-vars
  const [statusFilter, setStatusFilter] = useState("");
  const [paymentReceivedModalOpen, setPaymentReceivedModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [receiptsList, setReceiptsList] = useState([]);
  const [receiptsTotal, setReceiptsTotal] = useState(0);

  const [receiptsMenuOpen, setReceiptsMenuOpen] = useState(false);
  const [receiptsMenuAnchor, setReceiptsMenuAnchor] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Amount",
      sort: "amount",
    },
    {
      label: "Receive date",
      sort: "debit_date",
    },
    {
      label: "Receive from",
      sort: "ref_payer_user_id",
    },
    {
      label: "Invoice",
      sort: "ref_invoice_id",
    },
    {
      label: "Invoice total",
      sort: null,
      align: "right",
      sx: { paddingRight: "5vw", width: "200px" }
    },
    {
      label: "Status",
      sort: "status",      
    },
    {
      label: "",
      sort: null,      
    },
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetReceipts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection, statusFilter]);

  useEffect(() => {
    try {
      formatReceiptsList(state.receiptsList.records || []);
      setReceiptsTotal(state.receiptsList.total || 0);
    } catch (err) {}
  }, [state]);

  // ---------------- list formatter ---------------- 
  const formatReceiptsList = (list) => {
    setReceiptsList(list);
  }; 

  async function handleGetReceipts(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    if(statusFilter) query += `&status__in=${statusFilter}`;
    await dispatch(GetReceipts(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const getReceiptChipColor = (status) => {
    switch(status){
      case "Scheduled": return "info";
      case "Paid": return "success";
      case "Failed": return "error";

      default: return "default";
    }
  };

  return (
    <Box>

      <PaymentsReceivedModal
        open={paymentReceivedModalOpen}
        setOpen={setPaymentReceivedModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetReceipts}
        />

      <Box 
        className="flexCenterSBRow" 
        gap="15px"
        mb={"10px"}>
        <Box  
          className="flexCenter_Row"
          gap="15px">

        </Box>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <Table className='table'>
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      sx={heading.sx}
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      sx={heading.sx}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                receiptsList.length === 0
                ?
                <NATable
                  label={"No receipt found"}
                  />
                :
                receiptsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        fontWeight={600}>
                        {handleShowAmount(row?.currency, row?.amount || 0)}
                      </Typography>
                    </TableCell>
                    <TableCell>{row?.debit_date &&  DateFormatter(row.debit_date)}</TableCell>
                    <TableCell>
                      {
                        row?.paid_by_guest === true
                        ?
                        // guest
                        <Tooltip
                          title={"Guest user"}>
                          <Chip
                            size='small'
                            color='warning'
                            label={row?.ref_payer_user_id?.employee_email || <NA />} 
                            />
                        </Tooltip>
                        :
                        // lusid client
                        <Tooltip
                          title={
                            <Box
                              className="flexCenter_Row"
                              sx={{ padding: "5px" }}>
                              <Typography
                                color={"white"}
                                variant='caption'>
                                Paid by <b>{row?.ref_payer_user_id?.employee_email}</b> from {row?.ref_payer_user_id?.company_id?.company_name}
                              </Typography>
                            </Box>}>
                          <Box
                            className="flexCenter_Row"
                            gap="10px">
                            {
                              row?.ref_payer_user_id?.company_id?.logo_url
                              ?
                              <Box
                                sx={{ 
                                  width: "24px", 
                                  height: "24px", 
                                  borderRadius: "8px" ,
                                  overflow: "hidden",
                                  border: "solid 1px #f1f1f1" }}>
                                  <img 
                                    src={row?.ref_payer_user_id?.company_id?.logo_url}
                                    alt='company-logo'
                                    style={{
                                      width: "24px", 
                                      height: "24px", 
                                      objectFit: "contain"
                                    }}
                                    />
                              </Box>
                              :
                              <CompanyAvatar
                                color={row?.ref_payer_user_id?.company_id?.color}
                                size="small">
                                {row?.ref_payer_user_id?.company_id?.company_name || ""}
                              </CompanyAvatar>
                            }
                            <Typography 
                              variant='body1'
                              className='longText'
                              sx={{ maxWidth: "180px" }}
                              fontWeight={500}>
                              {row?.ref_payer_user_id?.company_id?.company_name || ""}
                            </Typography>
                          </Box>
                        </Tooltip>
                      }
                    </TableCell>
                    <TableCell>
                      <Box
                        className="flex__Column">
                        <Typography
                          fontWeight={500}>
                          {
                            row.ref_invoice_id
                            ?
                              row?.ref_invoice_id?.invoice_number 
                              ? "#" + row?.ref_invoice_id?.invoice_number
                              : <NA />
                            : <NA />
                          }
                        </Typography>
                        <Typography
                          variant='caption'>
                          {
                            row.ref_invoice_id 
                            ? row?.ref_invoice_id?.ref_customer_id?.name 
                            : <></>
                          }
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{ paddingRight: "5vw", width: "200px" }}>
                      <Typography
                        fontWeight={600}>
                        {row.ref_invoice_id ? handleShowAmount(row?.currency, row?.ref_invoice_id.total_amount || 0) : <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        size='small'
                        color={getReceiptChipColor(row.status)}
                        label={row.status || <NA />} 
                        />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setUpdate(row);
                          setModalTitle("Edit invoice number");
                          setReceiptsMenuAnchor(e.currentTarget);
                          setReceiptsMenuOpen(true);
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={receiptsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={receiptsMenuOpen}
        anchorEl={receiptsMenuAnchor}
        onClose={() => setReceiptsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setUpdate({
              ...update, 
              defaultMode: 1
            });
            setReceiptsMenuOpen(false);
            setPaymentReceivedModalOpen(!paymentReceivedModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default PaymentsReceivedPage;