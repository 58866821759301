import { useSelector } from 'react-redux';

const useCompanyRole = () => {
    const state = useSelector(state => state.other);

    if(!("companyRole" in state && (state.companyRole && "current_role" in state.companyRole))){
        console.log("LOGOUT FROM USE COMPANY ROLE")
    }   

    return (
        state.companyRole 
        ? state.companyRole.current_role  
        : null
    )
}

export default useCompanyRole;