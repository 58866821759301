import * as actionTypes from "../actionTypes.js";

const initialState = {
  // vendors
  vendorsList: null, 
  vendorDetails: null,
  vendorAccounts: null, 

  bankAccountTypesList: null, 
  banksList: null, 
  companyBankAccountsList: null,
  companyBankAccountDetails: null,

  // bills
  billsList: null,
  paymentFrequenciesList: null,
  
  // pay approval workflow
  approvalWorkflowsList: null,
  
  // payments
  paymentsList: null, 
  payment: null, 
  paymentFeesList: null, 
  servicesForProductList: null,
  servicesForProductForGuestList: null,
  paymentActivitiesList: null,

  // pay approvals
  paymentsListForApprover: null,
};

const payReducer = (state = initialState, action) => {
  switch (action.type) {
    //vendors
    case actionTypes.GET_VENDORS_PAY:
      return { ...state, vendorsList: action.payload };
    case actionTypes.GET_VENDOR_DETAILS_PAY:
      return { ...state, vendorDetails: action.payload };
    case actionTypes.CLEAR_VENDOR_DETAILS_PAY:
      return { ...state, vendorDetails: null };
    case actionTypes.GET_VENDOR_ACCOUNTS_PAY:
      return { ...state, vendorAccounts: action.payload };
    case actionTypes.CLEAR_VENDOR_ACCOUNTS_PAY:
      return { ...state, vendorAccounts: null };

    case actionTypes.GET_BANK_ACCOUNTS_TYPES_PAY:
      return { ...state, bankAccountTypesList: action.payload };
    case actionTypes.GET_BANKS_PAY:
      return { ...state, banksList: action.payload };
    case actionTypes.GET_COMPANY_BANK_ACCOUNTS_PAY:
      return { ...state, companyBankAccountsList: action.payload };
    case actionTypes.GET_COMPANY_BANK_ACCOUNT_DETAILS_PAY:
      return { ...state, companyBankAccountDetails: action.payload };
    case actionTypes.CLEAR_COMPANY_BANK_ACCOUNT_DETAILS_PAY:
      return { ...state, companyBankAccountDetails: null };    

    // bills
    case actionTypes.GET_BILLS_PAY:
      return { ...state, billsList: action.payload };  
    case actionTypes.GET_PAYMENT_FREQUENCIES_PAY:
      return { ...state, paymentFrequenciesList: action.payload };
        
    // pay approval workflow
    case actionTypes.GET_APPROVAL_WORKFLOWS_PAY:
      return { ...state, approvalWorkflowsList: action.payload };
          
    // payments
    case actionTypes.GET_PAYMENTS_PAY:
      return { ...state, paymentsList: action.payload };
    case actionTypes.GET_PAYMENT_PAY:
      return { ...state, payment: action.payload };
    case actionTypes.GET_PAYMENT_FEES_PAY:
      return { ...state, paymentFeesList: action.payload };
    case actionTypes.GET_SERVICES_FOR_PRODUCT_PAY:
      return { ...state, servicesForProductList: action.payload };
    case actionTypes.GET_SERVICES_FOR_PRODUCT_FOR_GUEST_PAY:
      return { ...state, servicesForProductForGuestList: action.payload };
    case actionTypes.GET_PAYMENT_ACTIVITIES_PAY:
      return { ...state, paymentActivitiesList: action.payload };
      
    // pay approvals
    case actionTypes.GET_PAYMENTS_FOR_APPROVER_PAY:
      return { ...state, paymentsListForApprover: action.payload };
    

    case actionTypes.SELECT_COMPANY:
      return initialState;
    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default payReducer;