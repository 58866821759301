import * as actionTypes from "../actionTypes.js";

const initialState = {
  isAuthenticated: false,
  user: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOG_IN:
      localStorage.setItem("user", JSON.stringify(action.payload.access_token));
      localStorage.setItem("refresh_token", JSON.stringify(action.payload.refresh_token));
      return { 
        ...state, 
        isAuthenticated: true,
        user: action.payload.access_token
      };
    case actionTypes.REGISTER:
      localStorage.setItem("user", JSON.stringify(action.payload.access_token));
      return { 
        ...state, 
        user: action.payload.access_token,
        isAuthenticated: true 
      };
    case actionTypes.REFRESH_TOKEN:
      localStorage.setItem("user", JSON.stringify(action.payload.access_token));
      return { 
        ...state, 
        isAuthenticated: true,
        user: action.payload.access_token
      };

    case actionTypes.LOG_OUT:
      localStorage.clear();
      return initialState;
      
    default:
      return state;
  }
};

export default authReducer;