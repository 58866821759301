import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box
} from '@mui/material';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import { 
  CLEAR_IMAGES_URL, 
  CLOSE_IMAGE_VIEWER 
} from '../../redux/actionTypes';

const ImageViewer = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.other);
  const [open, setOpen] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [imagesList, setImagesList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setImagesList([]);
    dispatch({ type: CLOSE_IMAGE_VIEWER });
    dispatch({ type: CLEAR_IMAGES_URL });
  };

  useEffect(() => {
    try {
      // console.log(state)
      if(state.imageViewerOpen === true) formatImages(state.imagesList);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const formatImages = (list) => {
    console.log("asdf")
    setCurrentPosition(0);
    setOpen(true);
    setImagesList(list);
  }

  const handleNextImage = (e) => {
    e.stopPropagation();
    setCurrentPosition(currentPosition + 1);
  };

  const handlePrevImage = (e) => {
    e.stopPropagation();
    setCurrentPosition(currentPosition - 1);
  };

  return (
    open
    ?
    <Box
      sx={{ 
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        zIndex: "100",
        background: "rgba(0,0,0,0.4)"
      }}
      onClick={handleClose}>

      <Box
        color='white'
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          borderRadius: "50%",
          position: "absolute",
          top: 40,
          right: 40,
          backgroundColor: "lightgrey",
          cursor: "pointer"
        }}
        onClick={handleClose}>
        <CloseRoundedIcon 
          fontSize='large'
          sx={{ color: "black" }}/>
      </Box>

      {
        currentPosition > 0 
        &&
        <Box
          color='white'
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            borderRadius: "50%",
            position: "absolute",
            top: "50%",
            left: 40,
            backgroundColor: "lightgrey",
            cursor: "pointer"
          }}
          onClick={handlePrevImage}>
          <ArrowBackRoundedIcon
            fontSize='large'
            sx={{ color: "black" }}/>
        </Box>
      }

      {
        currentPosition < imagesList.length - 1
        &&
        <Box
          color='white'
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            borderRadius: "50%",
            position: "absolute",
            top: "50%",
            right: 40,
            backgroundColor: "lightgrey",
            cursor: "pointer"
          }}
          onClick={handleNextImage}>
          <ArrowForwardRoundedIcon
            fontSize='large'
            sx={{ color: "black" }}/>
        </Box>
      }
    
      {
        imagesList[currentPosition].split(".pdf").length === 2 && imagesList[currentPosition].split(".pdf")[1] === ""
        ?
        <iframe
          src={imagesList[currentPosition]}
          title="PDF Viewer"
          style={{
            width: "70%",
            height: "70%"
          }}
        ></iframe>
        :
        <img
          src={imagesList[currentPosition]}
          alt='image-viewer'
          style={{
            // width: "50%",
            height: "70%"
          }}
          />
      }
      
    </Box>
    : 
    <></>
  )
}

export default ImageViewer;