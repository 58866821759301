import * as actionTypes from "../actionTypes.js";

const initialState = {
  billsStats: null,
  invoicesStats: null,
  expenseStats: null
};

const analyticsRedcuer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BILLS_STATS_ANA:
      return { ...state, billsStats: action.payload };  
    case actionTypes.GET_INVOICES_STATS_ANA:
      return { ...state, invoicesStats: action.payload };  
    case actionTypes.GET_EXPENSE_STATS_ANA:
      return { ...state, expenseStats: action.payload };  

    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default analyticsRedcuer;