import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from "react-router-dom";

const useAuth = (user) => {
    if (user.isAuthenticated) {
        return true;
    } else {
        return false;
    }
};

const PublicRoutes = (props) => {
    const state = useSelector(state => state.auth);
    const auth = useAuth(state);
    const location = useLocation();

    useEffect(() => {}, [location]);

    return auth ? !location.state && <Navigate to="/dashboard" /> : <Outlet />;
};

export default PublicRoutes;