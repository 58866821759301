import React from 'react';

import { 
  Tab, 
  Tabs 
} from '@mui/material';

const TabsPanel = ({
  value, 
  handleChange,
  links
}) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
    >
      {
        links.map((link, linkIndex) => (
          <Tab 
            key={linkIndex}
            value={link.value} 
            label={link.label}
            />
        ))
      }
    </Tabs>
  )
}

export default TabsPanel;