import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../components/topBar/TopBar';
import TabsPanel from '../../components/tabs/TabsPanel';
import CustomersPage from './customersPage/CustomersPage';
import InvoicesPage from './invoicesPage/InvoicesPage';
import PaymentsReceivedPage from './paymentsReceivedPage/PaymentsReceivedPage';

const GetPaidIndex = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(localStorage.getItem("receiveTab"));

  useEffect(() => {
    if("child" in params){
      switch (params.child) {
        case "customers":
          localStorage.setItem("receiveTab", "1");  
          setCurrentTab("1"); 
          break;
        case "invoices": 
          localStorage.setItem("receiveTab", "2");  
          setCurrentTab("2"); 
          break;
        case "payments-received": 
          localStorage.setItem("receiveTab", "3");  
          setCurrentTab("3"); 
          break;

        default: {
          localStorage.setItem("receiveTab", "1");
          setCurrentTab("1");
          navigate("/get-paid");
        }
      }
      navigate("/get-paid")
    }else{
      if(!localStorage.getItem("receiveTab")){
        localStorage.setItem("receiveTab", "1");
        setCurrentTab("1");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TopBar
        title="Get Paid"
        />
      
      <Box className="flexCenterSBRow" mb={"10px"}>
        <TabsPanel
          value={currentTab+""}
          handleChange={(e, value) => {
            localStorage.setItem("receiveTab", value);
            setCurrentTab(value);
          }}
          links={[
            { value: "1", label: "Customers", path: "/customers" },
            { value: "2", label: "Invoices", path: "/invoices" },
            { value: "3", label: "Payments received", path: "/payments-received" }
          ]}
          />
      </Box>
      {
        currentTab === "1"
        ?
          <CustomersPage />
          :
            currentTab === "2"
            ?
              <InvoicesPage />
              :
                currentTab === "3"
                ?
                  <PaymentsReceivedPage />
                  :
                  <></>
      }
    </Box>
  )
}

export default GetPaidIndex;