import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Menu, 
  MenuItem, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../../components/textFields/SearchTextField';
import TableLoading from '../../../../components/loaders/TableLoading';
import NATable from '../../../../components/utils/NATable';
import NA from '../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  GetChartOfAccounts 
} from '../../../../redux/actions/accounting/chartOfAccountsActions';
import { 
  GetAccountTypes 
} from '../../../../redux/actions/accounting/accountTypesActions';

import ChartOfAccountsModal from './ChartOfAccountsModal';

import global from "../../../../global.scss";
import "./styles.scss";

const ChartOfAccountsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.accounting);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [statusFilter, setStatusFilter] = useState("Income")
  const [chartOfAccountsModalOpen, setChartOfAccountsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [chartOfAccountsList, setChartOfAccountsList] = useState([]);
  const [chartOfAccountsTotal, setChartOfAccountsTotal] = useState(0);

  const [chartOfAccountsMenuOpen, setChartOfAccountsMenuOpen] = useState(false);
  const [chartOfAccountsMenuAnchor, setChartOfAccountsMenuAnchor] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Account type",
      sort: "ref_account_type_id",
    },
    {
      label: "Account name",
      sort: "account_name",
    },
    {
      label: "Description",
      sort: "account_description",
    },
    {
      label: "Account number",
      sort: "account_number",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };
    
  useEffect(() => {
    dispatch(GetAccountTypes(companyId, 1, 1000, "&ordering=account_category", setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    handleChartOfAccounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection, statusFilter]);

  useEffect(() => {
    try {
      formatChartOfAccountsList(state.chartOfAccountsList.records || []);
      setChartOfAccountsTotal(state.chartOfAccountsList.total || 0);
    } catch (err) {}
  }, [state]);

  // ----------------- list formatter -----------------
  const formatChartOfAccountsList = (list) => {
    setChartOfAccountsList(list);
  };

  async function handleChartOfAccounts(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    if(statusFilter) query += `&ref_account_type_id__account_category__in=${statusFilter}`;
    await dispatch(GetChartOfAccounts(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  return (
    <Box>

      <ChartOfAccountsModal 
        open={chartOfAccountsModalOpen}
        setOpen={setChartOfAccountsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleChartOfAccounts}
        />

      <Box 
        className="flexCenterSBRow" 
        mb={"15px"}>
        <Box  
          className="flexCenter_Row"
          gap="15px">
          <Chip
            variant='outlined'
            color={statusFilter === "Assets" ? 'success' : 'default'}
            label="Assets"
            onClick={() => {
              setStatusFilter("Assets");
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Liabilities" ? 'warning' : 'default'}
            label="Liabilities"
            onClick={() => {
              setStatusFilter("Liabilities");
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Income" ? 'warning' : 'default'}
            label="Income"
            onClick={() => {
              setStatusFilter("Income");
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Expenses" ? 'warning' : 'default'}
            label="Expenses"
            onClick={() => {
              setStatusFilter("Expenses");
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Equity" ? 'warning' : 'default'}
            label="Equity"
            onClick={() => {
              setStatusFilter("Equity");
            }}
            />
        </Box>
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <SearchTextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
          <Button variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Create account");
              setChartOfAccountsModalOpen(!chartOfAccountsModalOpen);
            }}>
            Create account
          </Button>
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <Table className='costacc'>
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                chartOfAccountsList.length === 0
                ?
                <NATable
                   label={"No cost account found"}
                   />
                :
                chartOfAccountsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>{row.ref_account_type_id?.account_type || <NA />}</TableCell>
                    <TableCell>{row.account_name || <NA />}</TableCell>
                    <TableCell>{row.account_description || <NA />}</TableCell>
                    <TableCell>{row.account_number || <NA />}</TableCell>
                    <TableCell>
                      {
                        row.id === deleteId && deleting
                        ?
                        <CircularProgress size={26} sx={{ margin: "7px" }}/>
                        :
                        <IconButton
                          onClick={(e) => {
                            setChartOfAccountsMenuAnchor(e.currentTarget);
                            setChartOfAccountsMenuOpen(true);
                            setDeleteId(row.id);
                            setUpdate(row);
                            setModalTitle(row.account_name || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={chartOfAccountsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={chartOfAccountsMenuOpen}
        anchorEl={chartOfAccountsMenuAnchor}
        onClose={() => setChartOfAccountsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setChartOfAccountsMenuOpen(false);
            setChartOfAccountsModalOpen(!chartOfAccountsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default ChartOfAccountsPage;