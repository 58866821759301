import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
  Autocomplete,
  Box,
  Checkbox,
  Container,
  MenuItem,
  Modal, 
  TextField,
  Typography,
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import useCurrencyService from '../../../../components/hooks/useCurrencyService';
import AmountTextField from '../../../../components/textFields/AmountTextField';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateProduct,
  UpdateProduct
} from '../../../../redux/actions/accounting/productsActions';
import { 
  GetSalesTaxes 
} from '../../../../redux/actions/accounting/salesTaxActions';

import "./styles.scss";

const Data = {
  Name: "",
  Currency: "USD",
  Description: "",
  Price: "",
  SalesTaxInput: "",
  SalesTaxList: [],
  UseEffectiveDate: false,
  EffectiveDate: dayjs(),
  SellThis: true,
  BuyThis: false,
  IncomeAccountId: "",
  ExpensesAccountId: ""
};

const ProductsModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.accounting);
  const companyId = useSelectedCompany();
  const { handleEditAmount, handleCalculateAmount } = useCurrencyService();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const [salesTaxList, setSalesTaxList] = useState([]);

  const [incomeAccountsList, setIncomeAccountsList] = useState([]);
  const [expensesAccountsList, setExpensesAccountsList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open) {
      dispatch(GetSalesTaxes(companyId, 1, 500, 1, "", setLoading));
      if(update?.defaultMode === 1){
        // mode = 1 - create product
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - edit product
        setMode(2);
        setFormData({
          ...formData,
          Name: update?.name || "",
          Description: update?.description || "",
          SellThis: update?.sell_this,
          BuyThis:  update?.buy_this,
          IncomeAccountId: update?.income_account || "",
          ExpensesAccountId:  update?.expense_account || "",
          Currency: update?.associated_selling_accounting_product[0].currency || "USD",
          Price: handleEditAmount(update?.associated_selling_accounting_product[0].currency || "USD", 
            update?.associated_selling_accounting_product[0].selling_price || 0),
          SalesTaxList: update?.associated_taxes_accounting_product.map((salesTax) => {
            return {
                "sales_tax_id": salesTax.ref_sales_tax_id.sales_tax_id,
                "name": salesTax.ref_sales_tax_id.name,
                "abbreviation": salesTax.ref_sales_tax_id.abbreviation,
                "associated_sale_taxes": salesTax.ref_sales_tax_id.associated_sale_taxes,
                "is_tax_num_shown_on_invoice": salesTax.ref_sales_tax_id.is_tax_num_shown_on_invoice,
                "is_compound_tax": salesTax.ref_sales_tax_id.is_compound_tax,
            }
          })
        });
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  
  useEffect(() => {
    try {
      formatSalesTaxList(state.salesTaxesList.records || [])
      setIncomeAccountsList(state.chartOfAccountsForIncomeList.records || []);
      setExpensesAccountsList(state.chartOfAccountsForExpensesList.records || []);
    } catch (err) {}
  }, [state]);

  const formatSalesTaxList = (list) => {
    setSalesTaxList(list);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> create product
        let obj =  {
          "name": formData.Name,
          "description": formData.Description,
          "sell_this": formData.SellThis,
          "buy_this": formData.BuyThis,
          "income_account": formData.IncomeAccountId,
          "expense_account": formData.ExpensesAccountId,
          "product_selling_price_array": [
            {
              "currency": formData.Currency,
              "selling_price": handleCalculateAmount(formData.Currency, formData.Price),
              "effective_date": dayjs().format('YYYY-MM-DD'),
            }
          ],
          "product_tax_array": formData.SalesTaxList.map((item) => {
            return {
              "ref_sales_tax_id": item.sales_tax_id
            }
          })
        };

        dispatch(CreateProduct(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2){
        // mode = 2 -> edit product
        let obj = {
          "name": formData.Name,
          "description": formData.Description,
          "sell_this": formData.SellThis,
          "buy_this": formData.BuyThis,
          "income_account": formData.IncomeAccountId,
          "expense_account": formData.ExpensesAccountId,
          "product_selling_price_array": 
            formData.UseEffectiveDate === true
            ?
            // add another selling price
            [
              {
                "action" : "ADD",
                "currency": formData.Currency,
                "selling_price": handleCalculateAmount(formData.Currency, formData.Price),
                "effective_date": dayjs(formData.EffectiveDate).format('YYYY-MM-DD'),
              }
            ]
            :
            // update existing selling price
            [
              {
                "action" : "UPDATE",
                "product_price_id": update?.associated_selling_accounting_product[0].product_price_id,
                "currency": formData.Currency,
                "selling_price": handleCalculateAmount(formData.Currency, formData.Price),
                "effective_date": dayjs().format('YYYY-MM-DD'),
              }
            ],
          "product_tax_array": formData.SalesTaxList.map((item) => {
            return {
              "ref_sales_tax_id": item.sales_tax_id
            }
          })
        };

        dispatch(UpdateProduct(companyId, update?.product_id, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          className={`fullpage__modal animate__animated animate__fadeIn`}>
          
          <ModalTopBar
            onBack={handleClose}
            title={title} 
            />

          <Container 
            maxWidth="sm">

            <Box 
              className="flex__Column"
              gap={"20px"}
              mt={"20px"}
              mb={"20px"}>
              
              <Box 
                className="flexFSSBRow"
                gap="15px">
                <TextField 
                  label="Name"
                  fullWidth
                  name="Name"
                  value={formData.Name}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 80
                  }}
                  />
              </Box>

              <Box 
                className="flexFSSBRow"
                gap="15px">
                <TextField 
                  label="Description"
                  fullWidth
                  name="Description"
                  value={formData.Description}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 80
                  }}
                  />
              </Box>

              <Box 
                className="flexFSSBRow"
                gap="15px">
                <AmountTextField 
                  currencyName="Currency"
                  currency={formData.Currency}
                  onCurrencyChange={handleChange}
                  hideCurrencySelector={mode === 2 ? true : false}
                  label="Price"
                  fullWidth
                  name="Price"
                  value={formData.Price}
                  onChange={handleChange}
                  />
              </Box>

              {
                mode === 2
                &&
                <Box 
                  className="flexCenter_Row"
                  gap="15px">
                  <Checkbox
                    checked={formData.UseEffectiveDate}
                    name='UseEffectiveDate'
                    onChange={(e) => 
                      handleChange({target: { name: "UseEffectiveDate", value: e.target.checked }})
                    }
                    />
                  <Typography>Product price effective from future date</Typography>
                </Box>
              }
              
              {
                formData.UseEffectiveDate === true
                &&
                <>
                <Box
                    className="flexCenterCenterRow"
                    gap={"5px"}>
                    <Typography
                      variant='caption'
                      color={"grey"}>
                      Effective date
                    </Typography>
                    <Typography
                      variant='caption'
                      fontWeight={500}>
                      {dayjs(formData?.EffectiveDate).format("MMM DD, YYYY")}
                    </Typography>
                  </Box>
                  <LocalizationProvider 
                    dateAdapter={AdapterDayjs}>
                    <DateCalendar 
                      minDate={dayjs().add(1, 'day')}
                      value={formData.EffectiveDate}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "EffectiveDate",
                            value: e
                          }
                        })
                      }} />
                  </LocalizationProvider>
                </>
              }

              <Box 
                className="flexCenter_Row"
                gap="15px">
                <Checkbox
                  checked={formData.SellThis}
                  name='SellThis'
                  onChange={(e) => 
                    handleChange({target: { name: "SellThis", value: e.target.checked }})
                  }
                  />
                <Typography>Sell this</Typography>
              </Box>

              {
                formData.SellThis
                &&
                <Box 
                  className="flexCenter_Row"
                  gap="15px">
                  <TextField 
                    select
                    label="Income account"
                    fullWidth
                    name="IncomeAccountId"
                    value={formData.IncomeAccountId}
                    onChange={handleChange}
                    >
                    {
                      incomeAccountsList.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.id}>
                          {item.account_name}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </Box>
              }

              <Box 
                className="flexCenter_Row"
                gap="15px">
                <Checkbox
                  checked={formData.BuyThis}
                  name='BuyThis'
                  onChange={(e) => 
                    handleChange({target: { name: "BuyThis", value: e.target.checked }})
                  }
                  />
                <Typography>Buy this</Typography>
              </Box>

              {
                formData.BuyThis
                &&
                <Box 
                  className="flexCenter_Row"
                  gap="15px">
                  <TextField 
                    select
                    label="Expenses account"
                    fullWidth
                    name="ExpensesAccountId"
                    value={formData.ExpensesAccountId}
                    onChange={handleChange}
                    >
                    {
                      expensesAccountsList.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.id}>
                          {item.account_name}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </Box>
              }

              <Autocomplete
                multiple
                options={salesTaxList}
                getOptionLabel={(option) => option.name}
                value={formData.SalesTaxList}
                inputValue={formData.SalesTaxInput}
                onSelect={(e) => console.log(e.target.value) }
                onChange={(event, newValue) => {
                  handleChange({target: { name: "SalesTaxList", value: newValue }});
                }}
                onInputChange={(event, newInputValue) => {
                  handleChange({target: { name: "SalesTaxInput", value: newInputValue }});
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sales tax"
                    placeholder="Search sales tax"
                  />
                )}
              />

              <LoadingButton
                variant="contained"
                loading={loading}
                disabled={!(formData.Name !== "" && formData.Description !== "" &&
                  formData.Price !== "" && (formData.BuyThis ? formData.BuyThis && formData.ExpensesAccountId : true) &&
                  (formData.SellThis ? formData.SellThis && formData.IncomeAccountId : true))}
                onClick={handleSubmit}>
                {mode === 1 ? "Submit" : "Update"}
              </LoadingButton>
            </Box>
          </Container>
        </Box>
      </Box>
    </Modal>
  )
}

export default ProductsModal;