import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import 'react-image-crop/dist/ReactCrop.css';

import {
  Box,
  IconButton,
  Modal, 
  Typography, 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import LoadingButton from '../../../../components/buttons/Button';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { 
  UploadFiles 
} from '../../../../redux/actions/miscellaneousActions';
import { 
  GetCompany,
  UpdateCompany 
} from '../../../../redux/actions/dashboard/companiesActions';

import "./styles.scss";

const LogoUploadModal = ({
  open,
  setOpen,
  selectedImage,
  setSelectedImage
}) => {
  const dispatch = useDispatch();
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    try {
      if(selectedImage){
        setPreviewImage(URL.createObjectURL(selectedImage));
      }
    } catch (err) {
      console.log(err)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setPreviewImage(null);
    setSelectedImage(null);
  };

  const uploadImage = async () => {
    let path = "";

    const image = new FormData();
    image.append("new_file", selectedImage);
    await dispatch(UploadFiles(image, setLoading)).then(({res, statusCode}) => {
      if(statusCode === 201) {
        let obj = {
          logo_url: res.data.file_path
        };

        dispatch(UpdateCompany(companyId, obj, setLoading)).then(() => {
          dispatch(GetCompany(companyId, setLoading)).then(() => {
            handleClose();
          })
        });
      }
    });

    return path;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className="flexCenterCenterColumn"
        sx={{ width: "100vw", height: "100vh" }}>
        <Box
          className={`modal animate__animated animate__fadeIn flex__Column`}
          sx={{ 
            minWidth: "750px",
            maxWidth: "750px",
            width: "100%",
            maxHeight: "80vh",
            height: "60vh",
            minHeight: "40vh",
            padding: "20px",
            gap: "25px"
          }}>
            <Box
              className="flexCenterSBRow">
              <Typography
                variant='h5'>
                Preview logo
              </Typography>
              <IconButton
                onClick={handleClose}>
                <CloseOutlinedIcon 
                  size="large" 
                  />
              </IconButton>
            </Box>
            
          {selectedImage && (
            <Box
              className="flexCenterCenterRow"
              sx={{ margin: "auto", width: "70%" }}>
              <img 
                src={previewImage} 
                style={{
                  width: "100%",
                  objectFit: 'contain',
                  height: "40vh"
                }}
                alt='company-crop=logo'
                />
            </Box>
          )}
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={uploadImage}>
            Upload Logo
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
 

  )
}

export default LogoUploadModal;