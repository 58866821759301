import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

import { 
  GetMyProfile 
} from "./dashboard/profileActions";

export const LogIn = (formData, navigate, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.login(formData);
    res = data;
    statusCode = status;
    if (status === 200) {
      dispatch({ type: actionTypes.LOG_IN, payload: data.data });
      // await dispatch(AssociatedCompanies(setLoading));
      await dispatch(GetMyProfile(setLoading));
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode }; 
};

export const Register = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.register(formData);
    res = data;
    statusCode = status;
    if (status === 201) {
      // dispatch({ type: actionTypes.REGISTER, payload: data.data });
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};

export const ForgotPassword = (setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.forgotPassword();
    if (status === 200) {
      dispatch({ type: actionTypes.LOG_IN, payload: data.tokens });
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

// ====================== register otp ==========================
export const SendRegisterOtp = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.sendRegisterOtp(formData);
    res = data;
    statusCode = status;
    if (status === 201) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};

export const ResendRegisterOtp = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.resendRegisterOtp(formData);
    res = data;
    statusCode = status;
    if (status === 201) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};

export const VerifyRegisterOtp = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  let res, statusCode;
  try {
    const { data, status } = await api.verifyRegisterOtp(formData);
    res = data;
    statusCode = status;
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
  return { res, statusCode };
};


// ====================== refresh ==========================
export const RefreshToken = (formData) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  let res, statusCode;
  try {
    const { data, status } = await api.refreshToken(formData);
    res = data;
    statusCode = status;
    if (status === 200) {
      dispatch({ type: actionTypes.REFRESH_TOKEN, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
  }
  return { res, statusCode };
};