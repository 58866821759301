import * as actionTypes from "../../actionTypes";
import * as api from "../../../api/index";
import { toast } from "react-toastify";

export const GetInvoiceFromToken = (companyId, token, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  dispatch({ type: actionTypes.CLEAR_INVOICE_MAKEPAYMENT });
  setLoading(true);
  try {
    const { data, status } = await api.getInvoiceFromToken(companyId, token);
    if (status === 200) {
        dispatch({ type: actionTypes.GET_INVOICE_MAKEPAYMENT, payload: data.data });
    }else{
        dispatch({ type: actionTypes.CLEAR_INVOICE_MAKEPAYMENT });
    //   dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.CLEAR_INVOICE_MAKEPAYMENT });
    // dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const PayInvoice = (companyId, formData, setLoading) => async (dispatch) => {
    dispatch({ type: actionTypes.CLEAR_ERRORS });
    setLoading(true);
    let res, statusCode;
    try {
      const { data, status } = await api.payInvoice(companyId, formData);
      res = data;
      statusCode = status;
      if (status === 201) {
        toast.success(data?.message);
      }else{
        dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      }
      setLoading(false);
    } catch (error) {
      dispatch({ type: actionTypes.SET_CRASHES, payload: error });
      setLoading(false);
    }
    return { res, statusCode };
  };