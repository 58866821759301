import * as actionTypes from "../actionTypes.js";

const initialState = {
  accountTypesList: null,
  chartOfAccountsList: null,
  chartOfAccountsForIncomeList: null, 
  chartOfAccountsForExpensesList: null,

  salesTaxesList: null,
  productsList: null,
};

const accountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_TYPES_ACCOUNTING:
      return { ...state, accountTypesList: action.payload };  
    case actionTypes.GET_CHART_OF_ACCOUNTS_ACCOUNTING:
      return { ...state, chartOfAccountsList: action.payload }; 
    case actionTypes.GET_CHART_OF_ACCOUNTS_INCOME_ACCOUNTING:
      return { ...state, chartOfAccountsForIncomeList: action.payload }; 
    case actionTypes.GET_CHART_OF_ACCOUNTS_EXPENSES_ACCOUNTING:
      return { ...state, chartOfAccountsForExpensesList: action.payload }; 

        
    case actionTypes.GET_SALES_TAXES_ACCOUNTING:
      return { ...state, salesTaxesList: action.payload };
    case actionTypes.GET_PRODUCTS_ACCOUNTING:
      return { ...state, productsList: action.payload };


    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default accountingReducer;