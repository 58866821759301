import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../components/textFields/SearchTextField';
import useCurrencyService from '../../../components/hooks/useCurrencyService';
import TableLoading from '../../../components/loaders/TableLoading';
import NATable from '../../../components/utils/NATable';
import NA from '../../../components/utils/NA';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';

import {
  ActOnExpenseForApproval,
  GetExpensesForApprover,  
} from '../../../redux/actions/expense/expensesActions';

import { 
  OPEN_IMAGE_VIEWER, 
  SET_IMAGES_URL 
} from '../../../redux/actionTypes';

import ApprovalsModal from './ApprovalsModal';
import ExpensesModal from '../expensesPage/ExpensesModal';

import global from "../../../global.scss";
import "./styles.scss";

const ApprovalsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.expense);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  const { handleShowAmount } = useCurrencyService();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [approvalsModalOpen, setApprovalsModalOpen] = useState(false);
  const [expensesModalOpen, setExpensesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);
  
  const [expensesList, setExpensesList] = useState([]);
  const [expensesTotal, setExpensesTotal] = useState(0);

  const [selectedExpensesList, setSelectedExpensesList] = useState([]);
  
  const [sendId, setSendId] = useState([]);
  const [sending, setSending] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();
  
  const tableHeadingsList = [
    {
      label: "Date",
      sort: "created_at",
    },
    {
      label: "Employee",
      sort: "ref_employee_id",
    },
    {
      label: "Transaction",
      sort: "name",
    },
    {
      label: "Amount",
      sort: "amount",
    },
    {
      label: "Category | Cost account",
      sort: "ref_category_id",
    },
    {
      label: "Additional employees",
      sort: "expense_employees",
    },
    {
      label: "",
      sort: null,
      align: "center"
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetExpensesForApprover();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatExpensesList(state.expensesListForApprover.records || []);
      setExpensesTotal(state.expensesListForApprover.total || 0);
    } catch (err) {}
  }, [state]);

  // ----------------- list formatter -----------------
  const formatExpensesList = (list) => {
    setExpensesList(list)
  };

  async function handleGetExpensesForApprover(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetExpensesForApprover(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const handleToForwardStep = (id) => {
    setSendId([id, "Approved"]);
    let obj = {
      "expenses_list": [
        {
          "expense_id": id,
          "action": "Approved"
        }
      ]
    };
    dispatch(ActOnExpenseForApproval(companyId, obj, setSending)).then(() => {
      handleGetExpensesForApprover(setSending).then(() => {
        setSendId([]);
      })
    });
  };

  const handleMutliToForwardStep = () => {
    setSendId(["Approved"]);
    let obj = {
      "expenses_list": selectedExpensesList.map(i => {
        return {
          "expense_id": i,
          "action": "Approved"
        };
      })
    };
    dispatch(ActOnExpenseForApproval(companyId, obj, setSending)).then(() => {
      handleGetExpensesForApprover(setSending).then(() => {
        setSendId([]);
        handleClearSelectedExpensesList();
      })
    });
  };

  const handleClearSelectedExpensesList = () => {
    setSelectedExpensesList([]);
  };

  return (
    <Box>

      <ExpensesModal
        open={expensesModalOpen}
        setOpen={setExpensesModalOpen}
        title={modalTitle}
        update={update}
        handleGet={() => {}}
        />

      <ApprovalsModal
        open={approvalsModalOpen}
        setOpen={setApprovalsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetExpensesForApprover}
        handleClearSelectedExpensesList={handleClearSelectedExpensesList}
        />

      <Box 
        className="flexCenterFERow" 
        mb={"10px"}>
        <Box
          className="flexCenter_Row" 
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <>

          {
            selectedExpensesList.length > 0
            &&
            <Box
              className="approvals__multiselect flexCenterSBRow">

              <Typography
                variant='body2'
                fontWeight={600}>
                {selectedExpensesList.length} Expense selected
              </Typography>

              <Box 
                className="flexCenter_Row" 
                gap="15px">
                {
                  <Chip
                    variant='outlined' 
                    color='success'
                    disabled={sending}
                    sx={{ "&:hover": { cursor: "pointer" }}}
                    onClick={() => {
                      handleMutliToForwardStep()
                    }}
                    label={
                      sendId[0] === "Approved" && sending
                      ?
                      <Box width={"99px"}
                        className="flexCenterCenterRow">
                        <CircularProgress 
                          size={24} />
                      </Box>  
                      :
                      <Box className="flexCenterSERow" gap="5px" width={"130px"}>
                        <CheckOutlinedIcon fontSize='small'/>
                        Accept selected
                      </Box>
                    }
                    />
                }
                {
                  <Chip
                    variant='outlined' 
                    color='error'
                    disabled={sending}
                    sx={{ "&:hover": { cursor: "pointer" }}}
                    onClick={() => {
                      setSendId(["Rejected"]);
                      setUpdate({
                        defaultMode: 1,
                        expensesList: selectedExpensesList.map(i => {
                          return i;
                        }),
                        action: "Rejected",
                      });
                      setModalTitle("Rejecting selected expenses");
                      setApprovalsModalOpen(!approvalsModalOpen);  
                    }}
                    label={
                      sendId[1] === "Rejected" && sending
                      ?
                      <Box width={"99px"}
                        className="flexCenterCenterRow">
                        <CircularProgress 
                          size={24} />
                      </Box> 
                      :
                      <Box className="flexCenterSERow" gap="5px" width={"130px"}>
                        <ClearOutlinedIcon fontSize='small'/>
                        Reject selected
                      </Box>
                    }
                    />
                }
              </Box>
            </Box>
          }
          <Table className='table'>
            <TableHead>
              <TableRow>
                {
                  expensesList.length > 0
                  &&
                  <TableCell width={"60px"}>
                    <Checkbox 
                      checked={selectedExpensesList.length === expensesList.length}
                      onChange={(e) => {
                        if(e.target.checked === true){
                          let temp = expensesList.map(i => {
                            return i.expense_id
                          });
                          setSelectedExpensesList([ ...temp ]);
                        } else {
                          setSelectedExpensesList([]);
                        }
                      }}
                      />
                  </TableCell>
                }
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
                {
                  selectedExpensesList.length === 0
                  &&
                  <TableCell align='center'>Actions</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                expensesList.length === 0
                ?
                <NATable
                  label={"No expense approval found"}
                  />
                :
                expensesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell width={"60px"}>
                      <Checkbox 
                        checked={selectedExpensesList.includes(row.expense_id)}
                        onChange={(e) => {
                          if(e.target.checked === true){
                            let temp = selectedExpensesList;
                            temp.push(row.expense_id);
                            setSelectedExpensesList([ ...temp ]);
                          } else {
                            let temp = selectedExpensesList;
                            temp = temp.filter(i => i !== row.expense_id);
                            setSelectedExpensesList([ ...temp ]);
                          }
                        }}
                        />
                    </TableCell>
                    <TableCell>{row.created_at && new Date(row.created_at).toDateString().slice(4)}</TableCell>
                    <TableCell>
                      <Tooltip
                        color="info"
                        title={row?.ref_employee_id?.employee_email || <NA />}>
                        <Chip 
                          size='small'
                          label={row?.ref_employee_id?.employee_name || <NA />}
                          />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row.name}</Typography>
                      </Box>
                      <Typography
                        color={"grey"} 
                        className='longText'
                        sx={{ width: "220px", fontSize: 12 }}
                        >
                        {row.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography fontWeight={600}>
                          {row.amount && handleShowAmount(row.currency, row.amount)}
                        </Typography>
                        <Typography  variant='caption' color={"grey"}>{row?.ref_payment_method_id?.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{row?.ref_category_id?.category}<br/>{row?.ref_cost_account_id?.name}
                    </TableCell>
                    <TableCell>
                      <Box
                        className="flexCenter_Row"
                        gap={"5px"}>
                        <Typography>
                          {row?.expense_employees?.length}
                        </Typography>
                        { 
                          row?.expense_employees?.length !== 0
                          &&
                          <IconButton
                            onClick={() => {
                              setModalTitle("View employees in " + row?.name);
                              setUpdate({
                                ...row,
                                defaultMode: 3
                              });
                              setExpensesModalOpen(!expensesModalOpen);
                            }}>
                            <PeopleOutlineRoundedIcon />
                          </IconButton>
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className="flexCenterFERow" gap={"10px"}>
                        <IconButton
                          disabled={row?.expense_attachments.length === 0}
                          onClick={() => {
                            setUpdate(row?.expense_attachments.map(i => { return i.attachment_path }))
                            dispatch({ type: OPEN_IMAGE_VIEWER });
                            dispatch({ type: SET_IMAGES_URL, payload: row?.expense_attachments.map(i => { return i.attachment_path }) });
                          }}>
                          <ReceiptLongOutlinedIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setModalTitle("Activity messages for " + row?.name);
                            setUpdate({
                              ...row,
                              defaultMode: 4
                            });
                            setExpensesModalOpen(!expensesModalOpen);
                          }}>
                          <MessageOutlinedIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                    {
                      selectedExpensesList.length === 0
                      &&
                      <TableCell align='center'
                        width={"200px"}>
                        <Box className="flexCenter_Row" gap="15px">
                          {
                            sendId[0] === row?.expense_id && sendId[1] === "Approved" && sending
                            ?
                            <Box width={"99px"}
                              className="flexCenterCenterRow">
                              <CircularProgress 
                                size={24} />
                            </Box>
                            :
                            <Chip
                              variant='outlined' 
                              color='success'
                              disabled={sending}
                              sx={{ "&:hover": { cursor: "pointer" }}}
                              onClick={() => {
                                handleToForwardStep(row?.expense_id)
                              }}
                              label={
                                <Box className="flexCenterSERow" gap="5px" width={"75px"}>
                                  <CheckOutlinedIcon fontSize='small'/>
                                  Accept
                                </Box>
                              }
                              />
                          }
                          {
                            sendId[0] === row?.expense_id && sendId[1] === "Rejected" && sending
                            ?
                            <Box width={"99px"}
                              className="flexCenterCenterRow">
                              <CircularProgress 
                                size={24} />
                            </Box>
                            :
                            <Chip
                              variant='outlined' 
                              color='error'
                              disabled={sending}
                              sx={{ "&:hover": { cursor: "pointer" }}}
                              onClick={() => {
                                setUpdate({
                                  defaultMode: 1,
                                  expensesList: [row?.expense_id],
                                  action: "Rejected",
                                });
                                setModalTitle(
                                  <>
                                    {"Rejecting expense - "}
                                    <span
                                      style={{ color: global["secondary"] }}>
                                      {row.name}
                                    </span>
                                  </>);
                                setApprovalsModalOpen(!approvalsModalOpen);  
                              }}
                              label={
                                <Box className="flexCenterSERow" gap="5px" width={"75px"}>
                                  <ClearOutlinedIcon fontSize='small'/>
                                  Reject
                                </Box>
                              }
                              />
                          }
                        </Box>
                      </TableCell>
                    }
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={expensesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
          </>
      }
    </Box>
  )
}

export default ApprovalsPage;