import * as actionTypes from "../actionTypes.js";

const initialState = {
  // real cards
  realCardsList: null,

  // spend wallets
  spendWalletsList: null,
  spendWalletEmployeesList: null,
  employeeWalletsList: null,

  // vcn
  virtualCardsList: null, 
  virtualCardDetails: null,

  // miscellaneous
  networksList: null, 
  walletFrequenciesList: null
};

const walletsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REAL_CARDS_WALLET:
      return { ...state, realCardsList: action.payload };
    case actionTypes.GET_SPEND_WALLETS_WALLET:
      return { ...state, spendWalletsList: action.payload };
    case actionTypes.GET_SPEND_WALLETS_EMPLOYEES_WALLET:
      return { ...state, spendWalletEmployeesList: action.payload };
    case actionTypes.GET_EMPLOYEE_WALLETS_WALLET:
      return { ...state, employeeWalletsList: action.payload };
    case actionTypes.GET_VCN_WALLET:
      return { ...state, virtualCardsList: action.payload };
    case actionTypes.GET_VCN_DETAILS_WALLET:
      return { ...state, virtualCardDetails: action.payload };

    case actionTypes.GET_NETWORK_WALLET:
      return { ...state, networksList: action.payload };
    case actionTypes.GET_WALLET_FREQUENCY_WALLET:
      return { ...state, walletFrequenciesList: action.payload };
      
    case actionTypes.SELECT_COMPANY:
      return initialState;
    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default walletsReducer;