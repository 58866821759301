import React from 'react';

import { 
  Avatar, 
  Typography
} from '@mui/material';

const CompanyAvatar = (props) => {

  function filterText(text) {
    let label = "NA";

    let arr = text.split(" ");

    if(arr.length >= 2){
      label = arr[0][0] + arr[1][0];
    }else if(arr.length === 1){
      label = arr[0][0];
    }else{
      label = "NA";
    };

    return label.toUpperCase();
  };

  const newShade = (hexColor, magnitude) => {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
        const decimalColor = parseInt(hexColor, 16);
        let r = (decimalColor >> 16) + magnitude;
        r > 255 && (r = 255);
        r < 0 && (r = 0);
        let g = (decimalColor & 0x0000ff) + magnitude;
        g > 255 && (g = 255);
        g < 0 && (g = 0);
        let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
        b > 255 && (b = 255);
        b < 0 && (b = 0);
        return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
        return hexColor;
    }
};

  return (
    <Avatar
      sx={{ 
        backgroundColor: props.color ? props.color : "#eaeaea",
        border: "1px solid", 
        borderColor: props.color ? newShade(props.color, -10) : "#c1c1c1",
        width: props.size === "large" 
          ? "60px" 
          : props.size === "small" 
            ? "26px"
            : "34px",
        height: props.size === "large" 
            ? "60px" 
            : props.size === "small" 
              ? "26px"
              : "34px",
        borderRadius: "8px"
      }}>
      {
        props.children
        ? 
          <Typography
            color={props.color ? newShade(props.color, -80) : "black"}
            variant={
              props.size === "large" 
              ? "h6" 
              :  props.size === "small" 
                ? "caption"
                : "body2" }
            fontWeight={600}
            >
            {filterText(props.children)}
          </Typography>
        : 
          <Typography
            color={props.color ? newShade(props.color, -80) : "black"}
            variant={
              props.size === "large" 
              ? "h6" 
              :  props.size === "small" 
                ? "caption"
                : "body2" }
            fontWeight={600}>
            NA 
          </Typography>
      } 
    </Avatar>
  )
};

export default CompanyAvatar;