import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Menu, 
  MenuItem, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow,  
  TableSortLabel,  
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../components/textFields/SearchTextField';
import useCurrencyService from '../../../components/hooks/useCurrencyService';
import TableLoading from '../../../components/loaders/TableLoading';
import DateFormatter from '../../../components/utils/DateFormatter';
import LoadingButton from '../../../components/buttons/Button';
import NATable from '../../../components/utils/NATable';

import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { 
  DeleteExpense,
  DeleteMultiExpenses,
  GetExpenses, 
  SubmitExpenseForApproval, 
} from '../../../redux/actions/expense/expensesActions';
import { 
  GetCostAccounts 
} from '../../../redux/actions/expense/costAccountsActions';
import { 
  GetExpenseCategories 
} from '../../../redux/actions/expense/categoriesActions';

import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import ExpensesModal from './ExpensesModal';

import { 
  OPEN_IMAGE_VIEWER, 
  SET_IMAGES_URL 
} from '../../../redux/actionTypes';

import global from "../../../global.scss";
import "./styles.scss";

const ExpensesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.expense);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(true);
  const { handleShowAmount } = useCurrencyService();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [statusFilter, setStatusFilter] = useState("Created");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [expensesModalOpen, setExpensesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);
  
  const [expensesList, setExpensesList] = useState([]);
  const [expensesTotal, setExpensesTotal] = useState(0);

  const [selectedExpensesList, setSelectedExpensesList] = useState([]);
  
  const [expenseMenuOpen, setExpenseMenuOpen] = useState(false);
  const [expenseMenuAnchor, setExpenseMenuAnchor] = useState(null);
  
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [mutliDeleting, setMultiDeleting] = useState(false);
  
  const [sendId, setSendId] = useState(null);
  const [sending, setSending] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Date",
      sort: "created_at",
    },
    {
      label: "Transaction",
      sort: "name",
    },
    {
      label: "Amount | Payment method",
      sort: "amount",
    },
    {
      label: "Category | Cost account",
      sort: "ref_category_id",
    },
    {
      label: "Additional employees",
      sort: "expense_employees",
    },
    {
      label: "",
      sort: null,
      align: "center"
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    dispatch(GetCostAccounts(companyId, 1, 1000, "", setLoading2));
    dispatch(GetExpenseCategories(companyId, 1, 1000, "", setLoading2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetExpenses();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection, statusFilter]);

  useEffect(() => {
    try {
      formatExpensesList(state.expensesList.records || []);
      setExpensesTotal(state.expensesList.total || 0);
    } catch (err) {}
  }, [state]);

  // ----------------- list formatter -----------------
  const formatExpensesList = (list) => {
    setExpensesList(list)
  };

  const handleSendToApprover = (id) => {
    setSendId(id);
    let obj = {
      "expenses_list": [id]
    };
    dispatch(SubmitExpenseForApproval(companyId, obj, setSending)).then(() => {
      handleGetExpenses(setSending);
    });
  };

  const handleMultiSendToApprover = () => {
    let obj = {
      "expenses_list": selectedExpensesList.map(i => {
        return i;
      })
    };
    dispatch(SubmitExpenseForApproval(companyId, obj, setSending)).then(() => {
      handleGetExpenses(setSending).then(() => {
        handleClearSelectedExpensesList();
      })
    });
  };
  
  async function handleGetExpenses(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    if(statusFilter) query += `&status__in=${statusFilter}`;
    await dispatch(GetExpenses(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteExpense(companyId, deleteId, setDeleting)).then(() => {
      handleGetExpenses(setDeleting).then(() => {
        setDeleteId(null);
      })
    });
  };

  const handleMutliDelete = () => {
    let obj = {
      "expenses_list": selectedExpensesList.map(i => {
        return i;
      })
    };
    dispatch(DeleteMultiExpenses(companyId, obj, setMultiDeleting)).then(() => {
      handleGetExpenses(setMultiDeleting).then(() => {
        handleClearSelectedExpensesList();
      })
    })
  };

  const handleClearSelectedExpensesList = () => {
    setSelectedExpensesList([]);
  };

  return (
    <Box>
      
      <ExpensesModal 
        open={expensesModalOpen}
        setOpen={setExpensesModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetExpenses}
        />

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <Box 
        className="flexCenterSBRow" 
        gap="15px"
        mb={"10px"}>
        <Box  
          className="flexCenter_Row"
          gap="15px">
          <Chip 
            variant='outlined'
            color={statusFilter === "Created" ? 'info' : 'default'}
            label="Created"
            onClick={() => {
              handleClearSelectedExpensesList();
              setStatusFilter("Created");
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Pending 1st approval,Pending 2nd approval" ? 'warning' : 'default'}
            label="Pending approval"
            onClick={() => {
              handleClearSelectedExpensesList();
              setStatusFilter("Pending 1st approval,Pending 2nd approval")
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Rejected 1st approval,Rejected 2nd approval" ? 'error' : 'default'}
            label="Rejected"
            onClick={() => {
              handleClearSelectedExpensesList();
              setStatusFilter("Rejected 1st approval,Rejected 2nd approval");
            }}
            />
          <Chip 
            variant='outlined'
            color={statusFilter === "Approved,Scheduled,Paid,Failed" ? 'success' : 'default'}
            label="Approved"
            onClick={() => {
              handleClearSelectedExpensesList();
              setStatusFilter("Approved,Scheduled,Paid,Failed");
            }}
            />
        </Box>
        <Box
          className="flexCenter_Row" 
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
          <Button variant='contained'
            onClick={() => {
              setUpdate({
                defaultMode: 1
              });
              setModalTitle("Create expense");
              setExpensesModalOpen(!expensesModalOpen);
            }}>
            Add Expense
          </Button>
        </Box>
      </Box>

      {
        loading
        ?
        <TableLoading />
        :
          <>
          {
            selectedExpensesList.length > 0
            &&
            <Box
              className="expense__multiselect flexCenterSBRow">

              <Typography
                variant='body2'
                fontWeight={600}>
                {selectedExpensesList.length} Expense selected
              </Typography>

              <Box
                className="flexCenter_Row"
                gap={"10px"}>
                <LoadingButton
                  variant="contained"
                  loading={sending}
                  disabled={mutliDeleting || sending}
                  onClick={handleMultiSendToApprover}>
                  Send selected for approval
                </LoadingButton>
                <LoadingButton
                  loading={mutliDeleting}
                  disabled={mutliDeleting || sending}
                  onClick={handleMutliDelete}>
                  Delete selected
                </LoadingButton>
              </Box>
            </Box>
          }
          <Table className='table'>
            <TableHead>
              <TableRow>
                {
                  (statusFilter !== "Pending 1st approval,Pending 2nd approval" && statusFilter !== "Approved")
                  &&
                    expensesList.length > 0
                    &&
                    <TableCell width={"60px"}>
                      <Checkbox 
                        checked={selectedExpensesList.length === expensesList.length}
                        onChange={(e) => {
                          if(e.target.checked === true){
                            let temp = expensesList.map(i => {
                              return i.expense_id
                            });
                            setSelectedExpensesList([ ...temp ]);
                          } else {
                            setSelectedExpensesList([]);
                          }
                        }}
                        />
                    </TableCell>
                }
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                expensesList.length === 0
                ?
                <NATable 
                  label={"No expense found"}
                  />
                :
                expensesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    {
                      (statusFilter !== "Pending 1st approval,Pending 2nd approval" && statusFilter !== "Approved")
                      &&
                      <TableCell width={"60px"}>
                        <Checkbox 
                          checked={selectedExpensesList.includes(row.expense_id)}
                          onChange={(e) => {
                            if(e.target.checked === true){
                              let temp = selectedExpensesList;
                              temp.push(row.expense_id);
                              setSelectedExpensesList([ ...temp ]);
                            } else {
                              let temp = selectedExpensesList;
                              temp = temp.filter(i => i !== row.expense_id);
                              setSelectedExpensesList([ ...temp ]);
                            }
                          }}
                          />
                      </TableCell>
                    }
                    <TableCell>{row.created_at && DateFormatter(row.created_at)}</TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row.name}</Typography>
                      </Box>
                      <Typography
                        color={"grey"} 
                        className='longText'
                        sx={{ width: "250px", fontSize: 12 }}
                        >
                        {row.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography fontWeight={600}>
                          {row.amount && handleShowAmount(row.currency, row.amount)}
                        </Typography>
                        <Typography  variant='caption' color={"grey"}>{row?.ref_payment_method_id?.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{row?.ref_category_id?.category}<br/>{row?.ref_cost_account_id?.name}</TableCell>
                    <TableCell>
                      <Box
                        className="flexCenter_Row"
                        gap={"5px"}>
                        <Typography>
                          {row?.expense_employees?.length}
                        </Typography>
                        { 
                          row?.expense_employees?.length !== 0
                          &&
                          <IconButton
                            onClick={() => {
                              setModalTitle("View employees in " + row?.name);
                              setUpdate({
                                ...row,
                                defaultMode: 3
                              });
                              setExpensesModalOpen(!expensesModalOpen);
                            }}>
                            <PeopleOutlineRoundedIcon />
                          </IconButton>
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className="flexCenterFERow" gap={"10px"}>
                        <IconButton
                          disabled={row?.expense_attachments.length === 0}
                          onClick={() => {
                            setUpdate(row?.expense_attachments.map(i => { return i.attachment_path }))
                            dispatch({ type: OPEN_IMAGE_VIEWER });
                            dispatch({ type: SET_IMAGES_URL, payload: row?.expense_attachments.map(i => { return i.attachment_path }) });
                          }}>
                          <ReceiptLongOutlinedIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setModalTitle(
                              <>
                                {"Activity messages for"}&nbsp;
                                <span
                                  style={{ color: global["secondary"] }}>
                                  {row?.name}
                                </span>
                              </>);
                            setUpdate({
                              ...row,
                              defaultMode: 4
                            });
                            setExpensesModalOpen(!expensesModalOpen);
                          }}>
                          <MessageOutlinedIcon />
                        </IconButton>
                        {
                          row?.is_reimbursed === true
                          ?
                          <Tooltip
                            title="Reimbursed">
                            <CheckCircleIcon color="success" sx={{ cursor: "pointer" }}/>
                          </Tooltip>
                          :
                            row?.ref_payment_method_id?.is_reimbursable === true
                            ?
                            <Tooltip
                              title="Reimbursable">
                              <MonetizationOnOutlinedIcon color="success"  sx={{ cursor: "pointer" }}/>
                            </Tooltip>
                            :
                            <Tooltip
                              title="Not reimbursable">
                              <MonetizationOnOutlinedIcon sx={{ cursor: "pointer", color: "sandybrown" }}/>
                            </Tooltip>
                        }
                        {
                          selectedExpensesList.length > 0
                          ?
                          <></>
                          :
                            (row.status === "Approved" || row?.status === "Pending 1st approval" || row?.status === "Pending 2nd approval" ||
                              row.status === "Scheduled" || row.status === "Paid" || row.status === "Failed")
                            ?
                            <Box width={"40px"}/>
                            :
                              row?.expense_id === sendId && sending
                              ?
                              <CircularProgress size={26} sx={{ margin: "7px" }}/>
                              :
                              <IconButton
                                onClick={(e) => {
                                  handleSendToApprover(row?.expense_id);
                                }}>
                                <SendTwoToneIcon sx={{ color: global['secondary_light']}}/>
                              </IconButton>
                        }
                        {
                          selectedExpensesList.length > 0
                          ?
                          <></>
                          :
                            (row.status === "Approved" || row?.status === "Pending 1st approval" || row?.status === "Pending 2nd approval" ||
                              row.status === "Scheduled" || row.status === "Paid" || row.status === "Failed")
                            ?
                            <></>
                            :
                              row.expense_id === deleteId && deleting
                              ?
                              <CircularProgress size={26} sx={{ margin: "7px" }}/>
                              :
                              <IconButton
                                onClick={(e) => {
                                  setExpenseMenuAnchor(e.currentTarget);
                                  setExpenseMenuOpen(true);
                                  setDeleteId(row.expense_id);
                                  setUpdate(row);
                                  setModalTitle(row.name || "");
                                }}>
                                <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                              </IconButton>
                        }
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={expensesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
          </>
      }
      <Menu
        open={expenseMenuOpen}
        anchorEl={expenseMenuAnchor}
        onClose={() => setExpenseMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setExpenseMenuOpen(false);
            setExpensesModalOpen(!expensesModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setExpenseMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default ExpensesPage;