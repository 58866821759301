/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Container,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';
import NA from '../../../../components/utils/NA';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import { stateList } from '../../../../components/utils/usStateCodeList';

import { 
  CreateVendor, 
} from '../../../../redux/actions/pay/vendorsActions';
import {
  CreateVendorAccount, 
  UpdateVendorAccount
} from '../../../../redux/actions/pay/vendorBankAccountsActions';

import "./styles.scss";

const Data = {
  name: "",
  ach: "",
  wireRouting: "",
  account: "",
  BankId: "",
  BankAccountTypeId: "",
  Address: "",
  City: "",
  State: "",
  Pincode: ""
};

const CreateVendorsModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const companyId = useSelectedCompany();
  const state = useSelector(state => state.pay);
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const [vendorId, setVendorId] = useState(null);

  const [banksList, setBanksList] = useState([]);
  const [bankAccountTypesList, setBankAccountTypesList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
    setVendorId(null);
  };

  useEffect(() => {
    if(open) {
      if(update.defaultMode === 1){
        // mode = 1 - create vendor
        setMode(1);
      }else if(update.defaultMode === 3){
        // mode = 3 - add new bank detail
        setMode(update.defaultMode);
        setVendorId(update.vendorId);
      }else if(update.defaultMode === 4){
        // mode = 4 - edit bank detail
        setMode(update.defaultMode);
        setVendorId(update.vendorId);
        setFormData({
          ...formData,
          ach: update.ach || "",
          account: update.account || "",
        });
      } else {
        setVendorId(null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatBanksList(state.banksList.records || []);
      formatBankAccountTypesList(state.bankAccountTypesList.records || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const formatBanksList = (list) => {
    setBanksList(list);
  };

  const formatBankAccountTypesList = (list) => {
    setBankAccountTypesList(list);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  const handleNextStep = () => {
    setMode(mode + 1);
  }
  
  const handlePrevStep = () => {
    setMode(mode - 1);
  }

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // create vendor
        let obj = {
          name: formData.name,
          contact_name: formData.contact,
          email: formData.email,
          phone: formData.phone,
          addr_address1: formData.Address,
          addr_city: formData.City,
          addr_state: formData.State,
          addr_zip5: formData.Pincode,
        };
  
        dispatch(CreateVendor(companyId, obj, setLoading)).then(({ res, statusCode }) => {
          if(statusCode === 201) {
            console.log({ res, statusCode })
            handleGet(setLoading);
            handleNextStep();
            if(res.data.vendor_id) {
              setVendorId(res.data.vendor_id);
            } else {
              handleClose();
              throw new Error();
            }
          }
        });
      }else if(mode === 3){
        // create vendor account
        let obj = {
          "ach_routing": formData.ach,
          "wire_routing": formData.wireRouting,
          "account_number": formData.account,
          // "ref_bank_id": formData.BankId || "",
          "ref_bank_acc_type_id": formData.BankAccountTypeId || "",
        };

        dispatch(CreateVendorAccount(companyId, vendorId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      }else if(mode === 4){
        // edit vendor account
        let obj = {
          "ach_routing": formData.ach,
          "wire_routing": formData.wireRouting,
          "account_number": formData.account,
        };

        dispatch(UpdateVendorAccount(companyId, vendorId, update.vendorBankAccountId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      }else{
        throw new Error();
      }
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          className={`fullpage__modal animate__animated animate__fadeIn`}>
          
          <ModalTopBar
            onBack={(mode === 3 || mode === 4)
              ? handleClose 
              : mode === 1 
                ? handleClose 
                : handlePrevStep}
            title={title} 
            />

          {
            mode === 1
            ?
            <Container
              maxWidth="sm">
              <Box
                className="flexCenter_Column"
                gap={"20px"}>
                <Typography
                  variant='h4'
                  textAlign={"center"}
                  mt={"40px"}>
                  Add new vendor
                </Typography>

                <TextField 
                  label="Vendor's business name *"
                  fullWidth
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 100
                  }}
                  />

                <TextField 
                  label="Vendor's contact name"
                  fullWidth
                  name='contact'
                  value={formData.contact}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 100
                  }}
                  />

                <Box
                  width={"100%"}
                  className="flexCenter_Row"
                  gap="15px">
                  <TextField 
                    label="Email address"
                    fullWidth
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 100
                    }}
                    />

                  <TextField 
                    label="Phone number"
                    fullWidth
                    name='phone'
                    value={formData.phone}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 100
                    }}
                    />
                </Box>
                <Box
                  width={"100%"}
                  className="flexCenter_Row"
                  gap="15px">
                  <TextField 
                    label="Address"
                    fullWidth
                    name="Address"
                    value={formData.Address}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 128
                    }}
                    />
                  <TextField 
                    label="City"
                    fullWidth
                    name="City"
                    value={formData.City}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 128
                    }}
                    />
                </Box>
                <Box
                  width={"100%"}
                  className="flexCenter_Row"
                  gap="15px">
                  <TextField 
                    select
                    label="State"
                    fullWidth
                    name="State"
                    value={formData.State}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 128
                    }}
                    >
                    {
                      stateList.map((state, stateIndex) => (
                        <MenuItem
                          key={stateIndex}
                          value={state.code}>
                          <Typography>
                            {state.name}
                          </Typography>
                        </MenuItem>
                      ))
                    }
                  </TextField>
                  <TextField 
                    label="Postal code"
                    fullWidth
                    type='number'
                    name="Pincode"
                    value={formData.Pincode}
                    onChange={handleChange}
                    />
                </Box>

                <LoadingButton
                  variant='contained'
                  fullWidth
                  disabled={!(formData.name !== "" && formData.email !== "" && formData.Pincode !== "" &&
                    formData.Address !== "" && formData.City !== "" && formData.State !== ""
                  )}
                  loading={loading}
                  onClick={handleSubmit}>
                  Continue
                </LoadingButton>
              </Box>
            </Container>
            :
              mode === 2
              ?
              <Container 
                maxWidth="sm">
                <Box
                  className="flexCenter_Column"
                  gap={"40px"}>
                  <Typography
                    variant='h4'
                    textAlign={"center"}>
                    How would&nbsp;
                    <span style={{ color: "darkgrey" }}>{formData.name}</span>
                    &nbsp;like to receive payments?
                  </Typography>

                  <Typography
                    textAlign={"center"}>
                    Vendors don't need to sign up to get paid and it's free every time.
                  </Typography>

                  <Box
                    className="vendorset__bank flexCenter_Row" 
                    gap={"20px"}
                    onClick={handleNextStep}>
                    <AccountBalanceOutlinedIcon 
                      fontSize='large'/>
                    <Box>
                      <Typography 
                        variant='subtitle1'
                        fontWeight={600}>
                        Bank transfer (ACH)
                      </Typography>
                      <Typography
                        variant='body2'>
                        Deposit funds into your vendor’s bank account. (Free)
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    fullWidth
                    onClick={handleClose}>
                    Skip for now
                  </Button>
                </Box>

              </Container>
              :
                mode === 3
                ?
                <Container
                  maxWidth="sm">
                  <Box
                    className="flexCenter_Column"
                    gap={"20px"}>
                    <Typography
                      variant='h4'
                      textAlign={"center"}
                      mt={"40px"}>
                      Add&nbsp;
                      <span style={{ color: "darkgrey" }}>{formData.name}</span>
                      &nbsp;bank details
                    </Typography>

                    <Box
                      className="flexCenterSBRow"
                      width={"100%"}
                      gap={"20px"}>
                      {/* <TextField 
                        select
                        label="Bank"
                        fullWidth
                        name="BankId"
                        disabled={mode === 2}
                        value={formData.BankId}
                        onChange={handleChange}>
                        {
                          banksList.length === 0
                          ?
                          <MenuItem
                            disabled>
                            No bank found
                          </MenuItem>
                          :
                            banksList.map((bank, bankIndex) => (
                              <MenuItem
                                key={bankIndex}
                                value={bank.id}>
                                {bank.bank || <NA />}
                              </MenuItem>
                            ))
                        }
                      </TextField> */}
                      <TextField 
                        select
                        label="Account type"
                        fullWidth
                        name="BankAccountTypeId"
                        disabled={mode === 2}
                        value={formData.BankAccountTypeId}
                        onChange={handleChange}>
                        {
                          bankAccountTypesList.length === 0
                          ?
                          <MenuItem
                            disabled>
                            No account type found
                          </MenuItem>
                          :
                            bankAccountTypesList.map((accountType, accountTypeIndex) => (
                              <MenuItem
                                key={accountTypeIndex}
                                value={accountType.id}>
                                {accountType.type || <NA />}
                              </MenuItem>
                            ))
                        }
                      </TextField>
                    </Box>

                    <TextField 
                      label="Vendor's routing number (ACH)*"
                      fullWidth
                      name='ach'
                      value={formData.ach}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 100
                      }}
                      />

                    <TextField 
                      label="Vendor's wire routing number"
                      fullWidth
                      name='wireRouting'
                      value={formData.wireRouting}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 9
                      }}
                      />

                    <TextField 
                      label="Vendor's account number*"
                      fullWidth
                      name='account'
                      value={formData.account}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 100
                      }}
                      />

                    {/* <Button
                      fullWidth
                      variant='contained'>
                      Save and add bill
                    </Button> */}

                    <LoadingButton
                      loading={loading}
                      fullWidth
                      disabled={!(formData.ach !== "" && formData.account !== "")}
                      onClick={handleSubmit}>
                      Save and close
                    </LoadingButton>

                    <Typography
                      variant='body2'
                      textAlign={"center"}>
                      We'll save these details for future payments to this vendor. 
                      You can always edit them later from your vendor's contact page.
                    </Typography>
                  </Box>
                </Container>
                : 
                  mode === 4
                  ?
                  <Container
                    maxWidth="sm">
                    <Box
                      className="flexCenter_Column"
                      gap={"40px"}>
                      <Typography
                        variant='h4'
                        textAlign={"center"}
                        mt={"40px"}>
                        Edit&nbsp;
                        <span style={{ color: "darkgrey" }}>{formData.name}</span>
                        &nbsp;bank details
                      </Typography>

                      <TextField 
                        label="Vendor's routing number (ACH)*"
                        fullWidth
                        name='ach'
                        value={formData.ach}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 100
                        }}
                        />

                      <TextField 
                        label="Vendor's account number*"
                        fullWidth
                        name='account'
                        value={formData.account}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 100
                        }}
                        />

                      <LoadingButton
                        fullWidth
                        variant='contained'
                        loading={loading}
                        disabled={mode === 4
                          ? !(formData.ach !== "" && formData.account !== "")
                          : !(formData.ach !== "" && formData.account !== "" && 
                            formData.BankId !== "" && formData.BankAccountTypeId !== "")
                        }
                        onClick={handleSubmit}>
                        Save
                      </LoadingButton>
                    </Box>
                  </Container>
                  :
                  <></>
          }
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateVendorsModal;