import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import AuthReducer from "./authReducers";
import DashboardReducer from "./dashboardReducers";
import ProfileReducer from "./profileReducers";
import CompanyReducer from "./companyReducers";
import BillingReducer from "./billingReducers";
import WalletsReducer from "./walletReducers";
import AnalyticsRedcuer from "./analyticsReducers";
import MainReducer from "./mainReducers";
import AccountingReducer from "./accountingReducers";
import PayReducer from "./payReducers";
import ReceiveReducer from "./receiveReducers";
import ExpenseReducer from "./expenseReducers";
import MakePaymentReducer from "./makePaymentReducers";
import GuestReducer from "./guestReducers";
import MiscellaneousReducer from "./miscellaneousReducers";


const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: AuthReducer,
  dashboard: DashboardReducer,
  profile: ProfileReducer,
  analytics: AnalyticsRedcuer, 
  main: MainReducer,
  bill: BillingReducer,
  accounting: AccountingReducer,
  pay: PayReducer,
  receive: ReceiveReducer,
  expense: ExpenseReducer,
  makePayment: MakePaymentReducer,
  guest: GuestReducer,
  other: MiscellaneousReducer,
  
  company: CompanyReducer,

  wallet: WalletsReducer,
});

export default persistReducer(persistConfig, rootReducer);