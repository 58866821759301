import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  Modal, 
  TextField,
  Typography, 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import useCurrencyService from '../../../../components/hooks/useCurrencyService';
import AmountTextField from '../../../../components/textFields/AmountTextField';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateExpenseApprovalWorkflow,  
  UpdateExpenseApprovalWorkflow
} from '../../../../redux/actions/expense/approvalWorkflowsActions';

import "./styles.scss";

const Data = {
  MinLimit: "",
  MaxLimit: "",
  Currency: "USD",
  Name: ""
};

const ApprovalsModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const companyId = useSelectedCompany();
  const { handleEditAmount, handleCalculateAmount } = useCurrencyService();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open) {
      if(update?.defaultMode === 1){
        // mode = 1 - create approval workflow
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - edit approval workflow
        setMode(2);
        setFormData({
          ...Data, 
          MinLimit: handleEditAmount(update.currency, update?.min_limit || 0),
          MaxLimit: handleEditAmount(update.currency, update?.max_limit || 0),
          Currency: update?.currency || "USD",
          Name: update?.name || "",
        });
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> create category
        let obj = {
          "min_limit": handleCalculateAmount(formData.Currency, formData.MinLimit) || 0,
          "max_limit": handleCalculateAmount(formData.Currency, formData.MaxLimit) || 0,
          "currency": formData.Currency,
          "name": formData.Name || "",
        };
        dispatch(CreateExpenseApprovalWorkflow(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2){
        // mode = 2 -> edit category
        let obj = {
          "min_limit": handleCalculateAmount(formData.Currency, formData.MinLimit) || 0,
          "max_limit": handleCalculateAmount(formData.Currency, formData.MaxLimit) || 0,
          "currency": formData.Currency,
          "name": formData.Name || "",
        };
        dispatch(UpdateExpenseApprovalWorkflow(companyId, update?.emp_approver_id, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className={`fullpage__modal animate__animated animate__fadeIn`}>
          
        <ModalTopBar
          onBack={handleClose}
          title={title} 
          />

        <Container 
          maxWidth="sm">
          <Box 
            className="flex__Column"
            gap={"20px"}
            pt="20px">
            <TextField 
              label="Name"
              fullWidth
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              />
            <Box
              className="flexFS_Row"
              gap={"15px"}>
              <AmountTextField 
                currencyName="Currency"
                currency={formData.Currency}
                onCurrencyChange={handleChange}
                label="Start limit"
                fullWidth
                name="MinLimit"
                value={formData.MinLimit}
                onChange={handleChange}
                />  
              <AmountTextField 
                currencyName="Currency"
                currency={formData.Currency}
                onCurrencyChange={handleChange}
                label="End limit"
                fullWidth
                name="MaxLimit"
                value={formData.MaxLimit}
                onChange={handleChange}
                helperText="$999,999.99 is the maximum end limit"
                />  
            </Box>
            <Typography
              textAlign={"center"}
              variant='caption'
              color={"grey"}>
              Note: The selected range includes both the start and end values.
            </Typography>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleSubmit}
              disabled={!(formData.Name !== "" && formData.MinLimit !== "" && 
                formData.MaxLimit !== "" && (handleCalculateAmount(formData.Currency, formData.MinLimit) < handleCalculateAmount(formData.Currency, formData.MaxLimit)))}>
              {mode === 1 ? "Submit" : "Update"}
            </LoadingButton>
          </Box>
        </Container>
      </Box>
    </Modal>
  )
}

export default ApprovalsModal;