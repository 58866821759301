import { useState } from 'react';

const useStepperInit = (
  steps,
  initialStep
) => {
  const [activeStep, setActiveStep] = useState(initialStep || 0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return [
    steps,
    activeStep, 
    handleReset, 
    handleNext,
    handleBack
  ];
}

export default useStepperInit;