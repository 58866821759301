import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  Avatar,
  Box, 
  Button, 
  Chip, 
  CircularProgress, 
  Container, 
  IconButton,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';

import TopBar from '../../../components/topBar/TopBar';
import NA from '../../../components/utils/NA';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { 
  GetMyProfile 
} from '../../../redux/actions/dashboard/profileActions';
import { 
  DeleteUserBankAccount,
  GetUserBankAccount 
} from '../../../redux/actions/dashboard/userBankAccountsActions';

import BankAccountModal from './BankAccountModal';

import global from "../../../global.scss";
import "./styles.scss";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state.dashboard);
  const [loading, setLoading] = useState(true);
  const [loadingBank, setLoadingBank] = useState(true);
  const [bankAccountModalOpen, setBankAccountModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [profile, setProfile] = useState({});

  const [userBankAccount, setUserBankAccount] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    handleGetMyProfile();
    handleGetUserBankAccount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      formatProfileObj(state.myProfile || {});
      formatUserBankAccountsList(state.userBankAccount.records || []);
    } catch (err) {}
  }, [state]);

  const formatProfileObj = (obj) => {
    setProfile(obj);
  }; 

  const formatUserBankAccountsList = (list) => {
    setUserBankAccount(list[0] || null);
  };

  async function handleGetMyProfile(customLoading) {
    dispatch(GetMyProfile(customLoading ? customLoading : setLoading));
  };

  async function handleGetUserBankAccount(customLoading) {
    dispatch(GetUserBankAccount(customLoading ? customLoading : setLoadingBank));
  };

  const handleDeleteAccount = (id) => {
    setDeleteId(id);
    dispatch(DeleteUserBankAccount(id, setDeleting)).then(() => {
      handleGetUserBankAccount(setDeleting).then(() => {
        setDeleteId(null);
      })
    })
  };

  return (
    <Box>

      <BankAccountModal
        open={bankAccountModalOpen}
        setOpen={setBankAccountModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetUserBankAccount}
        />

      <Container
        maxWidth="md">
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}>
            <ArrowBackIcon 
              sx={{ width: "40px", height: "40px" }}/>
          </IconButton>
          <Box
            sx={{ paddingTop: "20px" }}>
            <TopBar
              title={"Profile"}
              />
          </Box>
        </Box>

        {
          loading
          ?
          <Box
            height={"400px"}
            className="flexCenterCenterRow">
            <CircularProgress 
              size={"50px"}/>
          </Box>
          :
            <Box
              className="flex__Column"
              sx={{ width: "450px", margin: "auto" }}>
              <Box
                className="flexCenter_Row"
                gap="15px"
                width={"100%"}
                mb={"15px"}>
                <Avatar
                  sx={{ backgroundColor: global["secondary"], 
                    width: "55px", 
                    height: "55px"
                  }}>
                  <Typography
                    variant='h6'
                    color={"white"}>
                    {"first_name" in profile && profile?.first_name[0]?.toUpperCase()}
                    {"last_name" in profile && profile?.last_name[0]?.toUpperCase()}
                  </Typography>
                </Avatar>
                <Typography
                  variant='h5'>
                  {profile?.first_name || <NA />}&nbsp;{profile?.last_name || <NA />}
                </Typography>
              </Box>
              <Box
                className="flexCenter_Row"
                gap="15px"
                pl={"70px"}>
                <Typography
                  variant='body1'
                  color={"grey"}
                  width={"50px"}>
                  Email
                </Typography>
                <Typography
                  variant='subtitle2'>
                  <Tooltip
                    title="Primary email">
                    {profile?.primary_email || <NA />}
                  </Tooltip>
                  {/* &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                  <Tooltip
                    title="Secondary email">
                    {profile?.secondary_email || <NA />}
                  </Tooltip> */}
                </Typography>
              </Box>
              <Box
                className="flexCenter_Row"
                gap="15px"
                pl={"70px"}
                mb={"80px"}>
                <Typography
                  variant='body1'
                  color={"grey"}
                  width={"50px"}>
                  Mobile
                </Typography>
                <Typography
                  variant='subtitle2'>
                  <Tooltip
                    title="Primary mobile">
                    {
                      profile?.primary_mobile
                      ? "+" + profile?.primary_mobile_country_code + " " + profile?.primary_mobile  
                      : <NA />
                    }
                  </Tooltip>
                  {/* &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                  <Tooltip
                    title="Secondary mobile">
                    {
                      profile?.secondary_mobile
                      ? "+" + profile?.secondary_mobile_country_code + " " + profile?.secondary_mobile  
                      : <NA />
                    }
                  </Tooltip> */}
                </Typography>
              </Box>

              
              <Box>
                <Box
                  className="flexCenterSBRow"
                  mb={"15px"}>
                  <Typography
                    variant='subtitle1'
                    color={"grey"}>
                    Personal Bank account
                  </Typography>
                  {
                    !userBankAccount
                    &&
                    <Button
                      variant='contained'
                      onClick={() => {
                        setUpdate({
                          defaultMode: 1
                        });
                        setModalTitle("Add Bank account")
                        setBankAccountModalOpen(!bankAccountModalOpen);
                      }}>
                      Add bank account
                    </Button>
                  }
                </Box>
                {
                  loadingBank
                  ?
                  <Skeleton
                    variant='rectangular'
                    sx={{ height: "90px", borderRadius: "16px" }}
                    />
                  :
                    userBankAccount === null
                    ?
                    <Box
                      className="flexCenterCenterRow"
                      height={"90px"}>
                      <NA 
                        label={"No bank account added"}/>
                    </Box>
                    :
                      <Box
                        className="pmtmethod__top-card flexCenter_Row"
                        sx={{
                          borderRadius: "16px",
                          padding: "20px",
                          backgroundColor: "#fafafa"
                        }}
                        gap={"20px"}>
                        <AccountBalanceOutlinedIcon 
                          fontSize='large' 
                          />
                        <Box
                          sx={{ marginRight: "auto" }}>
                          <Typography variant='subtitle2'>
                            {/* {employeeBankAccount?.account_type_id?.type || <NA />} */}
                            
                            {/* &nbsp;-&nbsp; */}
                            {userBankAccount.account_number?.slice(-5) || <NA />}</Typography>
                          {/* <Typography color={"grey"}>Free</Typography> */}
                          <Box
                            className="flexCenter_Row"
                            gap={"10px"}>
                            {
                              userBankAccount.verified === false
                              &&
                              <Chip
                                color='error'
                                size='small'
                                label={'Unverified'} 
                                />
                            }
                          </Box>
                        </Box>
                        <Box
                          className="flexCenter_Row"
                          gap={"5px"}>
                          {
                            userBankAccount.verified === false
                            &&
                            <Chip
                              color='info'
                              label={"Verify"} 
                              clickable={true}
                              onClick={() => {
                                setUpdate({
                                  ...userBankAccount,
                                  defaultMode: 1,
                                  defaultStep: 2,
                                  bankAccountId: userBankAccount.id
                                });
                                setModalTitle("Verify account " + userBankAccount.account_number || "");
                                setBankAccountModalOpen(!bankAccountModalOpen);
                              }}
                              />
                          }
                          {
                            deleting && deleteId === userBankAccount.id
                            ?
                            <CircularProgress 
                              size={40}
                              />
                            :
                            <Tooltip
                              title="Remove personal bank account">
                              <IconButton
                                onClick={() => {
                                  handleDeleteAccount(userBankAccount.id)
                                }}>
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          }
                        </Box>
                      </Box>
                }

              </Box>

            </Box>
        }
      </Container>
    </Box>
  )
}

export default ProfilePage