import * as actionTypes from "../actionTypes.js";

const initialState = {
  expensesList: null,
  expenseDetails: null,
  expenseMessages: null,
  expensesListForApprover: null,

  costObjectsList: null,
  costAccountsList: null,
  categoriesList: null,

  approvalWorkflowsList: null,

  reimbursementsList: null
};

const expenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EXPENSES_EXP:
      return { ...state, expensesList: action.payload };
    case actionTypes.GET_EXPENSES_DETAILS_EXP:
      return { ...state, expenseDetails: action.payload };
    case actionTypes.GET_EXPENSE_MESSAGAES_EXP:
      return { ...state, expenseMessages: action.payload };
    case actionTypes.GET_EXPENSES_FOR_APPROVER_EXP:
      return { ...state, expensesListForApprover: action.payload };

    case actionTypes.GET_COST_OBJECTS_EXP:
      return { ...state, costObjectsList: action.payload };
    case actionTypes.GET_COST_ACCOUNTS_EXP:
      return { ...state, costAccountsList: action.payload };
    case actionTypes.GET_CATEGORIES_EXP:
      return { ...state, categoriesList: action.payload };

    case actionTypes.GET_APPROVAL_WORKFLOWS_EXP:
      return { ...state, approvalWorkflowsList: action.payload };

    case actionTypes.GET_REIMBURSEMENTS_EXP:
      return { ...state, reimbursementsList: action.payload };
  

    case actionTypes.SELECT_COMPANY:
      return initialState;
    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default expenseReducer;